import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../utils/axios";
import useSnackbar from "../../../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import YesNoDialog from "../../../components/dialogs/YesNoDialog";
import EditIcon from "@mui/icons-material/Edit";

const PropertiesTable = ({ dashboardSummery, dashboardData }) => {

  const { isInitialized, isAuthenticated } = useAuth();
  const defaultPageSize = 10;

  const [rows, setRows] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [allRowsCount, setAllRowsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { alert } = useSnackbar();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/pages/property/${id}`);
  };

  const handleAddNew = () => {
    navigate("/pages/property");
  };

  const columns = [
    { field: "address", headerName: "Subject address", width: 300 },
    { field: "city", headerName: "City", width: 200 },
    { field: "state", headerName: "State", width: 200 },
    // { field: "site_size", headerName: "Site Size", width: 130 },
    // { field: "view", headerName: "View", width: 130 },
    // { field: "floor_height", headerName: "Floor Height", width: 130 },
    // { field: "actual_age", headerName: "Actual Age", width: 130 },
    // { field: "building_construction_condition", headerName: "Condition", width: 130 },
    // { field: "building_rooms_beds", headerName: "Bedroom(s)", width: 130 },
    // { field: "building_rooms_bathsfull", headerName: "Bath(s) - Full", width: 130 },
    // { field: "half_bathroom", headerName: "Bath(s) - Half", width: 130 },
    // { field: "living_area_under_air", headerName: "Living Area Under Air", width: 150 },
    // { field: "basement", headerName: "Basement", width: 130 },
    // { field: "garage_carport", headerName: "Garage/Carport", width: 130 },
    // { field: "lot_pooltype", headerName: "Pool/Patio/Porch/Deck", width: 180 },
    {
      field: "actions", headerName: "Actions", renderCell: (params) => {
        return (
          <IconButton onClick={(e) => handleEdit(params.id)}>
            <EditIcon/>
          </IconButton>
        );
      }
    }
  ];

  const createData = (id, responseData) => {
    return {
      id,
      address: responseData?.address,
      city: responseData?.city,
      state: responseData?.state,
      site_size: responseData?.site_size,
      view: responseData?.view,
      actual_age: responseData?.actual_age,
      building_construction_condition: responseData?.building_construction_condition,
      building_rooms_beds: responseData?.building_rooms_beds,
      building_rooms_bathsfull: responseData?.building_rooms_bathsfull,
      half_bathroom: responseData?.half_bathroom,
      living_area_under_air: responseData?.living_area_under_air,
      basement: responseData?.basement,
      garage_carport: responseData?.garage_carport,
      lot_pooltype: responseData?.lot_pooltype
    };
  };

  const getProperties = () => {
    setLoading(true);
    if (pageNumber !== 1 && (rows.length * pageNumber === allRowsCount)) {
      setPageNumber(pageNumber - 1);
      return;
    }
    // ! property info
    axios.get("/properties/prop/",
      { params: { page_size: pageSize, page: pageNumber } }
    )
      .then(response => {
        let count = 0;
        if (count && count !== allRowsCount) {
          setAllRowsCount(count);
        }
        let newRows = response.data?.results?.map(e => createData(e.id, e));
        setRows(newRows);
        setLoading(false);
      }).catch(
      error => {
        alert({ message: error.message, severity: "error" });
        setLoading(false);
      }
    );
  };

  const deleteProperties = () => {
    setLoading(true);
    axios.post("/properties/delete/", { ids: selectionModel }).then(
      response => {
        getProperties();
        setSelectionModel([]);
        alert({ message: "Items deleted successfully", severity: "success" });
        setOpenDeleteDialog(false);
      }
    ).catch(
      e => {
        alert({ message: e.message, severity: "error" });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (!dashboardData && isAuthenticated) {
      getProperties();
    } else if (dashboardData) {
      setIsDashboard(true);
      let newRows = dashboardData.map(e => createData(e.id, e))
      setRows(newRows);
      setLoading(false);
    }
  }, [isInitialized]);

  useEffect(() => {
    if (isAuthenticated) {
      getProperties();
    }
  }, [pageNumber, pageSize]);


  const actions = (
    <>
      <Box mb={3} sx={{ display: "flex", alignItems: "center", gap: 3, justifyContent: "end" }}>

        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNew}
        >
          + Add New
        </Button>

        <Button
          variant="contained"
          color="error"
          disabled={selectionModel.length === 0}
          onClick={(e) => setOpenDeleteDialog(true)}
        >
          <DeleteIcon/>
          Delete
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <div style={{ height: isDashboard ? 500 : 900, paddingBottom: 40 }}>

        {!isDashboard && actions}

        <DataGrid
          onPageChange={page => setPageNumber(page + 1)}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowCount={allRowsCount}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={isDashboard ? [] : [defaultPageSize, 50]}
          checkboxSelection={!isDashboard}
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
          pagination={!isDashboard}
          loading={loading}
          paginationMode="server"
        />


      </div>

      <YesNoDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        title="Delete property"
        subtitle="Are you sure you want to delete selected properties?"
        handleYes={deleteProperties}
        yesBtnColor="error"
      />

    </>
  );

};

export default PropertiesTable;
