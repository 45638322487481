import React, { useCallback, useEffect, useState } from "react";
import './uploadLogo.css'
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Buttons from "../../pages/components/Buttons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "../../utils/axios";
import useSnackbar from "../../hooks/useSnackbar";
import useAuth from "../../hooks/useAuth";
import Cropper from "react-easy-crop";
import getCroppedImg, { generateDownload } from "./cropImage";
import ReactCrop from "react-image-crop";
import img from '../../vendor/Comp Genie-2.png'


// export const dataURLtoFile = (dataurl, filename) => {
//   const arr = dataurl.split(",");
//   const mime = arr[0].match(/:(.*?);/)[1];
//   const bstr = atob(arr[1]);
//   let n = bstr.length;
//   const u8arr = new Uint8Array(n);
//
//   while (n--) u8arr[n] = bstr.charCodeAt(n);
//
//   return new File([u8arr], filename, { type: mime });
// };


const UploadProfileLogo = ({handleClose}) => {

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const {alert} = useSnackbar();
  const {user} = useAuth();
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedArea, setCroppedArea] = useState(null)

  const [croppedImg,setCroppedImg] =useState(null)
  const [cropper, setCropper] = useState();

  const [cropData, setCropData] = useState("#");
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    setCroppedArea(croppedArea)

  })

  // console.log(user)


  const validateFile = (e) => {
    if (!e.target?.files[0]) {
      return;
    }
    let f = e.target.files[0];
    let err = null;
    let mimeType = f["type"];
    if (mimeType.split("/")[0] !== "image") {
      err = "Please select an image file";
    } else if (f.size === 0 || f.size > 200000) {
      err = "Maximum allowed file size is 100KB";
    }
    if (!err) {
      setFile(f);
      setSelected(true);
    }
    setError(err);

    if (!err) {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (eve) => {
        setLogoUrl(eve.target["result"]);
      };
    }

  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        logoUrl,
        croppedAreaPixels,
      )
      // console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])


  useEffect(()=>{

    showCroppedImage()
  },[croppedAreaPixels])
  // function dataURItoBlob(dataURI) {
  //   // convert base64/URLEncoded data component to raw binary data held in a string
  //   let byteString;
  //   if (dataURI.split(',')[0].indexOf('base64') >= 0)
  //     byteString = atob(dataURI.split(',')[1]);
  //   else
  //     byteString = unescape(dataURI.split(',')[1]);
  //
  //   // separate out the mime component
  //   let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  //
  //   // write the bytes of the string to a typed array
  //   let ia = new Uint8Array(byteString.length);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //
  //   return new Blob([ia], {type:mimeString});
  // }
  // const convertFileToBase64 = file => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file.rawFile);
  //
  //   reader.onload = () => resolve({
  //     fileName: file.title,
  //     base64: reader.result
  //   });
  //   reader.onerror = reject;
  // });
  // function urltoFile(url, filename, mimeType){
  //   return (fetch(url)
  //       .then(function(res){return res.arrayBuffer();})
  //       .then(function(buf){return new File([buf], filename,{type:mimeType});})
  //   );
  // }
  //
  // //Usage example:
  // let lastImg ;
  //  urltoFile(croppedImage, 'logo_image.jpeg','image/jpeg') .then(function(file){ lastImg=file;});


  const submitAvatar = () => {
    // generateDownload(logoUrl , croppedArea)

    setUploading(true);
    // let myFile = new File([croppedImage], "image.jpeg", {
    //   type: 'image/jpeg',
    // });

    let formData = new FormData();

    // console.log( file ,croppedImage , croppedImage,'me' )

    // formData.append("logo_image", lastImg);
    formData.append("logo_image", file);

    // console.log(lastImg , file , formData)
    axios.post("/users/profile/logo/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => setProgress((progressEvent.loaded / progressEvent.total * 100))
    }).then(result => {
        setUploading(false);
        alert({message: "Logo image changed", severity: "success"});
        handleClose();

      }
    ).catch(err => {
        setUploading(false);
        setError(err.message);
      }
    );
  };



  return (
    <Grid container sx={{ textAlign: "center" , display:"flex",flexDirection:"column",alignItems:"center" ,justifyContent:"space-between" }}>
      <div style={{width:"100%",fontSize:"0.7rem",color:"red"}}>
        <div style={{width:"100%",textAlign:"left"}}> Guide:</div>
        <div style={{width:"100%",textAlign:"left"}}>Logo Format: PNG, JPEG, SVG, TIF</div>
          <div style={{width:"100%",textAlign:"left"}}>Logo Size: 160 * 48 px< /div>
      </div>
      <Grid item xs={12}>
        {/*<Box sx={{ width: 420, height: 460, m: "auto", mb: 4 }}>*/}
        {/*  /!*<div style={{width:"10rem !important" , height:"10rem !important"}}>*!/*/}
        {/*    <Cropper*/}
        {/*      minZoom={0.3}*/}
        {/*      maxZoom={5}*/}
        {/*      cropSize={{ width: 400 , height: 100 }}*/}
        {/*      image={logoUrl}*/}
        {/*      crop={crop}*/}
        {/*      zoom={zoom}*/}
        {/*      onZoomChange={setZoom}*/}
        {/*      onCropChange={setCrop}*/}
        {/*      onCropComplete={onCropComplete}*/}
        {/*      aspect={4}*/}
        {/*      objectFit="contain"*/}
        {/*      restrictPosition={false}*/}
        {/*      style={{*/}
        {/*        containerStyle: {*/}
        {/*          // backgroundColor:"#FFFFFF"*/}
        {/*        },*/}

        {/*        mediaStyle: {*/}
        {/*          // backgroundColor:"#FFFFFF"*/}
        {/*        },*/}
        {/*        cropAreaStyle: {*/}
        {/*          // backgroundColor:"#FFFFFF"*/}
        {/*        }*/}
        {/*      }}*/}

        {/*      classes={*/}
        {/*        { containerClassName: 'my-react-easy-crop', mediaClassName: '', cropAreaClassName: '' }*/}
        {/*      }*/}

        {/*    />*/}
        {/*    /!*  <img src={logoUrl} />*!/*/}
        {/*    /!*</Cropper>*!/*/}
        {/*  /!*</div>*!/*/}

        {/*  /!*<Avatar src={!selected ? user?.logo_image : logoUrl}*!/*/}
        {/*  /!*        sx={{ width: "100%", height: "40%" }} variant='square'/>*!/*/}
        {/*</Box>*/}
      </Grid>
      {/*<div style={{width:"5rem" }}>*/}
      {/*  <img src={croppedImg} style={{width:"5rem"}}/>*/}
      {/*</div>*/}
      {
        user?.logo_image ?
          <div style={{margin:"1rem 0" , width:"100%" , display:"flex" , flexDirection:"column" , alignItems:"center",justifyContent:"flex-start"}}>
              <div  style={{margin:"1rem 0"}}>
                Your Logo Image
              </div>

            <div style={{width:"10rem" , height:"3rem"}}>
              <img src={logoUrl}
                      style={{ width: "100%", height: "100%",objectFit:"contain" }} />
            </div>
          </div>
          :null
      }

      <Grid item xs={12}>
        <Box sx={{ marginLeft: 5 }}>
          <Typography variant="h4" sx={{ marginBottom: 4 }}>
            {!uploading ? "Please select a file": "Uploading..."}
          </Typography>

          {
            !uploading ?
              (
                <Box sx={{ display: "flex", gap: 3, justifyContent: "center" }}>
                  <Button variant="contained" color="primary" component="label">
                    <InsertDriveFileIcon/>
                    Browse
                    <input
                      type="file"
                      hidden
                      onChange={(e) => validateFile(e)}
                    />
                  </Button>

                  <Button
                    variant="contained"
                    color="success"
                    disabled={!selected}
                    onClick={(e) => submitAvatar()}
                  >
                    <CloudUploadIcon sx={{ mr: 1 }}/>
                    Upload
                  </Button>

                </Box>
              )
              :
              <LinearProgress variant="determinate" value={progress}/>
          }

          {error &&
          <Typography variant="subtitle1" sx={{ marginBottom: 3 }} color="error">
            {error}
          </Typography>
          }


        </Box>
      </Grid>

    </Grid>
  );

};


export default UploadProfileLogo;
