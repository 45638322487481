import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody, Grid
} from "@material-ui/core";
import styled from "styled-components/macro";
import { tableCellClasses } from '@material-ui/core/TableCell';
import blue from "@material-ui/core/colors/blue";
import { red } from "@mui/material/colors";
import { pink } from "@material-ui/core/colors";
import green from "@material-ui/core/colors/green";
import useAuth from "../../../hooks/useAuth";
import EmailField from "../../pages/profile/EmailField";
import PersonalField from "../../pages/profile/PersonalField";
const uploadImage = require("../../../vendor/uploadImage.jpg");


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: blue[800],
    padding: 5,
    width: '20%',
    height: 'auto !important',
    color: theme.palette.common.white,
    fontSize:12

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    border: 1,
    borderColor: 'gray',

    borderStyle: 'solid',
    // width: 'auto !important',
    height: 'auto !important',
    wordBreak: 'break-word'
  }
}));
const StyledTableCellBorderLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: blue[800],
    padding: 5,
    width: 'auto !important',
    height: 'auto !important',
    color: theme.palette.common.white,

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    border: 0,
    borderColor: 'gray',
    borderStyle: 'solid',
    width: 'auto !important',
    height: 'auto !important',
    wordBreak: 'break-word'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border

}));

const Avatar = styled.img`
  display: block;
  border-radius: 5px;
  text-align: center;
  height: 130px;
  width: 170px;
`;



export default function TablePrint({ rows }) {

  const { user } = useAuth();

  return (
    <TableContainer sx={{ mb: 5 ,borderRadius:2, boxShadow:3 ,border:"1px solid black" }} component={Paper} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow >
            <StyledTableCell sx={{width:"12% !important"}} align="center">FEATURE</StyledTableCell>
            <StyledTableCell sx={{width:"22%"}} align="center">SUBJECT</StyledTableCell>
            <StyledTableCell sx={{width:"22%"}} align="center" colSpan='2' >COMPARABLE SALE #1</StyledTableCell>
            <StyledTableCell sx={{width:"22%"}} align="center" colSpan='2' >COMPARABLE SALE #2</StyledTableCell>
            <StyledTableCell sx={{width:"22%"}} align="center" colSpan='2'>COMPARABLE SALE #3</StyledTableCell>
          </TableRow>
{/*          <TableRow>
            <StyledTableInfoCell  align="center">
              <Avatar alt="" src={user?.profile_image}/>
            </StyledTableInfoCell>
            <StyledTableInfoCell align="center">
            </StyledTableInfoCell>
            <StyledTableInfoCell align="center">
            </StyledTableInfoCell>
            <StyledTableInfoCell align="center"></StyledTableInfoCell>
            <StyledTableInfoCell align="center"></StyledTableInfoCell>
          </TableRow>*/}
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            const comma ='';
            const sqft = '';
            const km ='';
            const age ='';
            if(index <= 4){
              if(index===0){
                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: index === 19 ? '10em' : '1em !important',
                      verticalAlign: 'text-top',
                      // backgroundColor: (theme) => (index === 19 ? theme.palette.action.hover : null)
                    }}
                  >
                    <StyledTableCell

                      align="left"
                      sx={{
                        inlineSize: '170px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: 'white',
                        fontWeight:900,
                        // border:"1px solid black",
                        padding:"8px"

                      }}
                      >
                      {(row.address === 'Image') ? <Avatar alt="" src={user?.profile_image}/> : row.address}
                    </StyledTableCell>
                    {/*{*/}
                    {/*  index===0?*/}
                    {/*    <StyledTableCellBorderLeft>*/}

                    {/*    </StyledTableCellBorderLeft>*/}

                    {/*    :*/}
                    <StyledTableCell
                      align="left"
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor:index===1 || index ===4 || index ===5 ? 'rgba(148,193,235,255)': '#ffffff',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"

                      }}
                      >
                      {/*{comma}*/}
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.subject.default}/>) : row.subject?.split('*JR')[0]===undefined? row.subject: row.subject?.split('*JR')[0]}

                      </div>
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.subject.default}/>) : row.subject?.split('*JR')[1]===undefined? row.subject: row.subject?.split('*JR')[1]}

                      </div>

                      {/*{sqft}*/}
                      {/*{age}*/}
                    </StyledTableCell>
                    {/*}*/}



                    <StyledTableCell
                      align="left"
                      colSpan='2'

                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: 'white',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"

                      }}
                    >
                      {/*{comma}*/}
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.comp1.default}/>) : row.comp1?.split('*JR')[0]===undefined? row.comp1: row.comp1?.split('*JR')[0]}

                      </div>
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.comp1.default}/>) : row.comp1?.split('*JR')[1]===undefined? row.comp1: row.comp1?.split('*JR')[1]}

                      </div>

                      {/*{(row.address === 'Image') ? (<Avatar alt="" src={row.comp1.default}/>) : row.comp1}*/}
                      {/*{sqft}*/}
                      {/*{km}*/}
                      {/*{age}*/}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      colSpan='2'

                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        // backgroundColor: '#d6d6d6'
                        backgroundColor:"white",
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"
                      }}
                    >
                      {/*{comma}*/}
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.comp2.default}/>) : row.comp2?.split('*JR')[0]===undefined? row.comp2: row.comp2?.split('*JR')[0]}

                      </div>
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.comp2.default}/>) : row.comp2?.split('*JR')[1]===undefined? row.comp2: row.comp2?.split('*JR')[1]}

                      </div>
                      {/*{(row.address === 'Image') ? (<Avatar alt="" src={row.comp2.default}/>) : row.comp2}*/}
                      {/*{sqft}*/}
                      {/*{km}*/}
                      {/*{age}*/}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      colSpan='2'

                      sx={{
                        inlineSize: '150px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: 'white',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"
                      }}
                    >
                      {/*{comma}*/}
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.comp3.default}/>) : row.comp3?.split('*JR')[0]===undefined? row.comp3: row.comp3?.split('*JR')[0]}

                      </div>
                      <div>
                        {(row.address === 'Image') ? (<Avatar alt="" src={row.comp3.default}/>) : row.comp3?.split('*JR')[1]===undefined? row.comp3: row.comp3?.split('*JR')[1]}

                      </div>
                      {/*{(row.address === 'Image') ? (<Avatar alt="" src={row.comp3.default}/>) : row.comp3}*/}
                      {/*{sqft}*/}
                      {/*{km}*/}
                      {/*{age}*/}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              }
              else {


              return (

                <StyledTableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    height: index === 19 ? '10em' : '1em !important',
                    verticalAlign: 'text-top',
                    // backgroundColor: (theme) => (index === 19 ? theme.palette.action.hover : null)
                  }}
                >
                  <StyledTableCell

                    align="left"
                    sx={{
                      inlineSize: '170px',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all',
                      fontSize: '12px !important',
                      backgroundColor: 'white',
                      fontWeight:900,
                      // border:"1px solid black",
                      padding:"8px"


                      }}
                   >
                    {(row.address === 'Image') ? <Avatar alt="" src={user?.profile_image}/> : row.address}
                  </StyledTableCell>
                    {/*{*/}
                    {/*  index===0?*/}
                    {/*    <StyledTableCellBorderLeft>*/}

                    {/*    </StyledTableCellBorderLeft>*/}

                    {/*    :*/}
                      <StyledTableCell
                        align="left"
                        sx={{
                          inlineSize: '140px',
                          overflowWrap: 'break-word',
                          wordBreak: 'break-all',
                          fontSize: '12px !important',
                          backgroundColor:index===1 || index ===4 || index ===5 ? 'rgba(148,193,235,255)': '#ffffff',
                          fontWeight:900,
                          textAlign:"center",
                          // border:"1px solid black",
                          padding:"8px"

                        }}
                      >
                        <div>
                          {/*{comma}*/}

                          {(row.address === 'Image') ? (<Avatar alt="" src={row.subject.default}/>) : row.subject}

                          {/*{sqft}*/}
                          {/*{age}*/}
                        </div>

                      </StyledTableCell>
                  {/*}*/}



                  <StyledTableCell
                    align="left"
                    colSpan='2'

                    sx={{
                      inlineSize: '140px',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all',
                      fontSize: '12px !important',
                      backgroundColor: 'white',
                      fontWeight:900,
                      textAlign:"center",
                      // border:"1px solid black",
                      padding:"8px"

                    }}
                  >
                    <div>
                      {/*{comma}*/}
                      {(row.address === 'Image') ? (<Avatar alt="" src={row.comp1.default}/>) : row.comp1}
                      {/*{sqft}*/}
                      {/*{km}*/}
                      {/*{age}*/}
                    </div>

                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    colSpan='2'

                    sx={{
                      inlineSize: '140px',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all',
                      fontSize: '12px !important',
                      // backgroundColor: '#d6d6d6'
                      backgroundColor:"white",
                      fontWeight:900,
                      textAlign:"center",
                      // border:"1px solid black",
                      padding:"8px"
                    }}
                  >  <div>
                    {/*{comma}*/}
                    {(row.address === 'Image') ? (<Avatar alt="" src={row.comp2.default}/>) : row.comp2}
                    {/*{sqft}*/}
                    {/*{km}*/}
                    {/*{age}*/}
                  </div>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    colSpan='2'

                    sx={{
                      inlineSize: '150px',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-all',
                      fontSize: '12px !important',
                      backgroundColor: 'white',
                      fontWeight:900,
                      textAlign:"center",
                      // border:"1px solid black",
                      padding:"8px"
                    }}
                  >  <div>
                    {/*{comma}*/}
                    {(row.address === 'Image') ? (<Avatar alt="" src={row.comp3.default}/>) : row.comp3}
                    {/*{sqft}*/}
                    {/*{km}*/}
                    {/*{age}*/}
                  </div>
                  </StyledTableCell>
                </StyledTableRow>
            )} }
            else {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      height: index === 19 ? '5em' : '1rem !important',
                      verticalAlign: 'text-top',
                      // backgroundColor: (theme) => (index === 19 ? theme.palette.action.hover : null)
                    }}
                  >
                    <StyledTableCell
                      align="left"
                      sx={{

                        inlineSize: '170px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: 'white',
                        fontWeight:900,
                        // border:"1px solid black",
                        padding:"8px"


                      }}
                    >  <div>
                      {(row.address === 'Image') ? <Avatar alt="" src={user?.profile_image}/> : row.address}
                    </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align={ row.address === 'Value Adjustment' ? 'center' : 'left'}
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor:index===5?'rgba(148,193,235,255)': 'white',
                        color:index===5?'rgba(148,193,235,255)': 'black',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"

                      }}
                    >
                      <div>
                      {/*{comma}*/}

                      {(row.address === 'Adjustment Sale Price of Comparables') ? '' : row.subject}
                      {/*{sqft}*/}
                      {/*{age}*/}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: 'white',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"
                      }}
                    >  <div>
                      {/*{comma}*/}
                      {(row.address === 'Image') ? (<Avatar alt="" src={row.comp1.default}/>) : row.comp1}
                      {/*{sqft}*/}
                      {/*{km}*/}
                      {/*{age}*/}
                    </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: '#d6d6d6',
                        width:"10% !important",
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"
                      }}
                    >
                      <div>
                      {row.comp4}
                      </div>
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: 'white',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"

                      }}
                    >
                      <div>
                      {/*{comma}*/}
                      {(row.address === 'Image') ? (<Avatar alt="" src={row.comp2.default}/>) : row.comp2}
                      {/*{sqft}*/}
                      {/*{km}*/}
                      {/*{age}*/}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: '#d6d6d6',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"

                      }}
                    >  <div>
                      {row.comp4}
                    </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        // backgroundColor: '#f0f0f2'
                        backgroundColor: 'white',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px",
                      }}
                    >  <div>
                      {/*{comma}*/}
                      {(row.address === 'Image') ? (<Avatar alt="" src={row.comp3.default}/>) : row.comp3}
                      {/*{sqft}*/}
                      {/*{km}*/}
                      {/*{age}*/}
                    </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        inlineSize: '140px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '12px !important',
                        backgroundColor: '#d6d6d6',
                        fontWeight:900,
                        textAlign:"center",
                        // border:"1px solid black",
                        padding:"8px"

                      }}
                    >  <div>
                      {row.comp4}
                    </div>
                    </StyledTableCell>

                  </StyledTableRow>
                )
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
