import { useLocation, useNavigate } from "react-router-dom";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "../../utils/axios";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { PASSWORD_REGEX_MATCH_ERROR_MESSAGE, PASSWORD_REGEX_PATTERN } from "../../constants";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";

const ResetPasswordSetPasswordComponent = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const secret = query.get("secret");
  const email = query.get("email");
  const navToLogin = () => navigate("/auth/sign-in");
  let schema = Yup.object({
    email: Yup.string().required().email()
  });


  try {
    schema.validate({ email });
  } catch (e) {
    navToLogin();
  }

  if (!secret) {
    navToLogin();
  }

  const handlePasswordReset = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      await axios.post("/users/forget-password/", { email, secret, new_password: values.password });
      navToLogin();
    } catch (error) {
      setErrors({ submit: error.message || "Something went wrong" });
      setSubmitting(false);
      setStatus({success: false});
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        submit: false
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .matches(
            PASSWORD_REGEX_PATTERN,
            PASSWORD_REGEX_MATCH_ERROR_MESSAGE
          )
          .required("Required"),
        confirmPassword: Yup.string().when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string()
            .oneOf(
              [Yup.ref("newPassword")],
              "Both password need to be the same"
            )
            .required("Required")
        })
      })}
      onSubmit={handlePasswordReset}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert style={{marginTop: "1.5rem", marginBottom: "1rem"}} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{marginBottom: "2rem"}}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{marginBottom: "2rem"}}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Reset password
          </Button>
        </form>
      )}
    </Formik>
  );

  // if (!email || )
  // return (
  //
  // );
};


export default ResetPasswordSetPasswordComponent;
