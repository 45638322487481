import { Box } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";

const PersonalFieldValue = ({value}) => {
  return (
    <Typography variant="subtitle1">{value}</Typography>
  )
};

export default PersonalFieldValue;
