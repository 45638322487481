import React, { useContext, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { PropertyContext } from "../../../contexts/PropertyContext";
import { FieldArray, Formik, getIn } from "formik";
import { Link as RouterLink, useParams } from "react-router-dom";

import * as Yup from "yup";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { stringNoEmpty, stringValueOrEmpty } from "../../../utils/helpers";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import useSnackbar from "../../../hooks/useSnackbar";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@mui/material/CircularProgress";
import blue from "@material-ui/core/colors/blue";
import { useDispatch } from "react-redux";
import CountertopsIcon from "@mui/icons-material/Countertops";
import BathtubIcon from "@mui/icons-material/Bathtub";
import RoofingIcon from "@mui/icons-material/Roofing";
import WindowIcon from "@mui/icons-material/Window";
import HouseIcon from "@mui/icons-material/House";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MenuItem from "@mui/material/MenuItem";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { ComparableContext } from "../../../contexts/ComparableContext";

const PropertyGeneralInfo = (props) => {

  const [s1,setS1]=useState(0)
  const [s2,setS2]=useState(0)
  const [s3,setS3]=useState(0)
  const [s4,setS4]=useState(0)
  const [s5,setS5]=useState(0)
  const [s6,setS6]=useState(0)
  const [s7,setS7]=useState(0)
  const [s8,setS8]=useState(0)
  const [s9,setS9]=useState(0)
  const [s10,setS10]=useState(0)
  const [s11,setS11]=useState(0)

  const handleTotal = (e,index)=>{
    if (index===1){
      setS1(e)
    }else if(index===2){
      setS2(e)
    }else if(index===3){
      setS3(e)
    }else if(index===4){
      setS4(e)
    }else if(index===5){
      setS5(e)
    }else if(index===6){
      setS6(e)
    }else if(index===7){
      setS7(e)
    }else if(index===8){
      setS8(e)
    }else if(index===9){
      setS9(e)
    }else if(index===10){
      setS10(e)
    }else if(index===11){
      setS11(e)
    }
  }

  let total =s1+s2+s3+s4+s5+s6+s7+s8+s9+s10+s11



  const { propertyInfo, setPropertyInfo } = useContext(PropertyContext);
  const [useLookup, setUseLookup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const [bed, setBed] = useState(null);
  const [bath, setBath] = useState(null);
  const [halfBath, setHalfBath] = useState(null);


  const [state, setState] = useState("");
  const navigate = useNavigate();

  const {id}=useParams()


  const { alert } = useSnackbar();
  const getInitValueFromPropertyInfo = () => {
    // console.log(propertyInfo?.general , 'setZip');
    let general = propertyInfo?.general;
    mySetFieldValue("address", general?.address);
    mySetFieldValue("city", general?.city);
    mySetFieldValue("state", general?.state);
    mySetFieldValue("zip", general?.zipcode);
    mySetFieldValue("building_rooms_beds", general?.bedrooms === 0 ? null :general?.bedrooms);
    mySetFieldValue("building_rooms_bathsfull", general?.bathrooms === 0 ? null :general?.bathrooms);
    mySetFieldValue("half_bathroom", general?.half_baths === 0 ? null :general?.half_baths );
    mySetFieldValue("unique_characteristics", general?.unique_neighborhood_characteristics);
    mySetFieldValue("important_features", general?.important_features);




    // mySetFieldValue("zip", general?.zipcode);
    // mySetFieldValue("zip", general?.zipcode);
    setAddress(general?.address);
    setCity(general?.city);
    setState(general?.state);
    setZip(general?.zipcode)


    setS1(general?.kitchen_estimated_cost)
    setS2(general?.bath_estimated_cost)
    setS3(general?.roof_covering_estimated_cost)
    setS4(general?.AC_estimated_cost)
    setS5(general?.floor_covering_estimated_cost)
    setS6(general?.windows_estimated_cost)
    return {
      address: general?.address, //general?.address,
      city: general?.city, //general?.city,
      state: general?.state, //general?.state,
      zip: general?.zipcode,
      building_rooms_beds: general?.bedrooms,
      building_rooms_bathsfull: general?.bathrooms,
      half_bathroom: general?.half_baths,
      unique_characteristics: general?.unique_neighborhood_characteristics,
      important_features: general?.important_features
    };
  };

  useEffect(() => {
    if (propertyInfo != null) {
      const init = getInitValueFromPropertyInfo();
      // console.log(init);
      setInitValues(init);
    }
  }, [propertyInfo]);

  const validationSchema = Yup.object().shape({
    address:id? Yup.string().max(400): Yup.string().max(400).required("Address is required"),
    city:id? Yup.string().max(100):  Yup.string().max(100).required("City is required"),
    state:id? Yup.string().max(100):  Yup.string().max(100).required("State is required"),
    building_rooms_bathsfull: Yup.number().integer().min(1, "Minimum value is 1"),
    building_rooms_beds: Yup.number().integer().min(1, "Minimum value is 1"),
    half_bathroom: Yup.number().integer().min(1, "Minimum value is 1"),
    zip:id?Yup.string().max(100):  Yup.string().max(100).required("Zip is required"),
    unique_characteristics: Yup.string().max(5000),
    important_features: Yup.string().max(5000),
    remodeling: Yup.array()
      .of(
        Yup.object().shape({
          estimated_cost: Yup.number().integer().nullable().min(0, "Minimum value is 0") // these constraints take precedence
        })
      )
  });
  let mySetFieldValue = null;
  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    // await dispatch(setPropertyGeneralInfo(values));
    setPropertyInfo({...propertyInfo, general: values});
    setSubmitting(true);
    setStatus(true);
    handleNext(values);


  };



  const handleNext = (payload) => {
    if (payload) {
      saveData(payload);
      return;
    }
  }


  const saveData = async (remodel) => {


    // ! property info new

    // true? console.log(remodel,{
    //     // inserted_property_data: { general: remodel },
    //     // remodeling_or_updates: remodel.remodeling,
    //     // address: remodel.address,
    //     // city: remodel.city,
    //     // state: remodel.state,
    //
    //     "more_detail": [] ,
    //     'comparable_info':[],
    //     "address": remodel.address === undefined && remodel.initValues?.address ===undefined? '' : remodel.address === undefined?  remodel.initValues?.address: remodel.address,
    //     "city": remodel.city  === undefined && remodel.initValues?.city ===undefined? '' : remodel.city === undefined?  remodel.initValues?.city: remodel.city,
    //     "state":  remodel.state  === undefined && remodel.initValues?.state ===undefined? '' : remodel.state === undefined?  remodel.initValues?.state: remodel.state,
    //     "zipcode":   remodel.zip  === undefined && remodel.initValues?.zip ===undefined? '' : remodel.zip === undefined?  remodel.initValues?.zip: remodel.zip,
    //     "bedrooms":  remodel.building_rooms_beds ? remodel.building_rooms_beds :0,
    //     "bathrooms":  remodel.building_rooms_bathsfull ? remodel.building_rooms_bathsfull :0,
    //     "half_baths":  remodel.half_bathroom ? remodel.half_bathroom :0,
    //     "kitchen_timeframe":  remodel.remodeling[0].time_frame,
    //     "kitchen_estimated_cost": remodel.remodeling[0].estimated_cost,
    //     "bath_timeframe":  remodel.remodeling[1].time_frame,
    //     "bath_estimated_cost":remodel.remodeling[1].estimated_cost,
    //     "roof_covering_timeframe":  remodel.remodeling[2].time_frame,
    //     "roof_covering_estimated_cost":  remodel.remodeling[2].estimated_cost,
    //     "AC_timeframe": remodel.remodeling[3].time_frame,
    //     "AC_estimated_cost": remodel.remodeling[3].estimated_cost,
    //     "floor_covering_timeframe":  remodel.remodeling[4].time_frame,
    //     "floor_covering_estimated_cost":  remodel.remodeling[4].estimated_cost,
    //     "windows_timeframe":  remodel.remodeling[5].time_frame,
    //     "windows_estimated_cost":  remodel.remodeling[5].estimated_cost,
    //     "total_estimated_cost": total,
    //     "unique_neighborhood_characteristics":remodel.unique_characteristics  === undefined && remodel.initValues?.unique_characteristics ===undefined? '' : remodel.unique_characteristics === undefined?  remodel.initValues?.unique_characteristics: remodel.unique_characteristics,
    //     "important_features":  remodel.important_features  === undefined && remodel.initValues?.important_features ===undefined? '' : remodel.important_features === undefined?  remodel.initValues?.important_features: remodel.important_features,
    //
    //
    //
    //   }):

      setLoading(true);
    let propertyId = propertyInfo?.id;
    let more = props.initialValues?.more_detail[0]
    let comps = props.initialValues?.comparable_info
    // let x = delete more['property_id']
    if(more !== undefined){
      delete more['property_id']
    }

    let newComps = comps===undefined || comps===null ||comps===[]? []: comps.map((obj)=> {
      delete obj.property_id;
      return obj;
    });

    // console.log(comps  ,more  ,newComps , 'moreeeeee')
    let payload = {
      "more_detail":more===null || more===undefined?[]:[more],
      'comparable_info':newComps,
      "address": remodel.address === undefined && remodel.initValues?.address ===undefined? '' : remodel.address === undefined?  remodel.initValues?.address: remodel.address,
      "city": remodel.city  === undefined && remodel.initValues?.city ===undefined? '' : remodel.city === undefined?  remodel.initValues?.city: remodel.city,
      "state":  remodel.state  === undefined && remodel.initValues?.state ===undefined? '' : remodel.state === undefined?  remodel.initValues?.state: remodel.state,
      "zipcode":   remodel.zip  === undefined && remodel.initValues?.zip ===undefined? '' : remodel.zip === undefined?  remodel.initValues?.zip: remodel.zip,
      "bedrooms": bed===''||bed===null || bed ===undefined ? null : bed ,
      "bathrooms": bath===''||bath===null || bath ===undefined ? null :  bath ,
      "half_baths": halfBath===''||halfBath===null || halfBath ===undefined ? null :  halfBath,
      "kitchen_timeframe":  remodel.remodeling[0].time_frame,
      "kitchen_estimated_cost": remodel.remodeling[0].estimated_cost,
      "bath_timeframe":  remodel.remodeling[1].time_frame,
      "bath_estimated_cost":remodel.remodeling[1].estimated_cost,
      "roof_covering_timeframe":  remodel.remodeling[2].time_frame,
      "roof_covering_estimated_cost":  remodel.remodeling[2].estimated_cost,
      "AC_timeframe": remodel.remodeling[3].time_frame,
      "AC_estimated_cost": remodel.remodeling[3].estimated_cost,
      "floor_covering_timeframe":  remodel.remodeling[4].time_frame,
      "floor_covering_estimated_cost":  remodel.remodeling[4].estimated_cost,
      "windows_timeframe":  remodel.remodeling[5].time_frame,
      "windows_estimated_cost":  remodel.remodeling[5].estimated_cost,
      "total_estimated_cost": total,
      "unique_neighborhood_characteristics":remodel.unique_characteristics  === undefined && remodel.initValues?.unique_characteristics ===undefined? '' : remodel.unique_characteristics === undefined?  remodel.initValues?.unique_characteristics: remodel.unique_characteristics,
      "important_features":  remodel.important_features  === undefined && remodel.initValues?.important_features ===undefined? '' : remodel.important_features === undefined?  remodel.initValues?.important_features: remodel.important_features,



    };

    // "mAdditionalInfo": {
    //   "sales_price": null,
    //     "date_closed": null,
    //     "data_source": null,
    //     "status": null,
    //     "site_size": null,
    //     "view": null,
    //     "floor_height": null,
    //     "actual_age": null,
    //     "condition": null,
    //     "comp_page_bedrooms": null,
    //     "comp_page_full_baths": null,
    //     "comp_page_half_baths": null,
    //     "living_area_under_air": null,
    //     "basement": null,
    //     "garage_carport": null,
    //     "pool_deck_porch_patio": null,
    //     "additional_info": null,
    //     "mProperty": null
    // },
    // "address": "khiaboon shohada",
    //   "city": "mashhad",
    //   "state": "khorasan",
    //   "zipcode": "95965",
    //   "bedrooms": null,
    //   "bathrooms": null,
    //   "half_baths": null,
    //   "kitchen_timeframe": null,
    //   "kitchen_estimated_cost": null,
    //   "bath_timeframe": null,
    //   "bath_estimated_cost": null,
    //   "roof_covering_timeframe": null,
    //   "roof_covering_estimated_cost": null,
    //   "AC_timeframe": null,
    //   "AC_estimated_cost": null,
    //   "floor_covering_timeframe": null,
    //   "floor_covering_estimated_cost": null,
    //   "windows_timeframe": null,
    //   "windows_estimated_cost": null,
    //   "total_estimated_cost": null,
    //   "location_lat": null,
    //   "location_lng": null,
    //   "unique_neighborhood_characteristics": "",
    //   "important_features": "",
    //   "creation_date": "2022-05-26T13:57:55.241169Z",
    //   "modification_date": "2022-05-26T13:57:55.241169Z",
    //   "deleted": false


    try {
      let response;
      if (!propertyId) {
        response = await axios.post("/properties/prop/ ", payload);
        navigate(`/pages/comparables/${response?.data?.id}`);
      } else {
        response = await axios.put(`/properties/prop/${id}/`, payload);
        navigate(`/pages/comparables/${id}`);
      }
      setLoading(false);
      alert({ message: "Property data saved successfully", severity: "success" });
      // if(id){
      //   navigate(`/pages/comparables/${id}`);
      // }else {
      //   navigate("/pages/comparables/");
      //
      // }
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      setLoading(false);
    }



  };

  useEffect(()=>{
    setBed(props.initialValues?.bedrooms)
    setBath(props.initialValues?.bathrooms)
    setHalfBath(props.initialValues?.half_baths)
  },[props.initialValues])


  const getDividerIconByTag = (tag) => {
    switch (tag) {
      case "kitchen":
        return <CountertopsIcon/>;
      case "bath":
        return <BathtubIcon/>;
      case "roof":
        return <RoofingIcon/>;

      case "window":
        return <WindowIcon/>;

      default:
        return <HouseIcon/>;

    }
  };
  const remodelingUpdates = propertyInfo?.remodeling_or_updates ? propertyInfo?.remodeling_or_updates :

    [
      {
        tag: "kitchen",
        unit: "Kitchen",
        time_frame:  propertyInfo?.general?.kitchen_timeframe===null|| propertyInfo?.general?.kitchen_timeframe===undefined|| propertyInfo?.general?.kitchen_timeframe===''?'': propertyInfo?.general?.kitchen_timeframe,
        estimated_cost:propertyInfo?.general?.kitchen_estimated_cost===null|| propertyInfo?.general?.kitchen_estimated_cost===undefined|| propertyInfo?.general?.kitchen_estimated_cost===''||  propertyInfo?.general?.kitchen_estimated_cost===0?null: parseInt(propertyInfo?.general?.kitchen_estimated_cost)
      },
      {
        tag: "bath",
        unit: "Bath",
        time_frame:  propertyInfo?.general?.bath_timeframe===null|| propertyInfo?.general?.bath_timeframe===undefined|| propertyInfo?.general?.bath_timeframe===''?'': propertyInfo?.general?.bath_timeframe,
        estimated_cost:propertyInfo?.general?.bath_estimated_cost===null|| propertyInfo?.general?.bath_estimated_cost===undefined|| propertyInfo?.general?.bath_estimated_cost===''||  propertyInfo?.general?.bath_estimated_cost===0?null: parseInt(propertyInfo?.general?.bath_estimated_cost)
      },
      {
        tag: "roof",
        unit: "Roof Covering",
        time_frame:  propertyInfo?.general?.roof_covering_timeframe===null|| propertyInfo?.general?.roof_covering_timeframe===undefined|| propertyInfo?.general?.roof_covering_timeframe===''?'': propertyInfo?.general?.roof_covering_timeframe,
        estimated_cost: propertyInfo?.general?.roof_covering_estimated_cost===null|| propertyInfo?.general?.roof_covering_estimated_cost===undefined|| propertyInfo?.general?.roof_covering_estimated_cost==='' ||  propertyInfo?.general?.roof_covering_estimated_cost===0?null: parseInt(propertyInfo?.general?.roof_covering_estimated_cost)
      },
      {
        tag: "A/C",
        unit: "A/C",
        time_frame:  propertyInfo?.general?.AC_timeframe===null|| propertyInfo?.general?.AC_timeframe===undefined|| propertyInfo?.general?.AC_timeframe===''?'': propertyInfo?.general?.AC_timeframe,
        estimated_cost:propertyInfo?.general?.kitchen_estimated_cost===null|| propertyInfo?.general?.kitchen_estimated_cost===undefined|| propertyInfo?.general?.kitchen_estimated_cost==='' ||  propertyInfo?.general?.kitchen_estimated_cost===0?null: parseInt(propertyInfo?.general?.kitchen_estimated_cost)
      },
      {
        tag: "floor",
        unit: "Floor Covering",
        time_frame:  propertyInfo?.general?.floor_covering_timeframe===null|| propertyInfo?.general?.floor_covering_timeframe===undefined|| propertyInfo?.general?.floor_covering_timeframe===''?'': propertyInfo?.general?.floor_covering_timeframe,
        estimated_cost:propertyInfo?.general?.floor_covering_estimated_cost===null|| propertyInfo?.general?.floor_covering_estimated_cost===undefined|| propertyInfo?.general?.floor_covering_estimated_cost===''|| propertyInfo?.general?.floor_covering_estimated_cost===0?null: parseInt(propertyInfo?.general?.floor_covering_estimated_cost)
      },
      {
        tag: "window",
        unit: "Windows",
        time_frame: propertyInfo?.general?.windows_timeframe===null|| propertyInfo?.general?.windows_timeframe===undefined|| propertyInfo?.general?.windows_timeframe===''?'': propertyInfo?.general?.windows_timeframe,
        estimated_cost:propertyInfo?.general?.windows_estimated_cost===null|| propertyInfo?.general?.windows_estimated_cost===undefined|| propertyInfo?.general?.windows_estimated_cost==='' || propertyInfo?.general?.windows_estimated_cost===0?null: parseInt(propertyInfo?.general?.windows_estimated_cost)
      }
    ];


  const CustomInput = styled("div")(({ theme }) => ({

    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "30%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%"
    }
  }));

  const getError = (name, errors, touched) => {
    const error = getIn(errors, name);
    const touch = getIn(touched, name);
    return error ? error : null;
  };

  const isInvalid = (name, errors, touched) => {
    const error = getIn(errors, name);
    const touch = getIn(touched, name);
    return error;
  };


  // console.log(initValues,remodelingUpdates,  total.current)


  // useEffect(()=>{
  // },[])

  const handleFocusBath = ()=>{
    if(bath===0){
      setBath('')
    }
  }


  const handleFocusBed= ()=>{
    if(bed===0){
      setBed('')
    }
  }


  const handleFocusHalfBath = ()=>{
    if(halfBath===0){
      setHalfBath('')
    }
  }
  return (
    <>
      <div style={{display:"block" , height:"45px"  }}>
        <div style={{ position:"fixed" , bottom:40 , left:15, height:"45px",zIndex:12300 , fontSize:'0.8rem' }}>
          Total Estimated Cost = {total}
        </div>
      </div>
      <Card>

        <CardHeader
          sx={{ backgroundColor: blue[800], color: "white" }}
          title="Property Information"
        >
        </CardHeader>
        <Formik
          initialValues={{
            initValues: initValues,
            remodeling: remodelingUpdates
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
            mySetFieldValue = setFieldValue,
              <form onVlidate onSubmit={handleSubmit}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="Subject Address"
                        fullWidth
                        // label="Subject Name"
                        value={values.address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          handleChange(e);
                        }}
                        name="address"
                        onBlur={handleBlur}

                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="City"
                        fullwidth
                        value={values.city}
                        onChange={(e) => {
                          setCity(e.target.value);
                          handleChange(e);
                        }}
                        name="city"
                        fullWidth
                        onBlur={handleBlur}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="State"
                        fullwidth
                        value={values.state}
                        onChange={(e) => {
                          setState(e.target.value);
                          handleChange(e);
                        }}
                        name="state"
                        fullWidth
                        onBlur={handleBlur}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        // InputLabelProps={{ shrink: Boolean(values.zip) }}
                        label="Zip"
                        fullwidth
                        value={values.zip}
                        onChange={(e)=>{
                          setZip(e.target.value)
                          handleChange(e);
                        }}
                        name="zip"
                        fullWidth
                        onBlur={handleBlur}
                        error={Boolean(touched.zip && errors.zip)}
                        helperText={touched.zip && errors.zip}


                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="number"
                        // InputLabelProps={{ shrink: Boolean(values.building_rooms_beds) }}
                        label="Bedroom(s)"
                        value={values.building_rooms_beds}
                        fullwidth
                        name="building_rooms_beds"
                        onChange={(e)=> {
                          // handleChange(e)
                          // mySetFieldValue("bedrooms",e);
                          // console.log(values)
                          setBed(e.target.value)
                        }}
                        // value={values.building_rooms_beds}
                        value={bed}
                        onFocus={handleFocusBed}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="number"
                        // InputLabelProps={{ shrink: Boolean(values.building_rooms_bathsfull) }}
                        label="Bathroom(s)"
                        fullwidth
                        name="building_rooms_bathsfull"
                        onChange={(e)=> {
                          // handleChange(e)
                          // mySetFieldValue("Bathroom",e);
                          setBath(e.target.value)
                        }}
                        // value={values.building_rooms_bathsfull}
                        value={bath}
                        onFocus={handleFocusBath}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="number"
                        label="1/2 Bath(s)"
                        fullwidth
                        onChange={(e)=> {
                          // handleChange(e)
                          // mySetFieldValue("halfBaths",e);
                          setHalfBath(e.target.value)
                        }}
                        name="half_bathroom"
                        value={halfBath}
                        onFocus={handleFocusHalfBath}
                        // value={values.half_bathroom}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <FieldArray
                        name="friends"
                        render={arrayHelpers => (
                          <>
                            {values.remodeling.map(
                              (friend, index) => (
                                <div key={index}>

                                  <Divider variant="middle">
                                    <Chip
                                      icon={getDividerIconByTag(friend.tag)}
                                      label={friend.unit} color="primary"/>
                                  </Divider>
                                  <Grid container mt={12} mb={12} spacing={5} sx={{}}>
                                    <Grid item xs={12} md={6} mb={4}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Time frame</InputLabel>
                                      <Select
                                        style={{width:"100%",height:'52px' , fontSize:"0.8rem"}}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        label="Time frame"
                                        fullwidth
                                        select
                                        fullWidth
                                        // SelectProps={{
                                        //   native: true,
                                        // }}
                                        labelId={`frame-${index}`}
                                        id="demo-simple-select"
                                        sx={{ width: "100%", fontSize:"22px" }}
                                        name={`remodeling.${index}.time_frame`}
                                        value={friend.time_frame}
                                        MenuProps={{
                                          sx: {
                                            "&& .Mui-selected": {
                                              backgroundColor: "rgb(0, 115, 230)",
                                              color:"#FFFFFF",
                                              width:"100%"
                                            },
                                            "&& .Mui-selected:hover": {
                                              backgroundColor:"rgb(0, 115, 230)",
                                              color:"#FFFFFF",
                                              width:"100%"

                                            },
                                            "& .MuiMenuItem-root:hover": {
                                              backgroundColor: "rgb(0, 115, 230)",
                                              color:"#FFFFFF",
                                              width:"100%"

                                            },
                                            "& .MuiMenuItem-root": {
                                              backgroundColor: "rgba(0, 0, 0,0.05)",
                                            },
                                          }
                                        }}
                                        onChange={(e)=> {
                                          handleChange(e)
                                          mySetFieldValue(friend.unit+"timeFrame",e)
                                        }}
                                        // startAdornment={
                                        //   <InputAdornment position="start">
                                        //     <AccessTimeIcon/>
                                        //   </InputAdornment>
                                        // }
                                      >
                                            <MenuItem value="Less than a year" sx={{borderRadius:"0.4rem 0.4rem 0 0 !important"}}> Less than a year</MenuItem>
                                            <MenuItem value="1-5 years">1-5 years</MenuItem>
                                            <MenuItem value="6-10 years">6-10 years</MenuItem>
                                            <MenuItem value="11-15 years">11-15 years</MenuItem>
                                            <MenuItem value="Unknown" sx={{borderRadius:"0 0 0.4rem 0.4rem  !important"}}>Unknown</MenuItem>
                                      </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                          type="number"
                                          sx={{ width: "100%" }}
                                          label="Estimated cost"
                                          value={friend.estimated_cost}
                                          name={`remodeling.${index}.estimated_cost`}
                                          onFocus={(e)=>{
                                            mySetFieldValue(friend.unit+"estimatedCost",'')
                                          }}
                                          onChange={(e)=> {
                                            handleChange(e)
                                            mySetFieldValue(friend.unit+"estimatedCost",e);
                                            handleTotal(isNaN(parseInt(e.target.value))? 0 :parseInt(e.target.value) , index+1)
                                            // setTotal2(prevState => ({
                                            //   ...prevState,
                                            //   [total2[(index.toString())]]: parseInt(e.target.value)
                                            // }))
                                            // setFakeState(fakeState+1)

                                          }}
                                          error={Boolean(isInvalid(`remodeling.${index}.estimated_cost`, errors, touched))}
                                          helperText={getError(`remodeling.${index}.estimated_cost`, errors, touched)}
                                          startAdornment={
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          }
                                        />
                                    </Grid>
                                  </Grid>
                                </div>
                              )
                            )}

                          </>
                        )
                        }
                      />
                    </Grid>

                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      label="Unique Neighborhood Characteristics"
                      value={values.unique_characteristics}
                      fullWidth
                      onBlur={handleBlur}
                      multiline="true"
                      onChange={(e)=> {
                        handleChange(e.target.value)
                        mySetFieldValue("unique_characteristics",e.target.value)
                      }}
                      name="unique_characteristics"
                      rows={5}
                      maxRows={5}
                      inputProps={{
                        // disableUnderline: false,
                        maxlength: 120,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                      }}

                      sx={{
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                        // display: '-webkit-box',
                        // WebkitLineClamp: '2',
                        // WebkitBoxOrient: 'vertical',
                      }}

                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sx={{mt:2}} xs={12} md={12} lg={12}>
                    <TextField
                      label="Important features"
                      value={values.important_features}
                      fullWidth
                      onBlur={handleBlur}
                      multiline="true"
                      onChange={(e)=> {
                        handleChange(e.target.value)
                        mySetFieldValue("important_features",e.target.value);
                      }}
                      name="important_features"
                      rows={5}
                    />
                  </Grid>

                </CardContent>

                <CardActions
                  sx={{ direction: "rtl" }}
                >
                  <Grid container>
                    <Grid item xs={3} md={3} lg={2}>
                      <Button fullWidth type="submit" variant="contained" color="primary">
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={3} lg={2}>
                      <Button fullWidth sx={{mr:2}} variant="contained" color="primary"  component={RouterLink} to="/">
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>

                </CardActions>
              </form>
          )}
        </Formik>
      </Card>

    </>
  )


};


export default PropertyGeneralInfo;
