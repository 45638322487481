const setPropertyInfoReducer = (propertyInfo = {}, action) => {
  switch (action.type) {
    case "SET_PROPERTY":
      return action.payload;
    case "SET_PROPERTY_GENERAL_INFO":
      let s = propertyInfo?.general ? propertyInfo.general : {};
      let general = Object.assign(s, { ...action.payload });
      return { ...propertyInfo, general };

    case "SET_PROPERTY_LOOKUP":
      let lookup = Object.assign(propertyInfo.lookup_property_data, { ...action.payload });
      return { ...propertyInfo, ...lookup };

    case "SET_PROPERTY_REMODELING":
      return { ...propertyInfo, remodeling_or_update: action.payload };

    case "SET_PROPERTY_ID":
      return { ...propertyInfo, id: action.payload };

    case "SET_PROPERTY_IMAGES":
      return {...propertyInfo, images: action.payload};
    default:
      return propertyInfo;
  }
};


export { setPropertyInfoReducer };

