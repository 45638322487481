import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { InputAdornment, MenuItem } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import axios from "../utils/axios";
import { CircularProgress, FormControl, InputLabel, Menu, Select, Switch } from "@mui/material";
import useSnackbar from "../hooks/useSnackbar";
import { makeStyles } from '@mui/styles';
import makeAnimated from "react-select/animated";


const styles =makeStyles({
  root: {
    "&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover": {
      color: "red"
    }
  },
  select: {
    color: 'yellow'
  }
});


function SubjectFields(props) {
  const { alert } = useSnackbar();

  const [subjectFields , setSubjectFields]=useState({
    street:'',
    city:"",
    state:"",
    zip:"",
    proximity:"",
    salePrice:"",
    dateClosed:null,
    dataSource:"",
    status:"",
    siteSize:'',
    view:"",
    floorHeight:"",
    actualAge:"",
    condition:"",
    bedRooms:"",
    bathRooms:"",
    halfBath:"",
    livingArea:"",
    basement:"" ,
    garage:"",
    pool:"",
    additional:""
  })
  const carportGarage = [
    'None',
    '1 Car Garage',
    '2 Car Garage',
    '3 Car Garage',
    '4 Car Garage',
    '5 Car Garage',
    '6 Car Garage',
    '1 Car Carport',
    '2 Car Carport',
    '3 Car Carport',
    '4 Car Carport'
  ];

  const [forBefore ,setForBefore]=useState(true)
  useEffect(()=>{
    if(forBefore){
      setSubjectFields(props.subjectFields)
    }else {
      setForBefore(true)
    }
  },[props.subjectFields])

  const [firstTime , setFirstTime]=useState(true)

  useEffect(()=>{
    if(firstTime){
      setFirstTime(false)
    }else {
      setForBefore(false)
      props.setSubjectFields(subjectFields)

    }
  },[props.beforeSubmit])

  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }
  function getDistanceFromLatLonInMiles(lat1,lon1,lat2,lon2) {
    let R = 6371; // Radius of the earth in km
    let dLat = deg2rad(lat2-lat1);  // deg2rad below
    let dLon = deg2rad(lon2-lon1);
    let a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = R * c * 0.621371; // Distance in km
    return d;
  }

  const [currentTime , setCurrenttime]=useState(0)
  const getLatLng= (address1 , city1 ,zip1 )=>{
      const currentTimeInMilliseconds = Date.now() / 1000;

      // if (currentTimeInMilliseconds - 5 > currentTime) {
      //   setCurrenttime(currentTimeInMilliseconds)

        let address = subjectFields?.street
        let city = subjectFields?.city
        let zip = subjectFields?.zip
        let lat = subjectFields.lat
        let lng = subjectFields.lng
        if (
          address === undefined || address === null || address === '' ||
          city === undefined || city === null || city === '' ||
          zip === undefined || zip === null || zip === ''|| zip?.length <5
          // lat=== undefined     ||lat=== null     || lat==='' ||
          // lng=== undefined     ||lng=== null     || lng==='' ||
          // subjectFields.proximity===''|| subjectFields.proximity===null || props.subjectFields.proximity===''||  props.subjectFields.proximity===null
        ) {

        } else {
          if(zip?.length>=5){
            axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${address}&city=${city}&zipcode=${zip}`)
              .then((res) => {
                setProxi(res.data.lat.toString(), res.data.lng.toString(), address, city, zip)
              })
          }

        }

      // }


  }



  // useEffect(()=>{
  //   let address =subjectFields.street
  //   let city =subjectFields.city
  //   let zip =subjectFields.zip
  //   let lat = subjectFields.lat
  //   let lng = subjectFields.lng
  //   if(
  //     address=== undefined ||address=== null || address==='' ||
  //     city=== undefined    ||city=== null    || city===''    ||
  //     zip=== undefined     ||zip=== null     || zip===''
  //     // lat=== undefined     ||lat=== null     || lat==='' ||
  //     // lng=== undefined     ||lng=== null     || lng==='' ||
  //     // subjectFields.proximity===''|| subjectFields.proximity===null || props.subjectFields.proximity===''||  props.subjectFields.proximity===null
  //   ){
  //     console.log('thhhhis')
  //
  //   }else {
  //     console.log(props.subjectFields.proximity ,'props.subjectFields.proximity')
  //     axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${address}&city=${city}&zipcode=${zip}`)
  //       .then((res)=>{
  //         // props.setSubjectFields({...props.subjectFields ,
  //         //   street:address,
  //         //   city:city,
  //         //   state:subjectFields.state,
  //         //   zip:zip,
  //         // })
  //         // setSubjectFields({...subjectFields ,
  //         //   street:address,
  //         //   city:city,
  //         //   state:subjectFields.state,
  //         //   zip:zip,
  //         // })
  //         setProxi(res.data.lat.toString() , res.data.lng.toString() , address , city , zip)
  //         // setSubjectFields({...subjectFields , lat:res.data.lat.toString() , lng:res.data.lng.toString()})
  //         // props.setSubjectFields({...subjectFields , lat:res.data.lat.toString() , lng:res.data.lng.toString()})
  //       })
  //   }
  //
  // },[ subjectFields.city ,subjectFields.street , subjectFields.zip])

  // console.log('proxi' , subjectFields.proximity)

  const [proxyLatLng ,setProxyLatLng]=useState({
    proximity:"",
    lat:"",
    lng:""
  })


  const setProxi = (lat , lng , address , city  ,zip)=>{

    if(props.subLatLng===undefined){

    }else if(
      props.subLatLng?.lat===''||props.subLatLng?.lat===null||props.subLatLng?.lat===undefined|| props.subLatLng?.lat===0||
      props.subLatLng?.lng===''||props.subLatLng?.lng===null||props.subLatLng?.lng===undefined|| props.subLatLng?.lng===0
    ){}else {
      let distance =  getDistanceFromLatLonInMiles(props.subLatLng?.lat , props.subLatLng?.lng , lat , lng)
      props.setProximity(distance)
      // setSubjectFields({...subjectFields , proximity:distance , lat :lat , lng : lng , })
      // console.log(props.subLatLng?.lat , props.subLatLng?.lng , subjectFields.lat , subjectFields.lng)
      // console.log(distance)
    }
  }
  // useEffect(()=>{
  //
  //
  // },[subjectFields.lat , subjectFields.lng])
  const [loadingLookup, setLoadingLookup]=useState(false)
  const [value ,setValue]=useState(false)
  useEffect(()=>{
    setValue(false)
  },[props.rowId])
  const lookup =(address, city, state, zip)=>{

    if(!value){
      setLoadingLookup(true)

      if(
        address=== undefined ||address=== null || address==='' ||
        city=== undefined    ||city=== null    || city===''    ||
        state=== undefined   ||state=== null   || state===''   ||
        zip=== undefined     ||zip=== null     || zip===''
      ){
        alert({ message: "provide Address, City , State and Zip to use lookup data", severity: "error" });
        setLoadingLookup(false)

      }else {

        axios.post("https://thecompgenie.com/api/comparables/lookup/",{
            address: address, city: city, state: state, zip: zip
          },{
            headers:{
              Authorization:"Bearer "+window.localStorage.getItem('accessToken')
            }
          }
        ).then((res)=>{
          let currentDateAndTime = new Date()
          let currentYear = currentDateAndTime.getFullYear()
          if(res.data?.Data===undefined){
            setLoadingLookup(false)
            setValue(true)
            // setSubjectFields({...subjectFields ,
            //   salePrice:res.data[0]['SALES_HISTORY']['@PropertySalesAmount'],
            //   dateClosed:res.data[0]['SALES_CONTRACT']['@_Date'],
            //   siteSize: res.data[0]['SITE']['@LotSquareFeetCount'],
            //   actualAge:res.data[0]['STRUCTURE']['STRUCTURE_ANALYSIS']['@PropertyStructureBuiltYear'],
            //   bedRooms:res.data[0]['STRUCTURE']['@TotalBathroomCount'],
            //   bathRooms:res.data[0]['STRUCTURE']['@TotalBedroomCount'],
            //   livingArea: res.data[0]['STRUCTURE']['@GrossLivingAreaSquareFeetCount'],
            //   garage:res.data[0]['STRUCTURE']['CAR_STORAGE']['CAR_STORAGE_LOCATION']['@_ParkingSpacesCount'] + ' Car Garage',
            //   additional:res.data[0]["_LEGAL_DESCRIPTION"]['@_TextDescription'] ,
            //   lat :res.data[0]["_IDENTIFICATION"]['@LatitudeNumber'],
            //   lng:res.data[0]["_IDENTIFICATION"]['@LongitudeNumber']
            // })
            props.setSubjectFields({ ...props.subjectFields ,
              street:res.data[0]['@_StreetAddress'],
              state:res.data[0]['@_State'],
              zip:res.data[0]['@_PostalCode'],
              city:res.data[0]['@_City'],
              proximity:subjectFields?.proximity,
              salePrice:res.data[0]['SALES_HISTORY']['@PropertySalesAmount'],
              dateClosed:res.data[0]['SALES_CONTRACT']['@_Date'],
              // dataSource:"",
              // status:"",
              siteSize: res.data[0]['SITE']['@LotSquareFeetCount']?.split('.')[0]===undefined?'':res.data[0]['SITE']['@LotSquareFeetCount']?.split('.')[0],
              // view:"",
              // floorHeight:"",
              actualAge: currentYear - parseInt(res.data[0]['STRUCTURE']['STRUCTURE_ANALYSIS']['@PropertyStructureBuiltYear']) ,
              // condition:"",
              bedRooms:res.data[0]['STRUCTURE']['@TotalBedroomCount']===''||res.data[0]['STRUCTURE']['@TotalBedroomCount']===undefined
                ? props?.subjectFields?.bedRooms : parseInt(res.data[0]['STRUCTURE']['@TotalBedroomCount']),
              bathRooms:res.data[0]['STRUCTURE']['@TotalBathroomCount']===''||res.data[0]['STRUCTURE']['@TotalBathroomCount']===undefined
                ? props?.subjectFields?.bathRooms : parseInt(res.data[0]['STRUCTURE']['@TotalBathroomCount']),
              // halfBath:"",
              livingArea: res.data[0]['STRUCTURE']['@GrossLivingAreaSquareFeetCount'],
              // basement:"" ,
              garage:res.data[0]['STRUCTURE']['CAR_STORAGE']['CAR_STORAGE_LOCATION']['@_ParkingSpacesCount'] + ' Car Garage',
              // pool:"",
              additional:res.data[0]["_LEGAL_DESCRIPTION"]['@_TextDescription'] ,
              lat :res.data[0]["_IDENTIFICATION"]['@LatitudeNumber'],
              lng:res.data[0]["_IDENTIFICATION"]['@LongitudeNumber']
            })
            // setProxi(res.data[0]["_IDENTIFICATION"]['@LatitudeNumber'] ,res.data[0]["_IDENTIFICATION"]['@LongitudeNumber'] )
          }else {
            alert({ message: 'Data Not Found', severity: "error" });
            setLoadingLookup(false)
            setValue(false)
          }

        }).catch((e)=>{
          alert({ message: e.message, severity: "error" });
          setLoadingLookup(false)
          setValue(false)
        })

      }}
  }

  const parrentSwitch = document.getElementById('switchParrent'+props.idTag)
  const top = parrentSwitch?.offsetTop
  const left = parrentSwitch?.offsetLeft
//   const styles = theme => ({
//
//       backgroundColor: "#ffffff",
//       color:"black",
//       '&:hover': {
//         backgroundColor: "rgba(0,115,246,1)",
//         color:"#ffffff"
//       },
//
//
// });
  const animatedComponents = makeAnimated();


  const classes =styles()
  return (
    <div style={{width:"100%"}}>
      <div id={'switch'} style={{position:"absolute",left:left - 50 , top:top }} className={'use-lookup-container'}>
        {
           loadingLookup? <span style={{width:"58px", color:"#FFFFFF" , height:"38px" , display:"flex",flexDirection:"row" , alignItems:"center" , justifyContent:"center"}}>
                      <CircularProgress color="inherit" size={20} disableShrink />
                 </span>
            : <Switch
              checked={value}
              onChange={() => {
                lookup(subjectFields?.street ,subjectFields?.city , subjectFields?.state,subjectFields?.zip )
              }}

            />
        }

        <span style={{fontSize:"0.8rem" , color:"#FFFFFF"}}>Use lookup data </span>
      </div>

      <Grid item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          sx={{boxShadow:1
          }}
          label="Street"
          fullwidth
          value={subjectFields?.street}
          name={props.name+'Street'}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,street: e.target.value});
            // getLatLng(e.target.value , subjectFields.city , subjectFields.zip )
          }}
          onBlur={()=>{
            getLatLng(subjectFields.street ,  subjectFields.city , subjectFields.zip )
          }}
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>
      <Grid marginTop={5} item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          label="City"
          fullwidth
          value={subjectFields?.city}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,city: e.target.value});
            // getLatLng(subjectFields.street , e.target.value , subjectFields.zip )
          }}
          name={props.name+'City'}

          onBlur={()=>{
            getLatLng(subjectFields.street ,  subjectFields.city , subjectFields.zip )
          }}
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          label="State"
          name={props.name+'State'}

          fullwidth
          value={subjectFields?.state}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,state: e.target.value});
          }}
          onBlur={()=>{
            getLatLng(subjectFields.street ,  subjectFields.city , subjectFields.zip )
          }}
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />

      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          label="Zip"

          fullwidth
          value={subjectFields?.zip}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,zip: e.target.value});
            // getLatLng(subjectFields.street ,  subjectFields.city , e.target.value )

          }}
          onBlur={()=>{
            getLatLng(subjectFields.street ,  subjectFields.city , subjectFields.zip )
          }}
          name={props.name+'Zip'}
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>
      {
        props.subLatLng===undefined?
          <div style={{width:"100%" , height:"70px" , paddingLeft:"20px", paddingTop:"20px" , display:"flex", flexDirection:"row", alignItems:"center" , justifyContent:"space-between"}}/>
            :
          <div style={{width:"100%" , height:"70px" , paddingLeft:"20px", paddingTop:"20px" , display:"flex", flexDirection:"row", alignItems:"center" , justifyContent:"space-between"}}>
            <div style={{marginLeft:"20px"}}>
              Proximity
            </div>
            <div style={{marginRight:"20px"}}>
              {props.proximity===undefined || props.proximity===''||  props.proximity===null?'': parseFloat(props.proximity).toFixed(3)+' mi'}

              {/*{subjectFields.proximity===undefined || subjectFields.proximity===''||  subjectFields.proximity===null?'': parseFloat(subjectFields.proximity).toFixed(3)+' mi'}*/}
            </div>
          </div>
      }




      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          label="Sales Price"
          fullwidth
          value={subjectFields?.salePrice}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,salePrice: e.target.value});
          }}
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>

      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth
        >
          <DatePicker
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            fullwidth
            label="Date Closed"

            // value={subjectFields?.dateClosed === undefined || subjectFields?.dateClosed === null ? null : typeof subjectFields?.dateClosed ==="string" ? subjectFields?.dateClosed : null}
            // onBlur={handleBlur}
            value={subjectFields?.dateClosed === undefined  || subjectFields?.dateClosed === null ? null :subjectFields?.dateClosed}
            onChange={(e) => {
              setSubjectFields({ ...subjectFields ,dateClosed: e });
            }}
            toolbarPlaceholder={'mm-dd-yyyy'}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>

      <TextField
          InputLabelProps={{
            shrink: true,
          }}
          label="Data Source (MLS#)"
          fullwidth
          value={subjectFields?.dataSource}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,dataSource: e.target.value});
          }}
          name="sSource"
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          // sx={{
          //   '&& .MuiList-root':{
          //     boxShadow:"10px 10px 10px red"
          //   },
          // }}
          variant={'outlined'}
          InputLabelProps={{
            shrink: true,
          }}
          label="Status"
          fullwidth
          select
          value={subjectFields?.status}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,status: e.target.value});
          }}
          // components={animatedComponents}
          name="sStatus"
          fullWidth
          sx={{

          }}
          MenuProps={{
            sx: {
              borderRadius:"1rem !important",

              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF",
                borderRaduis:"0.5rem"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {


                backgroundColor: "rgba(0, 0, 0,0.05)",
              },

            }
          }}
          // MenuProps={{ classes: { paper: classes.select } }}
          // theme={theme}

          // SelectProps={{
          //   native: true,
          // }}
        >
          {/* <MenuItem value="unknown">None </MenuItem>*/}
          {/* <MenuItem value="Closed">Closed </MenuItem>*/}
          {/* <MenuItem value="Active">Active </MenuItem>*/}
          {/* <MenuItem value="Pending">Pending </MenuItem>*/}
            <MenuItem sx={{   borderRadius:"0.4rem 0.4rem 0 0 !important"}} value="None">None</MenuItem>
            <MenuItem value="Closed">Closed</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem sx={{   borderRadius:" 0 0 0.4rem 0.4rem !important"}} value="Pending">Pending</MenuItem>



        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
      <TextField
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
          }}
          label="Site Size"
          fullwidth
          value={subjectFields?.siteSize}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,siteSize: e.target.value});
          }}
          name="sSiteSize"
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">View</InputLabel>
          <Select
              InputLabelProps={{
                shrink: true,
              }}
              label="View"
              fullwidth
              select
              value={subjectFields?.view}
              onChange={(e) => {
                setSubjectFields({ ...subjectFields ,view: e.target.value});
              }}
              name="sView"
              fullWidth
              // SelectProps={{
              //   native: true,
              // }}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: "rgb(0, 115, 230)",
                    color:"#FFFFFF"
                  },
                  "&& .Mui-selected:hover": {
                    backgroundColor:"rgb(0, 115, 230)",
                    color:"#FFFFFF"
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "rgb(0, 115, 230)",
                    color:"#FFFFFF"
                  },
                  "& .MuiMenuItem-root": {
                    backgroundColor: "rgba(0, 0, 0,0.05)",
                  },
                }
              }}
            >
              <MenuItem value="None" sx={{   borderRadius:"0.4rem 0.4rem 0 0 !important"}}>None </MenuItem>

              <MenuItem value="Water">Water </MenuItem>
              <MenuItem value="Woods">Woods </MenuItem>
              <MenuItem value="Park">Park </MenuItem>
              <MenuItem value="Golf Course">Golf Course </MenuItem>
              <MenuItem value="City/Skyline">City/Skyline </MenuItem>
              <MenuItem value="Mountain">Mountain </MenuItem>
              <MenuItem value="Residential">Residential </MenuItem>
              <MenuItem value="City Street">City Street </MenuItem>
              <MenuItem value="Industrial">Industrial </MenuItem>
              <MenuItem value="Power Lines">Power Lines </MenuItem>
              <MenuItem value="Limited Sight">Limited Sight </MenuItem>
              <MenuItem value="Other" sx={{   borderRadius:" 0 0 0.4rem 0.4rem !important"}}>Other </MenuItem>

            </Select>

        </FormControl>

      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Floor Height</InputLabel>
        <Select
          InputLabelProps={{
            shrink: true,
          }}
          // SelectProps={{
          //   native: true,
          // }}
          label="Floor Height"
          fullwidth
          select
          value={subjectFields?.floorHeight}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,floorHeight: e.target.value});
          }}
          name="sFloor"
          fullWidth
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "rgba(0, 0, 0,0.05)",
              },
            }
          }}
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        >
          {Array.from({ length: 100 }, (_, i) => i).map((item,index) => (
            <MenuItem sx={index===0||index===100?{borderRadius:index===0?"0.4rem 0.4rem 0 0 !important":"0 0 0.6rem 0.6rem  !important"}:{}} key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
          }}
          label="Actual Age"
          fullwidth
          value={subjectFields?.actualAge}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,actualAge: e.target.value});
          }}
          name="sAge"
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Condition</InputLabel>
          <Select
            InputLabelProps={{
              shrink: true,
            }}
            label="Condition"
            fullwidth
            select
            value={subjectFields?.condition}
            onChange={(e) => {
              setSubjectFields({ ...subjectFields ,condition: e.target.value});
            }}
            name="sCondition"
            fullWidth
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "rgb(0, 115, 230)",
                  color:"#FFFFFF"
                },
                "&& .Mui-selected:hover": {
                  backgroundColor:"rgb(0, 115, 230)",
                  color:"#FFFFFF"
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "rgb(0, 115, 230)",
                  color:"#FFFFFF"
                },
                "& .MuiMenuItem-root": {
                  backgroundColor: "rgba(0, 0, 0,0.05)",
                },
              }
            }}
            // SelectProps={{
            //   native: true,
            // }}
          >
            <MenuItem value="None" sx={{   borderRadius:"0.4rem 0.4rem 0 0 !important"}}>None</MenuItem>

            <MenuItem value="Complete disaster">Complete disaster</MenuItem>
            <MenuItem value="Original but needs work">Original but needs work</MenuItem>
            <MenuItem value="Original but well kept">Original but well kept</MenuItem>
            <MenuItem value="Some updates/renovation">Some updates/renovation</MenuItem>
            <MenuItem value="Substantial remodel">Substantial remodel</MenuItem>
            <MenuItem value="Rebuilt as new or new construction" sx={{   borderRadius:"0 0 0.4rem 0.4rem !important"}}>
              Rebuilt as new or new construction
            </MenuItem>

          </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Bedrooms</InputLabel>
        <Select
          InputLabelProps={{
            shrink: true,
          }}
          label="Bedrooms"
          fullwidth
          select
          value={subjectFields?.bedRooms}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,bedRooms: e.target.value});
          }}
          name="sBedRooms"
          fullWidth
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "rgba(0, 0, 0,0.05)",
              },
            }
          }}
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        >
          {Array.from({ length: 100 }, (_, i) => i).map((item,index) => (
            <MenuItem key={item} value={item} sx={index===0||index===100?{borderRadius:index===0?"0.4rem 0.4rem 0 0 !important":"0 0 0.4rem 0.4rem  !important"}:{}}>
              {item}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Full Bath(s)</InputLabel>
        <Select
          InputLabelProps={{
            shrink: true,
          }}
          // SelectProps={{
          //   native: true,
          // }}
          label="Full Bath(s)"
          fullwidth
          select
          value={subjectFields?.bathRooms}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,bathRooms: e.target.value});
          }}
          name="sFBath"
          fullWidth
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "rgba(0, 0, 0,0.05)",
              },
            }
          }}
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        >
          {Array.from({ length: 100 }, (_, i) => i).map((item,index) => (
            <MenuItem key={item} value={item} sx={index===0||index===100?{borderRadius:index===0?"0.4rem 0.4rem 0 0 !important":"0 0 0.4rem 0.4rem  !important"}:{}}>
              {item}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Half Bath(s)</InputLabel>
        <Select
          InputLabelProps={{
            shrink: true,
          }}
          label="Half Bath(s)"
          fullwidth
          select
          value={subjectFields?.halfBath}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,halfBath: e.target.value});
          }}
          name="sHBath"
          fullWidth
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "rgba(0, 0, 0,0.05)",
              },
            }
          }}
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        >
          {Array.from({ length: 100 }, (_, i) => i).map((item,index) => (
            <MenuItem key={item} value={item} sx={index===0||index===100?{borderRadius:index===0?"0.4rem 0.4rem 0 0 !important":"0 0 0.4rem 0.4rem  !important"}:{}}>
              {item}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
          }}
          label="Living Area Under Air"
          fullwidth
          value={subjectFields?.livingArea}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,livingArea: e.target.value});
          }}
          name="sArea"
          fullWidth
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
        />
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Basement </InputLabel>
        <Select
          InputLabelProps={{
            shrink: true,
          }}
          label="Basement"
          fullwidth
          select
          value={subjectFields?.basement}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,basement: e.target.value});
          }}
          name="sBasement"
          fullWidth
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "rgba(0, 0, 0,0.05)",
              },
            }
          }}
          // SelectProps={{
          //   native: true,
          // }}
        >
          <MenuItem value="None" sx={{borderRadius:"0.4rem 0.4rem 0 0 !important"}}>None </MenuItem>
          <MenuItem value="Yes">Yes </MenuItem>
          <MenuItem value="No">No </MenuItem>
          <MenuItem value="Partial" sx={{borderRadius:"0 0 0.4rem 0.4rem !important"}}>Partial </MenuItem>

        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Garage/Carport</InputLabel>
        <Select
          InputLabelProps={{
            shrink: true,
          }}
          label="Garage/Carport"
          fullwidth
          select
          value={subjectFields?.garage}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,garage: e.target.value});
          }}
          name="sGarage"
          fullWidth
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "rgba(0, 0, 0,0.05)",
              },
            }
          }}
          // SelectProps={{
          //   native: true,
          // }}
        >
          {carportGarage.map((item,index) => (
            <MenuItem value={item} sx={index===0||index===10?{borderRadius:index===0?"0.4rem 0.4rem 0 0 !important":"0 0 0.4rem 0.4rem  !important"}:{}}>
              {item}
            </MenuItem>
          ))}

        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Pool, Patio, Porch, Deck</InputLabel>
        <Select
          InputLabelProps={{
            shrink: true,
          }}
          label="Pool, Patio, Porch, Deck"
          fullwidth
          select
          value={subjectFields?.pool}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,pool: e.target.value});
          }}
          name="sDeck"
          fullWidth
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "&& .Mui-selected:hover": {
                backgroundColor:"rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "rgb(0, 115, 230)",
                color:"#FFFFFF"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "rgba(0, 0, 0,0.05)",
              },
            }
          }}
          // SelectProps={{
          //   native: true,
          // }}
        >
          <MenuItem value="None"  sx={{   borderRadius:" 0.4rem 0.4rem 0 0 !important"}}>None </MenuItem>

          <MenuItem value="Pool">Pool </MenuItem>
          <MenuItem value="Patio">Patio </MenuItem>
          <MenuItem value="Porch">Porch </MenuItem>
          <MenuItem value="Deck"  sx={{   borderRadius:"0 0 0.4rem 0.4rem !important"}}>Deck </MenuItem>
        </Select>
        </FormControl>
      </Grid>
      <Grid  marginTop={5}  item xs={12} md={6} lg={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          label="Additional Info"
          fullwidth
          name="sAdditional"
          fullWidth
          value={subjectFields?.additional}
          onChange={(e) => {
            setSubjectFields({ ...subjectFields ,additional: e.target.value});
          }}
          // onBlur={handleBlur}
          // error={Boolean(touched.city && errors.city)}
          // helperText={touched.city && errors.city}
          // onChange={handleChange}
        />
      </Grid>



    </div>
  );
}

export default SubjectFields;