import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';

// examples:
import GoogleMap from './GoogleMap';

// consts: [34.0522, -118.2437]
import LOS_ANGELES_CENTER from './la_center';

const getInfoWindowString = (place) => `
    <div>
      <div style="font-size: 16px;">
        ${place.name}
      </div>
      <div style="font-size: 14px;">
        <span style="color: grey;">
        ${place.rating}
        </span>
        <span style="color: orange;">${String.fromCharCode(9733).repeat(Math.floor(place.rating))}</span><span style="color: lightgrey;">${String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}</span>
      </div>
      <div style="font-size: 14px; color: grey;">
        ${place.types[0]}
      </div>
      <div style="font-size: 14px; color: grey;">
        ${'$'.repeat(place.price_level)}
      </div>
      <div style="font-size: 14px; color: green;">
        ${place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div>
    </div>`;

// Refer to https://github.com/google-map-react/google-map-react#use-google-maps-api
const handleApiLoaded = (map, maps, places) => {
  const markers = [];
  const infowindows = [];

  places.forEach((place) => {
    markers.push(new maps.Marker({
      position: {
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng,
      },
      map,
    }));

    infowindows.push(new maps.InfoWindow({
      draggable: true,
      content: getInfoWindowString(place),
    }));
  });

  markers.forEach((marker, i) => {
    marker.addListener('click', () => {
      infowindows[i].open(map, marker);
    });
  });
};

class MarkerInfoWindowGmapsObj extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
    };
  }

  componentDidMount() {
    fetch('https://google-map-react.github.io/google-map-react-examples/places.json')
      .then((response) => response.json())
      .then((data) => {
        data.results.forEach((result) => {
          result.show = false; // eslint-disable-line no-param-reassign
        });
        this.setState({ places: data.results.slice(0,4) });
      });
  }

  render() {
    const { places } = this.state;
    return (
      <>
        <div style={{ height: '85vh', width: '100%' }} >

        {!isEmpty(places) && (
          <GoogleMap
            options={{
              disableDefaultUI: true,
              mapTypeControl: true,
              streetViewControl: true,
              styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
            }}
            defaultZoom={12}
            defaultCenter={LOS_ANGELES_CENTER}
            bootstrapURLKeys={{ key: 'AIzaSyC67iRupm53Y7eLhmNO5wXthRaX2KHggsw' }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, places)}
          />
        )}
        </div>
      </>
    );
  }
}

export default MarkerInfoWindowGmapsObj;
