import Box from "@mui/material/Box";
import React, { useContext, useState } from "react";

import ComparableGeneralInfo from "../../components/comparables/ComparableGeneralInfo";
import useSnackbar from "../../../hooks/useSnackbar";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { Helmet } from "react-helmet-async";
import { ComparableContext } from "../../../contexts/ComparableContext";
import axios from "../../../utils/axios";
import PropertyInfoPage from "../property/PropertyInfoPage";

const ComparableInfoPage = () => {

  const [getSaveData, setSaveData] = useState(false);
  const { comparableInfo, setComparableInfo } = useContext(ComparableContext);

  const { alert } = useSnackbar();
  // const ComparableInfo = useSelector(state => state.Comparable);
  const { id } = useParams();
  const { isInitialized } = useAuth();
  const [loading, setLoading] = useState(false);

  const handlesave = (payload) => {
    if (getSaveData) {
      // setRemodeling(payload);
      if (payload) {
        return;
      }


    }

  };

  const lookupDataFetch = async (address, city, state) => {
    try {
      let lookup = comparableInfo?.lookup_property_data;
      if (comparableInfo?.attom_id) {
        return { zip: lookup?.zip, baths: lookup?.baths, bedrooms: lookup?.bedrooms };
      }
      let resp = await axios.post("/properties/lookup/", {
        address: address, city: city, state: state
      });
      lookup = resp?.data?.lookup_property_data;
      if (lookup) {
        setComparableInfo({ ...comparableInfo, lookup_property_data: lookup });
      }
      return lookup;
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      return null;
    }

  };

  const lookupCompDataFetch = async (address, city, state, zip) => {
    try {
      let lookup = null;
      let resp = await axios.post("/comparables/lookup/", {
        address: address, city: city, state: state, zip: zip
      });
      lookup = resp.data
      if (lookup) {
        setComparableInfo({ ...comparableInfo, lookup_comp_data: lookup });
      }
      return lookup;
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      return null;
    }

  };


  return (
    <>
      <Helmet title={"Comparable"}/>
      <Box>
        {
          loading ?
            <Loader/>
            :
            (
              <>

                <Box mt={10}>
                  {/*<PropertyInfoPage/>*/}

                  <ComparableGeneralInfo lookupCompDataFetch={lookupCompDataFetch} lookupDataFetch={lookupDataFetch} />
                </Box>
              </>
            )
        }
      </Box>
    </>
  );

};

export default ComparableInfoPage;
