import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "../../../utils/axios";
import useSnackbar from "../../../hooks/useSnackbar";
import useAuth from "../../../hooks/useAuth";
import styled from "styled-components/macro";

const Avatar = styled.img`
  display: block;
  border-radius: 5px;
  margin-left: 100px;
  height: 200px;
  width: 200px;
`;

const UploadProfileAvatar = ({handleClose,imageSrc}) => {

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const {alert} = useSnackbar();
  const {user} = useAuth();

  const validateFile = (e) => {
    if (!e.target?.files[0]) {
      return;
    }
    let f = e.target.files[0];
    let err = null;
    let mimeType = f["type"];
    if (mimeType.split("/")[0] !== "image") {
      err = "Please select an image file";
    } else if (f.size === 0 || f.size > 200000) {
      err = "Maximum allowed file size is 100KB";
    }
    if (!err) {
      setFile(f);
      setSelected(true);
    }
    setError(err);

    if (!err) {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (eve) => {
        setLogoUrl(eve.target["result"]);
      };
    }

  };

  const submitAvatar = () => {
    setUploading(true);
    let formData = new FormData();
    formData.append("image", file);
    axios.post("/users/profile/logo/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => setProgress((progressEvent.loaded / progressEvent.total * 100))
    }).then(result => {
        setUploading(false);
        alert({message: "Profile image changed", severity: "success"});
        handleClose();
      }
    ).catch(err => {
        setUploading(false);
        setError(err.message);
      }
    );
  };

  return (
    <Grid container sx={{ textAlign: "center" }}>
      <Grid item xs={12}>
        <Box sx={{ width: 150, height: 150, mb: 4 }}>
          <Avatar src={!selected ? imageSrc : logoUrl}
                  sx={{ width: "100%", height: "100%" }}/>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ marginLeft: 5 }}>
          <Typography variant="h4" sx={{ marginTop: 15, marginBottom: 4 }}>
            {!uploading ? "Please select a file": "Uploading..."}
          </Typography>

          {
            !uploading ?
              (
                <Box sx={{ display: "flex", gap: 3, justifyContent: "center" }}>
                  <Button disabled variant="contained" color="primary" component="label">
                    <InsertDriveFileIcon/>
                    Browse
                    <input
                      type="file"
                      hidden
                      onChange={(e) => validateFile(e)}
                    />
                  </Button>

                  <Button
                    variant="contained"
                          color="success"
                          disabled={!selected}
                    onClick={(e) => submitAvatar()}
                  >
                    <CloudUploadIcon sx={{ mr: 1 }}/>
                    Upload
                  </Button>

                </Box>
              )
              :
              <LinearProgress variant="determinate" value={progress}/>
          }

          {error &&
          <Typography variant="subtitle1" sx={{ marginBottom: 3 }} color="error">
            {error}
          </Typography>
          }


        </Box>
      </Grid>

    </Grid>
  );

};


export default UploadProfileAvatar;
