import React from "react";
import { Helmet } from "react-helmet-async";
import ResetPasswordComponent from "../../components/auth/ResetPassword";
import styled from "styled-components/dist/styled-components-macro.esm";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { Paper, Typography } from "@mui/material";
import ResetPasswordSetPasswordComponent from "../../components/auth/ResetPasswordSetPasswordComponent";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";


// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 64px;
//   height: 64px;
//   margin-bottom: 32px;
// `;
//
// const Wrapper = styled(Paper)`
//   padding: ${(props) => props.theme.spacing(6)};
//
//   ${(props) => props.theme.breakpoints.up("md")} {
//     padding: ${(props) => props.theme.spacing(10)};
//   }
// `;

const ResetPasswordSetPassword = () => {

  return (

    <React.Fragment>
      <Helmet title="Set new password"/>
      <Card>
        <Typography mt={4} component="h1" variant="h4" align="center" gutterBottom>
          Insert new password
        </Typography>
        <CardContent>
          <Paper>
            <ResetPasswordSetPasswordComponent/>
          </Paper>
        </CardContent>
      </Card>


      {/*<Brand />*/}
      {/*<Wrapper>*/}
      {/* */}
      {/*</Wrapper>*/}
    </React.Fragment>
  );

};


export default ResetPasswordSetPassword;

