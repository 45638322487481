import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Button,
  Divider,
  Typography,
  Stack,
  Box,
  Grid
} from '@material-ui/core';
import { browserName, browserVersion } from "react-device-detect";

import { makeStyles } from '@material-ui/styles';
import { useReactToPrint } from 'react-to-print';
import './pageBreak.css';
import blue from "@material-ui/core/colors/blue";
import { Helmet } from "react-helmet-async";
import TablePrint from '../../components/preview/TablePrint';
import { ComparableContext } from "../../../contexts/ComparableContext";

import styled from "styled-components/macro";

import imageLogo from '../../components/preview/styles/img/Comp Genie-2-new.png'

import MarkerInfoWindowGmapsObj from "../../components/preview/map/MarkerInfoWindowGmapsObj";
// import axios from "../../../utils/axios";
import MapComponent from "../../components/preview/Map";
import { Container, TextField } from "@mui/material";
import Page from "../../components/preview/Page";
import PdfPage from "./PdfPage";
import { getDistance } from 'geolib';
import axios from 'axios'
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/Loader";
import html2canvas from "html2canvas";
import { createFileName, useScreenshot } from "use-react-screenshot";
import jsPDF from "jspdf";
import MyTable from "../../../MyTable/MyTable";
const uploadImage = require( "../../../vendor/uploadImage.jpg");
const useStyles = makeStyles({

  print: {
    padding: 20,
    paddingTop: '2rem',


  },
  '@media all': {
    pagebreak: {
      // marginTop: '1em',
      display: 'block',
      padding: '0rem',
    }
  },
  '@media print': {

    pagebreak: {
      'page-break-before': 'always'
    }
  },
  borderTypo: {
    borderRadius: '1em',
    borderWidth: 1,
    borderColor: '#000000',
    border: '1px solid #000000',
    padding: 4
  },
  boxInfo: {
    width: '100%',
    backgroundColor: 'black',
    height: '3.5rem',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '0.5rem',
    // padding: '16px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px'
  }
});


export default function PreviewPdf() {
  const { comparableInfo, setComparableInfo } = useContext(ComparableContext);
  const [ propertyInfo, setPropertyInfo ] = useState();
  const [ sumCost, setSumCost ] = useState(0)
  const [c1Proximity1 , setC1Proximity]=useState('')
  const [c2Proximity1 , setC2Proximity]=useState('')
  const [c3Proximity1 , setC3Proximity]=useState('')
  const [submiting , setSubmiting]=useState(0)
  const [compName , setCompName]=useState('The Comp Genie')

  const [data,setData]=useState([])

  const {id}=useParams()
  const getProperty = () => {
    // axios.get(`/properties/prop/${id}/`)
    //   .then(response => {
    //     console.log(response.data , 'data');
    //     // let newRows = response.data?.results?.map(e => createData(e.id, e?.inserted_property_data?.general));
    //     // setRows(newRows);
    //     setPropertyInfo(response.data)
    //
    //     // setC1Proximity('')
    //     // setC2Proximity('')
    //     // setC3Proximity('')
    //
    //     // setLoading(false);
    //
    //   }).catch(
    //   error => {
    //     console.log({ message: error.message, severity: "error" });
    //
    //
    //   }
    // );
  };
  const[loading ,setLoading]=useState(true)
  useEffect(()=>{
    setLoading(true)
    if(id){
      setLoading(true)
      axios.get(`https://thecompgenie.com/api/properties/prop/${id}/`,{
        headers:{
          Authorization:"Bearer "+window.localStorage.getItem('accessToken')
        }
      }).then(
        res=>{
          // console.log(res)
          setData(res.data)
          setLoading(false)

        })
    }
    setLoading(false)

  },[])

  // useEffect(() => {
  //   // if (comparableInfo?.property_id) {
  //     getProperty();
  //
  //
  //
  //   // }
  //
  // },[id]);

  const dataRows = [
    // {
    //   "address": "Images",
    //   "subject": "",
    //   "comp1": "",
    //   "comp2": "",
    //   "comp3": ""
    // },
    // {
    //   "address": "Address",
    //   "subject": "",
    //   "comp1": "",
    //   "comp2": "",
    //   "comp3": ""
    // },
    {
      "address": "Address",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Proximixy",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Sales Price",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Date Closed",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Data Source (MLS#)",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Value Adjustment",
      "subject": "subject",
      "comp1": "Comp One",
      "comp2": "Comp Two",
      "comp3": "Comp Three",
      "comp4": "+(-) $ Adjustment",
    },
    {
      "address": "Status",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Site size",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "View",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Floor Height",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Actual Age",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Condition",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Bedrooms",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Full Bath(s)",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Half Bath(s)",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Living Area Under Air",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Basement",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Garage/Carport",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Pool, Patio, Porch, Deck",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    {
      "address": "Additional Info",
      "subject": "",
      "comp1": "",
      "comp2": "",
      "comp3": ""
    },
    // {
    //   "address": "Net Adjustment (Total)",
    //   "subject": "",
    //   "comp1": "",
    //   "comp2": "",
    //   "comp3": ""
    // },
    // {
    //   "address": "Adjustment Sale Price of Comparables",
    //   "subject": "Net Adj      %" +
    //     "Gross Adj      %",
    //   "comp1": "Net Adj      % " +
    //     "Gross Adj      %",
    //   "comp2": "Net Adj      % " +
    //     "Gross Adj      %",
    //   "comp3": "Net Adj      % " +
    //     "Gross Adj      %"
    // }
  ];
  let latLngs =[
    {
        lat: data?.location_lat ? data?.location_lat  : '',
        lng:data?.location_lng  ?data?.location_lng : ''
      },
    // {
    //     lat:comparableInfo?.lookup_comp_data===undefined?'': comparableInfo?.lookup_comp_data[1]===undefined ? '':comparableInfo?.lookup_comp_data? comparableInfo?.lookup_comp_data[1]?.COMPARABLE_PROPERTY_ext['@LatitudeNumber'] ?comparableInfo?.lookup_comp_data[1]?.COMPARABLE_PROPERTY_ext['@LatitudeNumber'] :"" :"",
    //     lng:comparableInfo?.lookup_comp_data===undefined?'': comparableInfo?.lookup_comp_data[1]===undefined ? '':comparableInfo?.lookup_comp_data? comparableInfo?.lookup_comp_data[1]?.COMPARABLE_PROPERTY_ext['@LongitudeNumber'] ? comparableInfo?.lookup_comp_data[1]?.COMPARABLE_PROPERTY_ext['@LongitudeNumber']:"" :""
    //   },
    // {
    //     lat:comparableInfo?.lookup_comp_data===undefined?'': comparableInfo?.lookup_comp_data[2]===undefined ? '':comparableInfo?.lookup_comp_data? comparableInfo?.lookup_comp_data[2]?.COMPARABLE_PROPERTY_ext['@LatitudeNumber'] ?comparableInfo?.lookup_comp_data[2]?.COMPARABLE_PROPERTY_ext['@LatitudeNumber'] :"" :"",
    //     lng:comparableInfo?.lookup_comp_data===undefined?'': comparableInfo?.lookup_comp_data[2]===undefined ? '':comparableInfo?.lookup_comp_data? comparableInfo?.lookup_comp_data[2]?.COMPARABLE_PROPERTY_ext['@LongitudeNumber']?comparableInfo?.lookup_comp_data[2]?.COMPARABLE_PROPERTY_ext['@LongitudeNumber']:"" :""
    //   },
    // {
    //     lat:comparableInfo?.lookup_comp_data===undefined?'': comparableInfo?.lookup_comp_data[3]===undefined ? '':comparableInfo?.lookup_comp_data? comparableInfo?.lookup_comp_data[3]?.COMPARABLE_PROPERTY_ext['@LatitudeNumber'] ?comparableInfo?.lookup_comp_data[3]?.COMPARABLE_PROPERTY_ext['@LatitudeNumber'] :"" :"",
    //     lng:comparableInfo?.lookup_comp_data===undefined?'': comparableInfo?.lookup_comp_data[3]===undefined ? '':comparableInfo?.lookup_comp_data? comparableInfo?.lookup_comp_data[3]?.COMPARABLE_PROPERTY_ext['@LongitudeNumber']?comparableInfo?.lookup_comp_data[3]?.COMPARABLE_PROPERTY_ext['@LongitudeNumber']:"" :""
    //   }

    {
      lat:  data?.comparable_info?  data?.comparable_info[0]?.location_lat ?  data?.comparable_info[0]?.location_lat  : '' : '',
      lng:data?.comparable_info?  data?.comparable_info[0]?.location_lng  ? data?.comparable_info[0]?.location_lng : '' : '',
    },
    {
      lat: data?.comparable_info?  data?.comparable_info[1]?.location_lat ?  data?.comparable_info[1]?.location_lat : '' : '',
      lng:data?.comparable_info?  data?.comparable_info[1]?.location_lng  ? data?.comparable_info[1]?.location_lng : '' : '',
    },
    {
      lat: data?.comparable_info?  data?.comparable_info[2]?.location_lat ?  data?.comparable_info[2]?.location_lat  : '' : '',
      lng:data?.comparable_info?  data?.comparable_info[2]?.location_lng  ? data?.comparable_info[2]?.location_lng : '' : '',
    }
  ]

  // const a = getDistance(
  //    { latitude: latLngs[0].lat, longitude: latLngs[0].lng },
  //   { latitude: latLngs[1].lat, longitude:latLngs[1].lng }
  // );

  // let latLngs =[
  //   {
  //     lat: 29.79835,
  //     lng: -95.418603,
  //   },
  //   {
  //     lat: 29.79835,
  //     lng: -95.418603,
  //   },
  //   {
  //     lat: 29.79835,
  //     lng: -95.418603,
  //   },
  //   {
  //     lat: 29.79835,
  //     lng: -95.418603,
  //   }
  // ]
  // console.log(latLngs ,comparableInfo, 'latlngs')
  function titleCase(str) {
    let splitStr = str?.toLowerCase()?.split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  const name = {
    subject:   data?.address
    ,
    comp1:data.length!=0?  data?.comparable_info[0]===undefined? undefined:  titleCase(data?.comparable_info[0]?.address) :''
    ,
    comp2: data.length!=0?   data?.comparable_info[1]===undefined? undefined:   titleCase(data?.comparable_info[1]?.address):''+ '*JR'
    ,
    comp3: data.length!=0?  data?.comparable_info[2]===undefined? undefined:  titleCase(data?.comparable_info[2]?.address):''+ '*JR'

  };
  // console.log(data , 'dataaa', data.length===0)
  const proximities = {
    subject:   ''
    ,
    comp1:data && data.comparable_info!= undefined ?   data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.proximity?.toFixed(3):""
    ,
    comp2: data && data.comparable_info!= undefined ?    data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.proximity?.toFixed(3):""
    ,
    comp3: data && data.comparable_info!= undefined ?   data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.proximity?.toFixed(3):""
    //

  };
  const zipCodes = {
    subject:    data?.zipcode
    ,
    comp1:data && data.comparable_info!= undefined ?   data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.zipcode:""
    ,
    comp2: data && data.comparable_info!= undefined ?    data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.zipcode:""
    ,
    comp3: data && data.comparable_info!= undefined ?   data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.zipcode:""
    //

  };
  const cities = {
    subject:   data?.city
    ,
    comp1:data && data.comparable_info!= undefined ?   data?.comparable_info[0]===undefined? '':  titleCase(data?.comparable_info[0]?.city):""
    ,
    comp2: data && data.comparable_info!= undefined ?    data?.comparable_info[1]===undefined? '':  titleCase(data?.comparable_info[1]?.city):""
    ,
    comp3: data && data.comparable_info!= undefined ?   data?.comparable_info[2]===undefined? '':  titleCase(data?.comparable_info[2]?.city):""
    //

  };
  const states = {
    subject:data && data.comparable_info!= undefined ?  titleCase(data?.state) :''
    ,
    comp1:data && data.comparable_info!= undefined ?   data?.comparable_info[0]===undefined? '':  titleCase(data?.comparable_info[0]?.state):""
    ,
    comp2: data && data.comparable_info!= undefined ?    data?.comparable_info[1]===undefined? '':  titleCase(data?.comparable_info[1]?.state):""
    ,
    comp3: data && data.comparable_info!= undefined ?   data?.comparable_info[2]===undefined? '':  titleCase(data?.comparable_info[2]?.state):""
    //

  };


  if(data && data.comparable_info!= undefined){
    // console.log(comparableInfo?.data?.subject?.street);
    dataRows[0].subject = titleCase(data?.address) + '*JR' +titleCase(`${data?.city+', '+ data?.state+' '+data?.zipcode}`)
    dataRows[0].comp1 =data?.comparable_info[0]===undefined || data?.comparable_info[0]?.address===''? '':  titleCase(data?.comparable_info[0]?.address)+ '*JR' +titleCase(`${data?.comparable_info[0]?.city+', '+ data?.comparable_info[0]?.state+' '+data?.comparable_info[0]?.zipcode}`)
    dataRows[0].comp2 =data?.comparable_info[1]===undefined || data?.comparable_info[1]?.address===''? '':  titleCase(data?.comparable_info[1]?.address)+ '*JR' +titleCase(`${data?.comparable_info[1]?.city+', '+ data?.comparable_info[1]?.state+' '+data?.comparable_info[1]?.zipcode}`)
    dataRows[0].comp3 =data?.comparable_info[2]===undefined || data?.comparable_info[2]?.address===''? '': titleCase( data?.comparable_info[2]?.address)+ '*JR' +titleCase(`${data?.comparable_info[2]?.city+', '+ data?.comparable_info[2]?.state+' '+data?.comparable_info[2]?.zipcode}`)

    dataRows[1].subject = ''
    // dataRows[1].comp1 = name.comp1===undefined?'':comparableInfo?.lookup_comp_data===undefined?'': comparableInfo?.lookup_comp_data[1]===undefined ? '': comparableInfo?.lookup_comp_data[1]['COMPARABLE_PROPERTY_ext']['@DistanceFromSubjectPropertyMilesCount'] ?  parseFloat(comparableInfo?.lookup_comp_data[1]['COMPARABLE_PROPERTY_ext']['@DistanceFromSubjectPropertyMilesCount']).toFixed(3):''
    // dataRows[1].comp2 = name.comp2===undefined?'':comparableInfo?.lookup_comp_data===undefined?'':comparableInfo?.lookup_comp_data[2]===undefined ? '': comparableInfo?.lookup_comp_data[2]['COMPARABLE_PROPERTY_ext']['@DistanceFromSubjectPropertyMilesCount'] ?  parseFloat(comparableInfo?.lookup_comp_data[2]['COMPARABLE_PROPERTY_ext']['@DistanceFromSubjectPropertyMilesCount']).toFixed(3):""
    // dataRows[1].comp3 = name.comp3===undefined?'':comparableInfo?.lookup_comp_data===undefined?'':comparableInfo?.lookup_comp_data[3]===undefined ? '': comparableInfo?.lookup_comp_data[3]['COMPARABLE_PROPERTY_ext']['@DistanceFromSubjectPropertyMilesCount'] ?  parseFloat(comparableInfo?.lookup_comp_data[3]['COMPARABLE_PROPERTY_ext']['@DistanceFromSubjectPropertyMilesCount']).toFixed(3):""
    dataRows[1].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.proximity?.toFixed(3)+' Mi'
    dataRows[1].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.proximity?.toFixed(3)+' Mi'
    dataRows[1].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.proximity?.toFixed(3)+' Mi'

    // console.log("------------------------------");
    dataRows[2].subject = '$ '+data?.more_detail[0]?.sales_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    dataRows[2].comp1 =data?.comparable_info[0]===undefined? '':  '$ '+data?.comparable_info[0]?.sales_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    dataRows[2].comp2 =data?.comparable_info[1]===undefined? '':  '$ '+data?.comparable_info[1]?.sales_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    dataRows[2].comp3 =data?.comparable_info[2]===undefined? '':  '$ '+data?.comparable_info[2]?.sales_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    dataRows[3].subject =data?.more_detail[0]?.date_closed===undefined ||data?.more_detail[0]?.date_closed===null?"": data?.more_detail[0]?.date_closed.split('-')[0] ===undefined?'':data?.more_detail[0]?.date_closed.split('-')[1]+'/'+data?.more_detail[0]?.date_closed.split('-')[2]+'/'+data?.more_detail[0]?.date_closed.split('-')[0]
    dataRows[3].comp1 =data?.comparable_info[0]===undefined || data?.comparable_info[0]===null ? '': data?.comparable_info[0]?.date_closed?.split('-')[0] ===undefined ?'': data?.comparable_info[0]?.date_closed?.split('-')[1]+'/'+data?.comparable_info[0]?.date_closed?.split('-')[2]+'/'+data?.comparable_info[0]?.date_closed?.split('-')[0]
    dataRows[3].comp2 =data?.comparable_info[1]===undefined || data?.comparable_info[1]===null ? '':  data?.comparable_info[1]?.date_closed?.split('-')[0] ===undefined ?'': data?.comparable_info[1]?.date_closed?.split('-')[1]+'/'+data?.comparable_info[1]?.date_closed?.split('-')[2]+'/'+data?.comparable_info[1]?.date_closed?.split('-')[0]
    dataRows[3].comp3 =data?.comparable_info[2]===undefined || data?.comparable_info[2]===null ?  '':  data?.comparable_info[2]?.date_closed?.split('-')[0] ===undefined ?'': data?.comparable_info[2]?.date_closed?.split('-')[1]+'/'+data?.comparable_info[2]?.date_closed?.split('-')[2]+'/'+data?.comparable_info[2]?.date_closed?.split('-')[0]

    dataRows[4].subject = data?.more_detail[0]?.data_source
    dataRows[4].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.data_source
    dataRows[4].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.data_source
    dataRows[4].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.data_source



    dataRows[6].subject = data?.more_detail[0]?.status
    dataRows[6].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.status
    dataRows[6].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.status
    dataRows[6].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.status

    dataRows[7].subject = data?.more_detail[0]?.site_size?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.';
    dataRows[7].comp1 =data?.comparable_info[0]===undefined? '':   data?.comparable_info[0]?.site_size?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.';
    dataRows[7].comp2 =data?.comparable_info[1]===undefined? '':   data?.comparable_info[1]?.site_size?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.';
    dataRows[7].comp3 = data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.site_size?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.';

    dataRows[8].subject = data?.more_detail[0]?.view
    dataRows[8].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.view
    dataRows[8].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.view
    dataRows[8].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.view

    dataRows[9].subject = data?.more_detail[0]?.floor_height
    dataRows[9].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.floor_height
    dataRows[9].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.floor_height
    dataRows[9].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.floor_height

    dataRows[10].subject = data?.more_detail[0]?.actual_age?.toString()+' yrs'
    dataRows[10].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.actual_age?.toString()+' yrs'
    dataRows[10].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.actual_age?.toString()+' yrs'
    dataRows[10].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.actual_age?.toString()+' yrs'


    dataRows[11].subject = data?.more_detail[0]?.condition
    dataRows[11].comp1 =data?.comparable_info[0]===undefined? '':   data?.comparable_info[0]?.condition
    dataRows[11].comp2 = data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.condition
    dataRows[11].comp3 =data?.comparable_info[2]===undefined? '':   data?.comparable_info[2]?.condition


    dataRows[12].subject = data?.bedrooms
    dataRows[12].comp1 =data?.comparable_info[0]===undefined? '':   data?.comparable_info[0]?.bedrooms
    dataRows[12].comp2 = data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.bedrooms
    dataRows[12].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.bedrooms


    dataRows[13].subject =  data?.bathrooms
    dataRows[13].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.bathrooms
    dataRows[13].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.bathrooms
    dataRows[13].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.bathrooms


    dataRows[14].subject = data?.half_baths
    dataRows[14].comp1 = data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.half_baths
    dataRows[14].comp2 = data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.half_baths
    dataRows[14].comp3 = data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.half_baths


    dataRows[15].subject = data?.more_detail[0]?.living_area_under_air?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.'
    dataRows[15].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.living_area_under_air?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.'
    dataRows[15].comp2 = data?.comparable_info[1]===undefined? '': data?.comparable_info[1]?.living_area_under_air?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.'
    dataRows[15].comp3 =data?.comparable_info[2]===undefined? '': data?.comparable_info[2]?.living_area_under_air?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+' sq. ft.'

    dataRows[16].subject = data?.more_detail[0]?.basement
    dataRows[16].comp1 =data?.comparable_info[0]===undefined? '': data?.comparable_info[0]?.basement
    dataRows[16].comp2 =data?.comparable_info[1]===undefined? '':  data?.comparable_info[1]?.basement
    dataRows[16].comp3 =data?.comparable_info[2]===undefined? '': data?.comparable_info[2]?.basement

    dataRows[17].subject = data?.more_detail[0]?.garage_carport
    dataRows[17].comp1 =data?.comparable_info[0]===undefined? '':  data?.comparable_info[0]?.garage_carport
    dataRows[17].comp2 = data?.comparable_info[1]===undefined? '': data?.comparable_info[1]?.garage_carport
    dataRows[17].comp3 =data?.comparable_info[2]===undefined? '':  data?.comparable_info[2]?.garage_carport

    dataRows[18].subject = data?.more_detail[0]?.pool_deck_porch_patio
    dataRows[18].comp1 = data?.comparable_info[0]===undefined? '': data?.comparable_info[0]?.pool_deck_porch_patio
    dataRows[18].comp2 = data?.comparable_info[1]===undefined? '': data?.comparable_info[1]?.pool_deck_porch_patio
    dataRows[18].comp3 = data?.comparable_info[2]===undefined? '': data?.comparable_info[2]?.pool_deck_porch_patio

    dataRows[19].subject = data?.more_detail[0]?.additional_info
    dataRows[19].comp1 =data?.comparable_info[0]===undefined? '': data?.comparable_info[0]?.additional_info
    dataRows[19].comp2 = data?.comparable_info[1]===undefined? '': data?.comparable_info[1]?.additional_info
    dataRows[19].comp3 = data?.comparable_info[2]===undefined? '': data?.comparable_info[2]?.additional_info

  }

  let remodeling_or_updates = [
    {unit: 'Kitchen' ,time_frame :data?.kitchen_timeframe , estimated_cost:data?.kitchen_estimated_cost},
    {unit: 'Bath' ,time_frame :data?.bath_timeframe , estimated_cost:data?.bath_estimated_cost},
    {unit: 'Roof Covering' ,time_frame :data?.roof_covering_timeframe , estimated_cost:data?.roof_covering_estimated_cost},
    {unit: 'A/C' ,time_frame :data?.AC_timeframe , estimated_cost:data?.AC_estimated_cost},
    {unit: 'Floor Covering' ,time_frame :data?.floor_covering_timeframe , estimated_cost:data?.floor_covering_estimated_cost},
    {unit: 'Windows' ,time_frame :data?.windows_timeframe , estimated_cost:data?.windows_estimated_cost},
  ]
  const propertyData = '';
  const pdfRef = useRef();
  const classes = useStyles();

  const Avatar = styled.img`
  display: inline-block;
  border-radius: 5px;
  height: 50px;
  width: 250px;  
  max-height: 100%;
  max-width: 100%;
`;

  const getPageMargins = () => {
    return `@page { margin:0 0 0 0 !important }`;
  };
  const handlePrint = useReactToPrint({
    pageStyle:`
    @page {
      size: 297mm 210mm;
      margin: 0 0 0 0 !important; 
      }
    ` ,
    content: () => pdfRef.current
  });

  // const handlePrint = () => {
  //   let printableElements = document.getElementById('printme').innerHTML;
  //   let orderHtml = '<html><head><title></title></head><body>' + printableElements + '</body></html>'
  //   let oldPage = document.body.innerHTML;
  //   document.body.innerHTML = orderHtml;
  //   window.print();
  //   document.body.innerHTML = oldPage
  // }
  // console.log(comparableInfo?.data?.subject?.dateClose , 'comssss')
  const [user , setUser]=useState()
  useEffect(()=>{
    const accessToken = window.localStorage.getItem("accessToken");
    axios.get("https://thecompgenie.com/api/users/profile/" , {
      headers:{
        Authorization:"Bearer "+ accessToken
      }
    }).then((res)=>{
      setCompName(res?.data?.company_name===''|| res?.data?.company_name===undefined || res?.data?.company_name===null?'The Comp Genie': res?.data?.company_name)
      setUser(res.data)
    })
  },[])
  // console.log('user?.logo_image' ,user?.logo_image)
  const [mapImage , setMapimage]=useState(null)
  const [tableImg , setTableimage]=useState(null)
  const [propertyImg ,setPropertyImg]=useState()
  const imageRef =useRef()
  const tableRef =useRef()
  const propertyRef =useRef()

  const [TableShotImage, takeScreenshot] = useScreenshot()
  const[scale,setScale]=useState(1)
  const handleDownloadImage = async () => {
    const accessToken = window.localStorage.getItem("accessToken");

    if(compName===''||compName===null|| compName===undefined){

    }else {
      axios.post("https://thecompgenie.com/api/users/profile/company-name/",{company_name:compName} ,{ headers:{
          Authorization:"Bearer "+ accessToken
        }}).then((res)=>  null).catch((err)=> null)
    }



    const element = imageRef.current;
    const table = tableRef.current;
    const property =propertyRef.current
    takeScreenshot(tableRef.current)
    const canvas = await html2canvas(element ,{
      scale: 3,
      useCORS:true
    });
    const canvas2 = await html2canvas(table ,{
      scale: 3,
      useCORS:true
    });

    const canvas3 = await html2canvas(property ,{
      scale: 3,
      useCORS:true
    });
    const data = canvas.toDataURL('image/jpg');
    const data2 = canvas2.toDataURL('image/jpg');
    const data3 = canvas3.toDataURL('image/jpg');

    const link = document.createElement('a');
    setMapimage(data)
    setTableimage(data2)
    setPropertyImg(data3)
    setSubmiting(submiting+1)
    // if (typeof link.download === 'string') {
    //   link.href = data;
    //   link.download = 'image.jpg';
    //
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // } else {
    //   window.open(data);
    // }
  };

  useEffect(()=>{
    if(mapImage===null){

    }else {
      handlePrint()
    }
  },[mapImage])
  useEffect(()=>{
    if(mapImage===null){

    }else {
      handlePrint()
    }
  },[submiting])

  const [generator,setGenerator]=useState(false)
  const img = new Image();
  // img.crossorigin=true
  img.src = user?.logo_image ==='' || user?.logo_image ===null || user?.logo_image === undefined ? imageLogo : user?.logo_image?.replace('http','https')

  const cnavasMe = document.createElement('canvas')
  const ctx = cnavasMe.getContext('2d');
  let canImg = new Image;
  canImg.onload = function(){
    ctx.drawImage(img,0,0); // Or at whatever offset you like
  };
  canImg.src = user?.logo_image ==='' || user?.logo_image ===null || user?.logo_image === undefined ? imageLogo : user?.logo_image?.replace('http','https');
  const generatePDF = async () => {

    const logoWidth= canImg.width /canImg.height * 10
    const logoHeight= 10
    setGenerator(true)
    const element = imageRef.current;
    const table = tableRef.current;
    const property =propertyRef.current;

    const canvas = await html2canvas(element ,{
      backgroundColor: '#F7F9FC',
      logging:true,
      letterRendering:1,
      scale: 1.5,
      useCORS:true
    });
    const canvas2 = await html2canvas(table ,{
      backgroundColor: '#F7F9FC',
      logging:true,
      letterRendering:1,
      scale: 1.5,
      useCORS:true
    });

    const canvas3 = await html2canvas(property ,{
      backgroundColor: '#F7F9FC',
      logging:true,
      letterRendering:1,
      scale: 1.5,
      useCORS:true
    });

    const data = canvas.toDataURL('image/png');
    const data2 = canvas2.toDataURL('image/PNG');
    const data3 = canvas3.toDataURL('image/PNG');
    // const imgtag = document.getElementById('logo')

    const pdf = new jsPDF('p','mm','a4')
    // pdf.text('asd',1,1)
    pdf.addImage(data3 ,'PNG', 5,10, 200, 270)
    pdf.addImage(canImg,'jpeg',5,14,logoWidth,logoHeight)
    // pdf.html(imgtag,5,14,logoWidth,logoHeight)
    pdf.addPage('a4',"portrait")
    pdf.setPage(2)
    pdf.addImage(data2 ,'PNG', 5,10, 200, 280)
    pdf.addPage('a4',"portrait")
    pdf.setPage(3)
    pdf.addImage(data, 'PNG', 5,10, 200, 280)

    // pdf.addPage('a4',"portrait")

    pdf.save('compgenie.pdf')
    setGenerator(false)

    // setGenerator(true)
    // const report = new jsPDF('portrait','pt','a4');
    // report.html(document.querySelector('#printme2')).then(() => {
    //   report.save('report.pdf');
    //   setGenerator(false)
    }

    const [cover ,setCover]=useState(false)

  return (loading? <Loader/> :
    <div className='preview-pdf-container'>
      <div style={{width:"96.5%",marginLeft:"1.5%",borderRadius:"0.5rem" , backgroundColor:"#ffffff" , display:"flex",flexDirection:'row' , alignItems:'center',justifyContent:"flex-start" , padding:"0.4rem 1rem"}}>
        <div>
          Enter Your Company Name
        </div>
        <TextField
          defaultValue={compName}
          value={compName}
          onChange={(e)=>setCompName(e.target.value)}
          sx={{
            backgroundColor:"#ffffff",
            marginLeft:"2rem"
          }}

        />

      </div>

      {/*<input type={'number'} value={scale} onChange={(e)=>setScale(e.target.value)}/>*/}
      <div className={classes.print} id={'printme'}>





      <div ref={propertyRef} style={{width:generator?"80%":"100%"}}>
        <Grid container direction={"row"}   alignItems="center"
              sx={{ borderTopRightRadius: 15,borderTopLeftRadius: 15, backgroundColor: "white" }} spacing={1}>
          <Grid item xs={3} md={3} lg={3}>
            {/*<Avatar alt="Profile image" src={user?.logo_image ===''? imageLogo : user?.logo_image} />*/}
            <img  alt="Logo" id={'logo'} style={{display:generator?"none":"", height:"3rem" , width:"10rem" , borderRadius:"0.3rem" , objectFit:"contain"}}  src={user?.logo_image ==='' || user?.logo_image ===null || user?.logo_image === undefined? imageLogo : user?.logo_image?.replace('http','https')}  />
          </Grid>
          <Divider orientation="vertical" variant={"middle"} sx={{ borderColor: blue[800], borderWidth: 1, height: "50px"}} />

          <Grid item xs={3} md={3} lg={3}>
            <Typography variant="h1" sx={{ fontFamily: "Bookman, URW Bookman L, serif",fontSize: "16px" ,color: "black" , fontWeight:400 }}>
              {compName}
            </Typography>
            {/*<Typography variant="h1" sx={{ fontFamily: "Monospace",fontSize: "18px" ,color: "black" }}>*/}
            {/*</Typography>*/}
          </Grid>
          <Grid item marginLeft={"10px"} xs={4} md={3} lg={4}>
            <Typography variant="h1" sx={{ textAlign: "right",fontWeight: 560,fontFamily: "Monospace",fontSize: "16px" ,color: "black" }}>
              Comparative Market Analysis
            </Typography>
          </Grid>
        </Grid>
          <Divider variant={"middle"} sx={{ marginTop: 2,borderColor: "black", height: "auto"}} />

        {/*<Grid container direction={"column"} sx={{ backgroundColor: "white" }} spacing={1}>*/}

        {/*  <Divider variant={"middle"} sx={{ marginTop: 2,borderColor: "black", height: "auto"}} />*/}
        {/*  <Grid container direction={"row"}  spacing={1}>*/}

        {/*  <Grid item xs={12} md={12} lg={12}>*/}
        {/*    <Typography variant="h6" sx={{ marginLeft:2, fontFamily: "Times New Roman",fontSize: "16px" ,color: "black" }}>*/}
        {/*      Telephone: +1 999 9999 999*/}
        {/*    </Typography>*/}

        {/*  </Grid>*/}
        {/*    <Grid item xs={12} md={12} lg={12}>*/}
        {/*    <Typography variant="h6" sx={{ marginLeft:2, fontFamily: "Times New Roman",fontSize: "16px" ,color: "black" }}>*/}
        {/*      www.thecompgenie.com*/}
        {/*    </Typography>*/}

        {/*  </Grid>*/}
        {/*  </Grid>*/}
        {/*  <Divider variant={"middle"} sx={{ borderColor: "black", height: "auto"}} />*/}

        {/*</Grid>*/}


        <Grid container sx={{ padding: 2, backgroundColor: "white" }}  spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                  width: '100%'
                }}
              >
                <Typography classes={{fontSize:0.5}}  sx={{fontSize:"1.1rem" ,fontWeight:"600",width:"45%"}} variant="h6">Subject Address</Typography>
                <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600" , width:"17%",pl:"1rem" , textAlign:"left"}}>City</Typography>
                <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600",width:"17%" , textAlign:"center"}}>State</Typography>
                <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600",width:"17%" , textAlign:"right"}}>Zip</Typography>
                {/*<div style={{fontSize:"1.2rem"}}>Subject Address</div>*/}

              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                  width: '100%',
                  backgroundColor: (theme) => theme.palette.action.hover,
                  height: '1.7rem'
                  // padding: '4px'
                }}
              >
                <Typography sx={{width:"45%"}}>{data?.address? titleCase(data?.address) :"" }</Typography>
                <Typography sx={{width:"25%" , textAlign:"left"}}>{ data?.city? titleCase(data?.city) :""}</Typography>
                <Typography sx={{width:"17%" , textAlign:"left"}}>{data?.state? titleCase(data?.state):"" }</Typography>
                <Typography sx={{width:"10%" , textAlign:"right"}}>{data?.zipcode? titleCase(data?.zipcode) :""}</Typography>

              </Stack>
            </Stack>
          </Grid>

          {/*<Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>*/}
          {/*  <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>*/}
          {/*    <Stack*/}
          {/*      direction="row"*/}
          {/*      justifyContent="space-between"*/}
          {/*      alignItems="center"*/}
          {/*      spacing={1}*/}
          {/*      sx={{*/}
          {/*        width: '100%'*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <Typography variant="h6">City</Typography>*/}
          {/*      <Typography variant="h6">State</Typography>*/}
          {/*      <Typography variant="h6">Zip</Typography>*/}
          {/*    </Stack>*/}
          {/*    <Stack*/}
          {/*      direction="row"*/}
          {/*      justifyContent="space-between"*/}
          {/*      alignItems="center"*/}
          {/*      spacing={1}*/}
          {/*      sx={{*/}
          {/*        width: '100%',*/}
          {/*        backgroundColor: (theme) => theme.palette.action.hover,*/}
          {/*        height: '1.7rem'*/}
          {/*        // padding: '4px'*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <Typography>{ data?.city }</Typography>*/}
          {/*      <Typography>{ data?.state }</Typography>*/}
          {/*      <Typography>{ data?.zipcode}</Typography>*/}
          {/*    </Stack>*/}
          {/*  </Stack>*/}
          {/*</Grid>*/}

          <Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                  width: '100%'
                }}
              >
                <Typography variant="h6" sx={{fontSize:"1.1rem" ,fontWeight:"600"}}># Bedroom (s)</Typography>
                <Typography variant="h6" sx={{fontSize:"1.1rem" ,fontWeight:"600"}}># Bathroom (s)</Typography>
                <Typography variant="h6" sx={{fontSize:"1.1rem" ,fontWeight:"600"}}># 1/2 Bath (s)</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                  width: '100%',
                  backgroundColor: (theme) => theme.palette.action.hover,
                  height: '1.7rem'
                  // padding: '4px'
                }}
              >
                <Typography>{data?.bedrooms}</Typography>
                <Typography>{data?.bathrooms}</Typography>
                <Typography>{data?.half_baths}</Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                  width: '100%'
                }}
              >
                <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Unique Neighborhood Characteristics</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{
                  width: '100%',
                  backgroundColor: (theme) => theme.palette.action.hover,
                  height: '6rem',
                  pl: '0.4rem'
                }}
              >
                <Typography
                  display="inline-block"
                  sx={{ wordWrap: 'break-word', maxWidth: '90%', height: '100%' ,fontSize:"0.8rem !important"
                  }}
                >
                  {data?.unique_neighborhood_characteristics?.split('\n')?.map((item,index)=>{
                    return(
                      <div>
                        {item}
                      </div>
                    )
                  })}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, backgroundColor: 'text.primary', height: 1.5 }} />

        <Grid container sx={{ pl: 1,pr:1, backgroundColor: "white" }} spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Stack
              sx={{
                height: '2rem',
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600", width:"13%" ,textAlign:"left"}}>Remodeling/Updates</Typography>
              <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600", width:"33%",textAlign:"center" }}>Time Frame</Typography>
              <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600" , width:"20%",textAlign:"right" , mr:"1rem !important"}}>Estimated Cost</Typography>
            </Stack>
          </Grid>

          {remodeling_or_updates.map((row)=>{
            // setSumCost(sumCost + row.estimated_cost)
            return (

              <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  width: '100%',
                  backgroundColor: (theme) => theme.palette.action.hover,
                  height: '1.7rem'
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Typography sx={{ minWidth: '182px', width:"13%" ,textAlign:"left" }}>{row.unit}</Typography>
                <Typography sx={{ minWidth: '100px', width:"33%",textAlign:"left",pl:generator?'2rem':"3.8rem"  }}>{row.time_frame}</Typography>
                <Typography sx={{ minWidth: '132px', width:"8%",textAlign:"left" ,pr:"0.2rem" }}>{row.estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')===undefined?'': '$ '+row.estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
              </Stack>
            </Grid>
            )

          })}

          <Grid item xs={12} md={12} lg={12}>
            <Stack
              sx={{
                width: '100%',
                backgroundColor: 'white',
                height: '1.7rem'
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography sx={{ minWidth: '182px', width:"40%" ,textAlign:"left",fontSize:"1.1rem" ,fontWeight:"600" }}>Total Estimated Cost</Typography>
              <Typography sx={{ minWidth: '100px', width:"3%",textAlign:"center",pr:"3rem"  }}>{''}</Typography>
              <Typography sx={{ minWidth: '132px', width:"8%",textAlign:"left" ,pr:"0.2rem" }}>{data?.total_estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')===undefined?'':
               '$ '+ data?.total_estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
            </Stack>
          </Grid>
        </Grid>

        {/*<Divider sx={{ my: 1, backgroundColor: 'text.primary', height: 1.5 }} />*/}


        {/*<Stack sx={{ padding: 1, backgroundColor: "white"  , width: '100.8%',transform:"translate(-0.5rem,0)"}}  direction="row" justifyContent="space-between" alignItems="center" spacing={1}>*/}
        {/*  <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Total Estimated Cost</Typography>*/}
        {/*  <Typography sx={{ width: '12%' ,textAlign:"left" ,pr:"1rem" }}>*/}
        {/*    {data?.total_estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
        {/*  </Typography>*/}
        {/*</Stack>*/}
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ my: 1,padding: 2, backgroundColor: "white"  , width: '100.8%',transform:"translate(-0.5rem,0)"}}
          spacing={1}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{
              width: '100%'
            }}
          >
            <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Important Features</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{
              width: '100%',
              backgroundColor: (theme) => theme.palette.action.hover,
              height: '6rem',
              pl:"0.4rem"
            }}
          >
            <Typography
              display="inline-block"
              sx={{
                wordWrap: 'break-word',
                maxWidth: '90%',
                height: '100%',
                overflow:"hidden",
                fontSize:"0.8rem !important"

              }}
            >
              {data?.important_features?.split('\n')?.map((item,index)=>{
                return(
                  <div>
                    {item}
                  </div>
                )
              })}
            </Typography>
          </Stack>
        </Stack>
      </div>

        <div className={classes.pagebreak} />

         <Divider sx={{ my: 1, backgroundColor: '#000000', height: 2 }} />
        <div ref={tableRef}>
          <TablePrint rows={dataRows} />
          {/*<MyTable rows={dataRows} />*/}
        </div>

         <Divider sx={{ my: 2, backgroundColor: '#000000', height: 2 }} />
        <div className={classes.pagebreak} />
        {/*<div className="page-break" />*/}

        <div sx={{ my: 4 }} />
        <div ref={imageRef} style={{width:"100%",height:"96rem"}}>
          {
            latLngs[0]?.lng===''? null :        <MapComponent states={states} zipCodes={zipCodes} cities={cities}
                                                              name={name} proximixy={dataRows[3]} latLngs={latLngs} proximities={proximities} />

          }
        </div>

        {/*<img src={mapImage} style={{width:"50%" , height:"80rem" , objectFit:"cover"}}/>*/}
         {/*<GoogleMaps /> */}
        {/*<SimpleMap/>*/}
        {/*<MarkerInfoWindow/>*/}
        {/*<MarkerInfoWindowGmapsObj/>*/}

      </div>



      <Divider sx={{ my: 5, height: 1.5 }} />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <Button component={RouterLink} to="/pages/dashboard/">
          Cancel
        </Button>
        <Button variant="contained" component={RouterLink} to={id? `/pages/comparables/${id}`:"/pages/comparables"}>
          Previous
        </Button>
        <Button variant="contained"
                // onClick={handlePrint}
          onClick={handleDownloadImage}
        >
          Export to PDf
        </Button>
        {/*<Button variant="contained"*/}
        {/*        onClick={generatePDF}*/}
        {/*>*/}
        {/*  Download PDf*/}
        {/*</Button>*/}
      </Stack>
      {/*<input type={'checkbox'} value={cover} onChange={()=>setCover(!cover)}/> cover*/}
























    <div
      style={{display:generator?'none':"none"}}
      id={'printme2'}
    >


      <div className={classes.print} ref={pdfRef} id={'printme'} style={browserName==='Firefox'?{marginLeft:"0rem",margin:"auto"}:{margin:"auto"}}>


        {
          browserName==='Firefox'?
            <>
              <img  alt="Logo" style={{height:"3rem" , width:"10rem" , borderRadius:"0.3rem" , objectFit:"contain",position:"absolute",transform:"translate(0,0.5rem)",zIndex:10

              }}  src={user?.logo_image ==='' || user?.logo_image ===null || user?.logo_image === undefined? imageLogo : user?.logo_image?.replace('http','https')}  />
              <img src={propertyImg} style={{width:"100%" , height:"62rem" , objectFit:'fill'}}/>

            </>

            :

            <>
              <Grid container direction={"row"}   alignItems="center"
                    sx={{ borderTopRightRadius: 15,borderTopLeftRadius: 15, backgroundColor: "white" }} spacing={1}>
                <Grid item xs={3} md={3} lg={3}>
                  {/*<Avatar alt="Profile image" src={user?.logo_image ===''? imageLogo : user?.logo_image} />*/}
                  <img  alt="Logo" style={{height:"3rem" , width:"10rem" , borderRadius:"0.3rem" , objectFit:"contain"}}  src={user?.logo_image ==='' || user?.logo_image ===null || user?.logo_image === undefined? imageLogo : user?.logo_image?.replace('http','https')}  />
                </Grid>
                <Divider orientation="vertical" variant={"middle"} sx={{ borderColor: blue[800], borderWidth: 1, height: "50px"}} />

                <Grid item xs={3} md={3} lg={3}>
                  <Typography variant="h1" sx={{ fontFamily: "Bookman, URW Bookman L, serif",fontSize: "16px" ,color: "black" , fontWeight:400}}>
                    {compName}
                  </Typography>
                  {/*<Typography variant="h1" sx={{ fontFamily: "Monospace",fontSize: "18px" ,color: "black" }}>*/}
                  {/*</Typography>*/}
                </Grid>
                <Grid item marginLeft={"10px"} xs={4} md={3} lg={4}>
                  <Typography variant="h1" sx={{ textAlign: "right",fontWeight: 560,fontFamily: "Monospace",fontSize: "16px" ,color: "black" }}>
                    Comparative Market Analysis
                  </Typography>
                </Grid>
              </Grid>
              <Divider variant={"middle"} sx={{ marginTop: 2,borderColor: "black", height: "auto" , opacity:0}} />

              {/*<Grid container direction={"column"} sx={{ backgroundColor: "white" }} spacing={1}>*/}

              {/*  <Divider variant={"middle"} sx={{ marginTop: 2,borderColor: "black", height: "auto"}} />*/}
              {/*  <Grid container direction={"row"}  spacing={1}>*/}

              {/*  <Grid item xs={12} md={12} lg={12}>*/}
              {/*    <Typography variant="h6" sx={{ marginLeft:2, fontFamily: "Times New Roman",fontSize: "16px" ,color: "black" }}>*/}
              {/*      Telephone: +1 999 9999 999*/}
              {/*    </Typography>*/}

              {/*  </Grid>*/}
              {/*    <Grid item xs={12} md={12} lg={12}>*/}
              {/*    <Typography variant="h6" sx={{ marginLeft:2, fontFamily: "Times New Roman",fontSize: "16px" ,color: "black" }}>*/}
              {/*      www.thecompgenie.com*/}
              {/*    </Typography>*/}

              {/*  </Grid>*/}
              {/*  </Grid>*/}
              {/*  <Divider variant={"middle"} sx={{ borderColor: "black", height: "auto"}} />*/}

              {/*</Grid>*/}


              <Grid container sx={{ padding: 2, backgroundColor: "white" }}  spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        width: '100%'
                      }}
                    >
                      <Typography classes={{fontSize:0.5}}  sx={{fontSize:"1.1rem" ,fontWeight:"600",width:"45%"}} variant="h6">Subject Address</Typography>
                      <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600" , width:"17%",pl:"1rem" , textAlign:"left"}}>City</Typography>
                      <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600",width:"17%" , textAlign:"center"}}>State</Typography>
                      <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600",width:"17%" , textAlign:"right"}}>Zip</Typography>
                      {/*<div style={{fontSize:"1.2rem"}}>Subject Address</div>*/}

                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        width: '100%',
                        backgroundColor: (theme) => theme.palette.action.hover,
                        height: '1.7rem'
                        // padding: '4px'
                      }}
                    >
                      <Typography sx={{width:"45%"}}>{data?.address? titleCase(data?.address) :"" }</Typography>
                      <Typography sx={{width:"25%" , textAlign:"left"}}>{ data?.city? titleCase(data?.city) :""}</Typography>
                      <Typography sx={{width:"12%" , textAlign:"left"}}>{data?.state? titleCase(data?.state):"" }</Typography>
                      <Typography sx={{width:"17%" , textAlign:"right"}}>{data?.zipcode? titleCase(data?.zipcode) :""}</Typography>

                    </Stack>
                  </Stack>
                </Grid>

                {/*<Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>*/}
                {/*  <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>*/}
                {/*    <Stack*/}
                {/*      direction="row"*/}
                {/*      justifyContent="space-between"*/}
                {/*      alignItems="center"*/}
                {/*      spacing={1}*/}
                {/*      sx={{*/}
                {/*        width: '100%'*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <Typography variant="h6">City</Typography>*/}
                {/*      <Typography variant="h6">State</Typography>*/}
                {/*      <Typography variant="h6">Zip</Typography>*/}
                {/*    </Stack>*/}
                {/*    <Stack*/}
                {/*      direction="row"*/}
                {/*      justifyContent="space-between"*/}
                {/*      alignItems="center"*/}
                {/*      spacing={1}*/}
                {/*      sx={{*/}
                {/*        width: '100%',*/}
                {/*        backgroundColor: (theme) => theme.palette.action.hover,*/}
                {/*        height: '1.7rem'*/}
                {/*        // padding: '4px'*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <Typography>{ data?.city }</Typography>*/}
                {/*      <Typography>{ data?.state }</Typography>*/}
                {/*      <Typography>{ data?.zipcode}</Typography>*/}
                {/*    </Stack>*/}
                {/*  </Stack>*/}
                {/*</Grid>*/}

                <Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>
                  <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        width: '100%'
                      }}
                    >
                      <Typography variant="h6" sx={{fontSize:"1.1rem" ,fontWeight:"600"}}># Bedroom (s)</Typography>
                      <Typography variant="h6" sx={{fontSize:"1.1rem" ,fontWeight:"600"}}># Bathroom (s)</Typography>
                      <Typography variant="h6" sx={{fontSize:"1.1rem" ,fontWeight:"600"}}># 1/2 Bath (s)</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        width: '100%',
                        backgroundColor: (theme) => theme.palette.action.hover,
                        height: '1.7rem'
                        // padding: '4px'
                      }}
                    >
                      <Typography>{data?.bedrooms}</Typography>
                      <Typography>{data?.bathrooms}</Typography>
                      <Typography>{data?.half_baths}</Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        width: '100%'
                      }}
                    >
                      <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Unique Neighborhood Characteristics</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        width: '100%',
                        backgroundColor: (theme) => theme.palette.action.hover,
                        height: '6rem',
                        pl: '0.4rem'
                      }}
                    >
                      <Typography
                        display="inline-block"
                        sx={{ wordWrap: 'break-word', maxWidth: '90%', height: '100%',fontSize:"0.8rem !important"
                        }}
                      >
                        {data?.unique_neighborhood_characteristics?.split('\n')?.map((item,index)=>{
                          return(
                            <div>
                              {item}
                            </div>
                          )
                        })}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2, backgroundColor: 'text.primary', height: 1.5 }} />

              <Grid container sx={{pl:1, pr: 1, backgroundColor: "white" }} spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack
                    sx={{
                      height: '2rem'
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Remodeling/Updates</Typography>
                    <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Time Frame</Typography>
                    <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600" , mr:"1rem !important"}}>Estimated Cost</Typography>
                  </Stack>
                </Grid>

                {remodeling_or_updates.map((row)=>{
                  // setSumCost(sumCost + row.estimated_cost)
                  return (

                    <Grid item xs={12} md={12} lg={12}>
                      <Stack
                        sx={{
                          width: '100%',
                          backgroundColor: (theme) => theme.palette.action.hover,
                          height: '1.7rem'
                        }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <Typography sx={{ minWidth: '182px', width:"13%" ,textAlign:"left" }}>{row.unit}</Typography>
                        <Typography sx={{ minWidth: '100px', width:"33%",textAlign:"left",pl:"4rem"  }}>{row.time_frame}</Typography>
                        <Typography sx={{ minWidth: '132px', width:"8%",textAlign:"left" ,pr:"0.2rem" }}>{row.estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')===undefined?'': '$ '+row.estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                      </Stack>
                    </Grid>
                  )

                })}
                <Grid item xs={12} md={12} lg={12}>
                  <Stack
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                      height: '1.7rem'
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography sx={{ minWidth: '182px', width:"40%" ,textAlign:"left",fontSize:"1.1rem" ,fontWeight:"600" }}>Total Estimated Cost</Typography>
                    <Typography sx={{ minWidth: '100px', width:"3%",textAlign:"center",pr:"5rem"  }}>{''}</Typography>
                    <Typography sx={{ minWidth: '132px', width:"8%",textAlign:"left" ,pr:"0.2rem" }}>{data?.total_estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')===undefined?'':
                      '$ '+ data?.total_estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                  </Stack>
                </Grid>
              </Grid>

              {/*<Divider sx={{ my: 1, backgroundColor: 'text.primary', height: 1.5 }} />*/}

              {/*<Stack sx={{ padding: 1, backgroundColor: "white", width: '100.8%',transform:"translate(-0.5rem,0)" }}  direction="row" justifyContent="space-between" alignItems="center" spacing={1}>*/}
              {/*  <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Total Estimated Cost</Typography>*/}
              {/*  <Typography sx={{ width: '12%' ,textAlign:"left" ,pr:"1rem" }}>*/}
              {/*    {data?.total_estimated_cost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
              {/*  </Typography>*/}
              {/*</Stack>*/}
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ my: 1,padding: 1, backgroundColor: "white" , width: '100.8%',transform:"translate(-0.5rem,0)",  pl:"16px"}}
                spacing={1}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography variant="h6"  sx={{fontSize:"1.1rem" ,fontWeight:"600"}}>Important Features</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    width: '100%',
                    backgroundColor: (theme) => theme.palette.action.hover,
                    height: '6rem',

                  }}
                >
                  <Typography
                    display="inline-block"
                    sx={{
                      wordWrap: 'break-word',
                      maxWidth: '90%',
                      height: '100%',
                      overflow:"hidden",
                      fontSize:"0.8rem !important"
                    }}
                  >
                    {data?.important_features?.split('\n')?.map((item,index)=>{
                      return(
                        <div>
                          {item}
                        </div>
                      )
                    })}
                  </Typography>
                </Stack>
              </Stack>
            </>
        }





        <div className={classes.pagebreak} style={{opacity:0}}/>

        <Divider sx={{ my: 1, backgroundColor: 'rgba(0,0,0,0)', height: 2 ,display:"none"}} />
        <div style={{width:"100%" , height:browserName==="Safari"?"54rem":"64rem",paddingTop:"1rem" , display:"flex" , alignItems:"center" , justifyContent:"flex-start" ,flexDirection:"column"}}>
          <img src={tableImg} style={{width:"100%" , height:browserName==="Safari"?"54rem":"64rem" , objectFit:'fill'}}/>
          {/*<img src={TableShotImage} style={{width:"100%" , height:browserName==="Safari"?"54rem":"64rem" , objectFit:'fill'}}/>*/}
          {/*<TablePrint rows={dataRows} />*/}
          {/*<TablePrint rows={dataRows} />*/}

        </div>

        <Divider sx={{ my: 2, backgroundColor:'#FFFFFF', height: 2,display:"none" }} />
        <div className={classes.pagebreak} />
        {/*<div className="page-break" />*/}

        <div sx={{ my: 4 }} />
        {/*<div ref={imageRef}>*/}
        {/*  {*/}
        {/*    latLngs[0]?.lng===''? null :        <MapComponent states={states} zipCodes={zipCodes} cities={cities}*/}
        {/*                                                      name={name} proximixy={dataRows[3]} latLngs={latLngs} proximities={proximities} />*/}

        {/*  }*/}
        {/*</div>*/}
        <div style={{width:"100%" , display:"flex" , alignItems:"center" , justifyContent:"flex-start" ,flexDirection:"column",paddingTop:"1rem"}}>
          <img src={mapImage} style={{width:"100%" , height:browserName==="Safari"?"54rem":"64rem" , objectFit:"cover"}}/>

        </div>
        {/*<GoogleMaps /> */}
        {/*<SimpleMap/>*/}
        {/*<MarkerInfoWindow/>*/}
        {/*<MarkerInfoWindowGmapsObj/>*/}

      </div>

    </div>
    </div>
  );
}
