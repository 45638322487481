import { Paper } from "@mui/material";
import styled from "styled-components/macro";
import React from "react";

const WrapperMacro = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Wrapper = ({children}) => {
  return (
    <WrapperMacro>
      {children}
    </WrapperMacro>
    )
};

export default Wrapper;
