/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import './styles/mapStyle.css';
import { browserName, browserVersion } from "react-device-detect";
import { doc } from "prettier";
import { fireEvent } from "@testing-library/react";


export default function MapComponent({ name, proximities ,latLngs , states , zipCodes , cities}) {
  const mapRef = useRef(null);
  const [zooming , setZooming]=useState(false)

  // const subject = useSelector((state) => state.comparisionData.subjectPlace);
  // const latLng = useSelector((state) => state.comparisionData.compPlace);

  // const listLatLng = [
  //   {
  //     "lat": 29.79835,
  //     "lng": -95.418603,
  //     "name": "1110 W 15th 1/2 St, Houston TX, 77008"
  //   },
  //   {
  //     "lat": 29.798003,
  //     "lng": -95.417782,
  //     "name": "7777 W 15th 1/2 St , Houston, TX , 77008 ",
  //     "prox": "0.055"
  //   },
  //   {
  //     "lat": 29.797603,
  //     "lng": -95.417777,
  //     "name": "8888 W 15th 1/2 St , Houston, TX, 77008 ",
  //     "prox": "0.072"
  //   },
  //   {
  //     "lat": 29.798842,
  //     "lng": -95.420555,
  //     "name": "1212 W 15th 1/2 St , Houston, TX , 77008 ",
  //     "prox": "0.122"
  //   }
  // ];
  const listLatLng = [
    {
      "lat":isNaN(parseFloat(latLngs[0].lat))?0: parseFloat(latLngs[0].lat) ,
      "lng":isNaN(parseFloat(latLngs[0].lng))?0: parseFloat(latLngs[0].lng),
      "name": name.subject,
      "city":cities?.subject,
      'state' : states?.subject,
      "zip" :zipCodes?.subject
    },
    {
      "lat":isNaN(parseFloat(latLngs[1].lat))?0: parseFloat(latLngs[1].lat),
      "lng":isNaN(parseFloat(latLngs[1].lng))? 0: parseFloat(latLngs[1].lng),
      "name": name.comp1,
      "prox":proximities.comp1,
      "city":cities?.comp1,
      'state' : states?.comp1,
      "zip" :zipCodes?.comp1,
      'posA': !isNaN(parseFloat(latLngs[1].lat)) && !isNaN(parseFloat(latLngs[0].lat)) ?  parseFloat(latLngs[0].lat) - parseFloat(latLngs[1].lat) > 0?'S':'N':'',
      'posT': !isNaN(parseFloat(latLngs[1].lng)) && !isNaN(parseFloat(latLngs[0].lng)) ?  parseFloat(latLngs[0].lng) - parseFloat(latLngs[1].lng) > 0?'W':'E':''
    },
    {
      "lat": isNaN(parseFloat(latLngs[2].lat))?0:parseFloat(latLngs[2].lat),
      "lng": isNaN(parseFloat(latLngs[2].lng))? 0:parseFloat(latLngs[2].lng),
      "name": name.comp2,
      "prox":proximities.comp2,
      "city":cities?.comp2,
      'state' : states?.comp2,
      "zip" :zipCodes?.comp2,
      'posA': !isNaN(parseFloat(latLngs[2].lat)) && !isNaN(parseFloat(latLngs[0].lat)) ?  parseFloat(latLngs[0].lat) - parseFloat(latLngs[2].lat) > 0?'S':'N':'',
      'posT': !isNaN(parseFloat(latLngs[2].lng)) && !isNaN(parseFloat(latLngs[0].lng)) ?  parseFloat(latLngs[0].lng) - parseFloat(latLngs[2].lng) > 0?'W':'E':''

    },
    {
      "lat": isNaN(parseFloat(latLngs[3].lat))?0:parseFloat(latLngs[3].lat),
      "lng": isNaN(parseFloat(latLngs[3].lng))? 0:parseFloat(latLngs[3].lng),
      "name": name.comp3,
      "prox":proximities.comp3,
      "city":cities?.comp3,
      'state' : states?.comp3,
      "zip" :zipCodes?.comp3,
      'posA': !isNaN(parseFloat(latLngs[3].lat)) && !isNaN(parseFloat(latLngs[3].lat)) ?  parseFloat(latLngs[0].lat) - parseFloat(latLngs[3].lat) > 0?'S':'N':'',
      'posT': !isNaN(parseFloat(latLngs[3].lng)) && !isNaN(parseFloat(latLngs[3].lng)) ?  parseFloat(latLngs[0].lng) - parseFloat(latLngs[3].lng) > 0?'W':'E':''

    }
  ];

  function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      // mapTypeControl: true
    };
  }

  const loadedMap = (map, maps) => {
    DraggableOverlay.prototype = new maps.OverlayView();

    DraggableOverlay.prototype.onAdd = function () {
      var container = document.createElement('div'),
        that = this;

      if (typeof this.get('content').nodeName !== 'undefined') {
        container.appendChild(this.get('content'));
        } else {
        if (typeof this.get('content') === 'string') {
          container.innerHTML = this.get('content');
        } else {
          return;
        }
      }
      container.style.position = 'absolute';
      container.draggable = true;
      maps.event.addDomListener(this.get('map').getDiv(), 'mouseleave', function () {
        maps.event.trigger(container, 'mouseup');
      });


      maps.event.addDomListener(container, 'mousedown', function (e) {
        this.style.cursor = 'move';
        that.map.set('draggable', false);
        that.set('origin', e);

        that.moveHandler = maps.event.addDomListener(
          that.get('map').getDiv(),
          'mousemove',
          function (e) {
            var origin = that.get('origin'),
              left = origin.clientX - e.clientX,
              top = origin.clientY - e.clientY,
              pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
              latLng = that
                .getProjection()
                .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
            that.set('origin', e);
            that.set('position', latLng);
            that.draw();

            const lat = latLng.lat();
            const lng = latLng.lng();
            // console.log(that?.content?.includes('Comparable No. 3') , 'that')
            let poseX = that?.content?.includes('Comparable No. 1')? -200 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 40 : -350
            let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -250 : -275

            const offsetLatLngLeftCorner = that
              .getProjection()
              .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
            const offsetLatLngRightCorner = that
              .getProjection()
              .fromDivPixelToLatLng(new maps.Point(pos.x + 35+poseX, pos.y + 35+poseY));
            const coordinates = [
              { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
              { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
              { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
              { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
            ];
            that.get('flightPath').setPath(coordinates);
          }
        );

      });


      that.moveHandler = maps.event.addDomListener(
        that.get('map').getDiv(),
        // 'mouseenter',
        // 'wheel',
        //  'click',
        'zoom_changed',
        function (e) {
          var origin = that.get('origin'),
            left = 0,
            top = 0 ,
            pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
            latLng = that
              .getProjection()
              .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
          that.set('origin', e);
          that.set('position', latLng);
          that.draw();

          const lat = latLng.lat();
          const lng = latLng.lng();
          // console.log(that?.content?.includes('Comparable No. 3') , 'that')
          let poseX = that?.content?.includes('Comparable No. 1')?  -200 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 40 : -350
          let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -250 : -275

          const offsetLatLngLeftCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
          const offsetLatLngRightCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 35+poseX, pos.y + 35+poseY));
          const coordinates = [
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
            { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
            { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
          ];
          that.get('flightPath').setPath(coordinates);
        }
      );



      //!!!!!!!


      // that.moveHandler = maps.event.addDomListener(
      //   that.get('map').getDiv(),
      //   // 'mouseenter',
      //   // 'wheel',
      //   //  'click',
      //   'load',
      //   function (e) {
      //     console.log('load')
      //     var origin = that.get('origin'),
      //       left = 0,
      //       top = 0 ,
      //       pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
      //       latLng = that
      //         .getProjection()
      //         .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
      //     that.set('origin', e);
      //     that.set('position', latLng);
      //     that.draw();
      //
      //     const lat = latLng.lat();
      //     const lng = latLng.lng();
      //     // console.log(that?.content?.includes('Comparable No. 3') , 'that')
      //     let poseX = that?.content?.includes('Comparable No. 1')? 0 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 0 : -150
      //     let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -150 : -75
      //
      //     const offsetLatLngLeftCorner = that
      //       .getProjection()
      //       .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
      //     const offsetLatLngRightCorner = that
      //       .getProjection()
      //       .fromDivPixelToLatLng(new maps.Point(pos.x + 30+poseX, pos.y + 30+poseY));
      //     const coordinates = [
      //       { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
      //       { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
      //       { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
      //       { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
      //     ];
      //     that.get('flightPath').setPath(coordinates);
      //   }
      // );


      that.moveHandler =  map.addListener(
        "zoom_changed",
        (e) => {
          setTimeout(()=>{


        var origin = that.get('origin'),
          left = 0,
          top = 0 ,
          pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
          latLng = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
        that.set('origin', e);
        that.set('position', latLng);
        that.draw();

        const lat = latLng.lat();
        const lng = latLng.lng();
        // console.log(that?.content?.includes('Comparable No. 3') , 'that')
        let poseX = that?.content?.includes('Comparable No. 1')?  -200 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 40: -350
        let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -250 : -275

        const offsetLatLngLeftCorner = that
          .getProjection()
          .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
        const offsetLatLngRightCorner = that
          .getProjection()
          .fromDivPixelToLatLng(new maps.Point(pos.x + 35+poseX, pos.y + 35+poseY));
        const coordinates = [
          { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
          { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
          { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
          { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
        ];
        that.get('flightPath').setPath(coordinates);
          },500)
      });

      that.moveHandler = maps.event.addDomListener(
        that.get('map').getDiv(),
        // 'mouseenter',
        // 'wheel',
         'mouseup',
        // 'zoom_changed',
        function (e) {
            // setTimeout(()=>{
              var origin = that.get('origin'),
                left = 0,
                top = 0 ,
                pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
                latLng = that
                  .getProjection()
                  .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
              that.set('origin', e);
              that.set('position', latLng);
              that.draw();

              const lat = latLng.lat();
              const lng = latLng.lng();
              // console.log(that?.content?.includes('Comparable No. 3') , 'that')
              let poseX = that?.content?.includes('Comparable No. 1')?  -200 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 40 : -350
              let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -250 : -275

              const offsetLatLngLeftCorner = that
                .getProjection()
                .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
              const offsetLatLngRightCorner = that
                .getProjection()
                .fromDivPixelToLatLng(new maps.Point(pos.x + 35+poseX, pos.y + 35+poseY));
              const coordinates = [
                { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
                { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
                { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
                { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
              ];
              that.get('flightPath').setPath(coordinates);
              setZooming(false)
            // },1000)
        }
      );


      that.moveHandler = maps.event.addDomListener(
        that.get('map').getDiv(),
        // 'mouseenter',
        'wheel',
        //  'click',
        // 'zoom_changed',
        function (e) {
          var origin = that.get('origin'),
            left = 0,
            top = 0 ,
            pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
            latLng = that
              .getProjection()
              .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
          that.set('origin', e);
          that.set('position', latLng);
          that.draw();

          const lat = latLng.lat();
          const lng = latLng.lng();
          //  (that?.content?.includes('Comparable No. 3') , 'that')
          let poseX = that?.content?.includes('Comparable No. 1')?  -200 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 40 : -350
          let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -250 : -275
          const offsetLatLngLeftCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
          const offsetLatLngRightCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 35+poseX, pos.y + 35+poseY));


          const coordinates = [
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
            { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
            { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
          ];
          that.get('flightPath').setPath(coordinates);
        }
      );


      that.moveHandler = maps.event.addDomListener(
        that.get('map').getDiv(),
        // 'mouseenter',
        'mouseup',
        //  'click',
        // 'zoom_changed',
        function (e) {
          // var origin = that.get('origin'),
          //   left = 0,
          //   top = 0 ,
          //   pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
          //   latLng = that
          //     .getProjection()
          //     .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
          // that.set('origin', e);
          // that.set('position', latLng);
          // that.draw();
          //
          // const lat = latLng.lat();
          // const lng = latLng.lng();
          // //  (that?.content?.includes('Comparable No. 3') , 'that')
          // let poseX = that?.content?.includes('Comparable No. 1')? 0 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 0 : -150
          // let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -150 : -75
          //
          // const offsetLatLngLeftCorner = that
          //   .getProjection()
          //   .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
          // const offsetLatLngRightCorner = that
          //   .getProjection()
          //   .fromDivPixelToLatLng(new maps.Point(pos.x + 30+poseX, pos.y + 30+poseY));
          // const coordinates = [
          //   { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
          //   { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
          //   { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
          //   { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
          // ];
          // that.get('flightPath').setPath(coordinates);
        }
      );


      that.moveHandler = maps.event.addDomListener(
        that.get('map').getDiv(),
        'mouseover',
        // 'wheel',
        //  'click',
        // 'zoom_changed',
        function (e) {
          // var origin = that.get('origin'),
          //   left = 0,
          //   top = 0 ,
          //   pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
          //   latLng = that
          //     .getProjection()
          //     .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
          // that.set('origin', e);
          // that.set('position', latLng);
          // that.draw();
          //
          // const lat = latLng.lat();
          // const lng = latLng.lng();
          // // console.log(that?.content?.includes('Comparable No. 3') , 'that')
          // let poseX = that?.content?.includes('Comparable No. 1')? 0 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 0 : -150
          // let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -150 : -75
          //
          // const offsetLatLngLeftCorner = that
          //   .getProjection()
          //   .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
          // const offsetLatLngRightCorner = that
          //   .getProjection()
          //   .fromDivPixelToLatLng(new maps.Point(pos.x + 30+poseX, pos.y + 30+poseY));
          // const coordinates = [
          //   { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
          //   { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
          //   { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
          //   { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
          // ];
          // that.get('flightPath').setPath(coordinates);
        }
      );


      that.moveHandler = maps.event.addDomListener(
        that.get('map').getDiv(),
        'mouseenter',
        // 'wheel',
        //  'click',
        // 'zoom_changed',
        function (e) {
          var origin = that.get('origin'),
            left = 0,
            top = 0 ,
            pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
            latLng = that
              .getProjection()
              .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
          that.set('origin', e);
          that.set('position', latLng);
          that.draw();

          const lat = latLng.lat();
          const lng = latLng.lng();
          // console.log(that?.content?.includes('Comparable No. 3') , 'that')
          let poseX = that?.content?.includes('Comparable No. 1')?  -200 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 40 : -350
          let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -250 : -275

          const offsetLatLngLeftCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
          const offsetLatLngRightCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 35+poseX, pos.y + 35+poseY));


          const coordinates = [
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
            { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
            { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
          ];
          that.get('flightPath').setPath(coordinates);
        }
      );

      that.moveHandler = maps.event.addDomListener(
        that.get('map').getDiv(),
        'mouseleave',
        // 'wheel',
        //  'click',
        // 'zoom_changed',
        function (e) {
          var origin = that.get('origin'),
            left = 0,
            top = 0 ,
            pos = that.getProjection().fromLatLngToDivPixel(that.get('position')),
            latLng = that
              .getProjection()
              .fromDivPixelToLatLng(new maps.Point(pos.x - left, pos.y - top));
          that.set('origin', e);
          that.set('position', latLng);
          that.draw();

          const lat = latLng.lat();
          const lng = latLng.lng();
          // console.log(that?.content?.includes('Comparable No. 3') , 'that')
          let poseX = that?.content?.includes('Comparable No. 1')?  -200 :that?.content?.includes('Comparable No. 2') ? 150 :that?.content?.includes('Comparable No. 3') ? 40 : -350
          let poseY = that?.content?.includes('Comparable No. 1')? 150 :that?.content?.includes('Comparable No. 2') ? 75 :that?.content?.includes('Comparable No. 3') ? -250 : -275

          const offsetLatLngLeftCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 15+poseX, pos.y + 15+poseY));
          const offsetLatLngRightCorner = that
            .getProjection()
            .fromDivPixelToLatLng(new maps.Point(pos.x + 35+poseX, pos.y + 35+poseY));
          const coordinates = [
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) },
            { lat: offsetLatLngLeftCorner.lat(), lng: offsetLatLngLeftCorner.lng() },
            { lat: offsetLatLngRightCorner.lat(), lng: offsetLatLngRightCorner.lng() },
            { lat: Number(that.get('item').lat), lng: Number(that.get('item').lng) }
          ];
          that.get('flightPath').setPath(coordinates);
        }
      );
      //!!!!!!!


      maps.event.addDomListener(container, 'mouseup', function () {
        that.map.set('draggable', true);
        this.style.cursor = 'default';
        maps.event.removeListener(that.moveHandler);
      });

      this.set('container', container);
      this.getPanes().floatPane.appendChild(container);
    };

    function DraggableOverlay(map, position, content, flightPath, item) {
      if (typeof draw === 'function') {
        this.draw = draw;
      }
      this.setValues({
        position: position,
        container: null,
        content: content,
        map: map,
        flightPath: flightPath,
        item: item
      });
    }

    DraggableOverlay.prototype.draw = function () {
      // console.log(this?.content?.includes('Comparable No. 1'))
      let poseX = this?.content?.includes('Comparable No. 1')?  -200 :this?.content?.includes('Comparable No. 2') ? 150 :this?.content?.includes('Comparable No. 3') ? 40 : -350
      let poseY = this?.content?.includes('Comparable No. 1')? 150 :this?.content?.includes('Comparable No. 2') ? 75 :this?.content?.includes('Comparable No. 3') ? -250 : -275

      var pos = this.getProjection().fromLatLngToDivPixel(this.get('position'));
      this.get('container').style.left = pos.x+poseX + 'px';
      this.get('container').style.top = pos.y+poseY + 'px';
    };




    DraggableOverlay.prototype.onRemove = function () {
      this.get('container').parentNode.removeChild(this.get('container'));
      this.set('container', null);
    };
    if (listLatLng.length !== 0) {
      listLatLng.forEach((item, index) => {
        let contentString;
        if(item.name===undefined || item.name==='' || item.name===null){
          contentString=`<dov></dov>`
        }else {
          if (index === 0) {
            contentString = `
          <div id="subject">
           <div id="bodyContent">
                     <div id="bodyContent"><b>Subject</b></div>

            <div><b></b> ${item.name} </div>
           <div><b></b> ${  item.city+ ', '+item.state+ ' '+item.zip } </div>
          </div>
          
          

        `;
          } else if (index === 1) {
            contentString = `
          <div id="comp1">
          <div><b>Comparable No. 1</b></div>
           <div id="bodyContent">
          <div><b></b> ${item.name} </div>
           <div><b></b> ${  item.city+ ', '+item.state+ ' '+item.zip } </div>
          
          </div>
          <div><div>${ item.prox === undefined? ''  : item.prox} mi ${item.posA+item.posT+'°'}</div></div>
          </div>`;
          } else if (index === 2) {
            contentString = `
          <div id="comp2">
          <div><b>Comparable No. 2</b></div>
          <div id="bodyContent">
         <div><b></b> ${item.name} </div>
           <div><b></b> ${  item.city+ ', '+item.state+ ' '+item.zip } </div>
          </div>
         
          <div><div>${item.prox === undefined? ''  :item.prox} mi ${item.posA+item.posT+'°'}</div></div>

          </div>`;
          } else if (index === 3) {
            contentString = `
          <div id="comp3">
          <div><b>Comparable No. 3</b></div>
          <div id="bodyContent">
         <div><b></b> ${item.name} </div>
           <div><b></b> ${  item.city+ ', '+item.state+ ' '+item.zip } </div>
          </div>
          <div><div> ${item.prox === undefined? ''  : item.prox} mi ${item.posA+item.posT+'°'}</div></div>
          </div>`;
          } else {
            contentString = '';
          }
        }



        const colorFill = item.name===undefined || item.name==='' || item.name===null ? 0 : 0.7
        let coordinates = [{ lat: Number(item.lat), lng: Number(item.lng) }];
        const colorPolygon = index === 0 ? '#FF0000' : 'blue';
        const flightPath = new maps.Polygon({
          path: coordinates,
          strokeColor: colorPolygon,
          strokeOpacity: 1.0,
          strokeWeight: 0,
          fillColor: colorPolygon,
          fillOpacity: colorFill
        });

        flightPath.setMap(map);

        const overlay = new DraggableOverlay(map, map.getCenter(), contentString, flightPath, item);
      });
    }
  };





  // useEffect(()=>{
  //   if(zooming){
  //     setInterval(()=>{
  //       setZooming(false)
  //     },[500])
  //   }
  // },[zooming])

  // console.log(browserName ,'name')
  return (
    // <div style={{ height: '85vh', width: '100%' }} ref={mapRef}>
    // <div style={{maxHeight:"none",maxWidth:"none" , height:browserName==='Safari'? '70vh' : '85vh', width: '100%' }} ref={mapRef}>
    <div style={{maxHeight:"none",maxWidth:"none" , height:browserName==='Safari'? '100%' : '100%', width: '100%' }} ref={mapRef} >

    {/*{browserName==='Safari' ? 'sls' :'tr'}*/}
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyC67iRupm53Y7eLhmNO5wXthRaX2KHggsw' }}
        defaultCenter={{
          lat: listLatLng[0].lat,
          lng: listLatLng[0].lng
        }}
        options={{
          disableDefaultUI: true,
          mapTypeControl: true,
          streetViewControl: true,
          styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
        }}
        defaultZoom={14}
        yesIWantToUseGoogleMapApiInternals
        center={{lat: listLatLng[0].lat,
          lng: listLatLng[0].lng }}
        onGoogleApiLoaded={({ map, maps }) =>{
          // firstDraw(map , maps)
          loadedMap(map, maps)
        }}

        options={createMapOptions}
        onZoomAnimationStart={(e)=>{
          setZooming(true)
        }}

      />
    </div>
  );
}

/*import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC67iRupm53Y7eLhmNO5wXthRaX2KHggsw' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
*/
