import { Formik } from "formik";
import * as Yup from "yup";
import { PASSWORD_REGEX_MATCH_ERROR_MESSAGE, PASSWORD_REGEX_PATTERN } from "../../constants";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import axios from "../../utils/axios";
import { tryGetFirstArrayElement } from "../../utils/helpers";
import { SimpleSnackbar } from "../Snackbars";
import useSnackbar from "../../hooks/useSnackbar";


const ChangePasswordComponent = ({handleClose}) => {


  const {alert} = useSnackbar();

  const handlePasswordChange = async (values, { setErrors, setStatus, setSubmitting }) => {

    try {
      await axios.put("/users/profile/", {
        old_password: values.oldPassword, new_password: values.newPassword
      });
      alert({message: "Password changed successfully", severity: "success"});
      handleClose();
    } catch (error) {

      if (error.message) {
        alert({message: error.message, severity: "error"});
        setErrors({ submit: error.message });
      } else {
        setErrors({
          oldPassword: tryGetFirstArrayElement(error?.old_password),
          newPassword: tryGetFirstArrayElement(error?.new_password)
        });
      }

      setSubmitting(false);
      setStatus(false);

    }


  };

  return (
    <>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required("Required"),
          newPassword: Yup.string()
            .matches(
              PASSWORD_REGEX_PATTERN,
              PASSWORD_REGEX_MATCH_ERROR_MESSAGE
            )
            .required("Required"),
          confirmNewPassword: Yup.string().when("newPassword", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string()
              .oneOf(
                [Yup.ref("newPassword")],
                "Both password need to be the same"
              )
              .required("Required")
          })
        })}
        onSubmit={handlePasswordChange}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
          <form onValidate onSubmit={handleSubmit}>

            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning" sx={{marginTop: "1rem", marginBottom: "2rem"}}>
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="password"
              value={values.oldPassword}
              name="oldPassword"
              label="Old password"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={errors.oldPassword && errors.oldPassword}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              type="password"
              name="newPassword"
              label="New password"
              fullWidth
              sx={{ marginBottom: "1rem" }}
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={errors.newPassword && errors.newPassword}
            />
            <TextField
              type="password"
              name="confirmNewPassword"
              label="Confirm new password"
              fullWidth
              sx={{ marginBottom: "1rem" }}
              value={values.confirmNewPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                touched.confirmNewPassword && errors.confirmNewPassword
              )}
              helperText={
                errors.confirmNewPassword && errors.confirmNewPassword
              }
            />

            <Button type="submit" variant="contained" color="success">
              Done
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ChangePasswordComponent;
