import React, { useContext, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../../contexts/JWTContext";
import EmailField from "../../pages/profile/EmailField";
import PersonalField from "../../pages/profile/PersonalField";
import ChangePassword from "../../pages/profile/ChangePassword";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import UploadImage from "./UploadImage";

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled.img`
  display: block;
  border-radius: 5px;
  max-height: 100%;
  max-width: 100%;
`;



function Details({imageSrc}) {

  const [open, setOpen] = useState(false);

  const openChangeAvatar = () => {
    setOpen(true);
  };


  const handleAvatarClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <CardContent>
        <Centered>
          <Avatar alt="Profile image" src={imageSrc} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              marginTop: "0.5rem"
            }}
          >
            <Button onClick={(e) => openChangeAvatar()}>
              <Box>
                <ModeEditIcon style={{ fontSize: "1rem" }}/>
              </Box>
              <Box
                fontWeight="fontWeightMedium"
                style={{ marginLeft: "0.2rem" }}
              >
                edit
              </Box>
            </Button>
          </Box>
        </Centered>
      </CardContent>

      <Dialog onClose={handleAvatarClose} open={open}>
        <DialogTitle>Change profile image</DialogTitle>
        <DialogContent>
          <UploadImage imageSrc={imageSrc} handleClose={handleAvatarClose}/>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

function UserData() {
  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [errors, setErrors] = useState(null);
  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false);
  const { user } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const handleClose = () => {
    setOpenPasswordChangeModal(false);
    setOpenSnack(true);
    setMessage("Password changed successfully");
  };



  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Personal Information
          </Typography>

          <Spacer mb={4}/>

          <Grid container columnGap={10}>
            <Grid item xs={12} mb={5}>
              <EmailField/>

            </Grid>

            <Grid item xs={12} md={6} lg={5} mb={6}>
              <PersonalField label="First Name" value={user?.first_name}/>
            </Grid>
            <Grid item xs={12} md={6} lg={5} mb={6}>
              <PersonalField label="Last Name" value={user?.last_name}/>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenPasswordChangeModal(true)}
              >
                Change Password
              </Button>
              <ChangePassword
                open={openPasswordChangeModal}
                handleClose={handleClose}
                onPasswordChangeSubmit={() => {
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default Details;
