import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PropertyGeneralInfo from "../../components/property/PropertyGeneralInfo";
import RemodelingUpdates from "../../components/property/RemodlingUpdates";
import PropertyImageSelection from "../../components/property/PropertyImageSelection";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "../../../utils/axios";
import useSnackbar from "../../../hooks/useSnackbar";
import { useDispatch, useSelector } from "react-redux";
// import { setPropertyInfo } from "../../redux/actions/realstateProperty";
import Loader from "../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { PropertyContext, PropertyProvider } from "../../../contexts/PropertyContext";
import { Helmet } from "react-helmet-async";

const PropertyInfoPage = () => {

  const steps = ["General Information", "Remodeling - Updates", "Images"];
  const [activeStep, setActiveStep] = useState(0);
  const { alert } = useSnackbar();
  // const propertyInfo = useSelector(state => state.property);
  const { propertyInfo, setPropertyInfo } = useContext(PropertyContext);
  const [remodeling, setRemodeling] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isInitialized } = useAuth();
  const [loading, setLoading] = useState(false);

  const [initialValues , setInitialValues]=useState()

  useEffect(() => {
    if (isInitialized) {
      if (id) {
        setLoading(true);
        axios.get(`/properties/prop/${id}/`).then(
          resp => {
            // dispatch(setPropertyInfo(resp.data));
            setInitialValues(resp.data)
            setPropertyInfo({ ...resp.data, general: resp.data });
            setLoading(false);
          },
          err => {
            alert({ message: err.message, severity: "error" });
            navigate("/");
          }
        );
      }
    }
  }, [isInitialized , id]);


  const lookupDataFetch = async (address, city, state) => {
    // console.log(address,city,state);
    try {
      let lookup = propertyInfo?.lookup_property_data;
      if (propertyInfo?.attom_id) {
        return { zip: lookup?.zip, baths: lookup?.baths, bedrooms: lookup?.bedrooms };
      }
      let resp = await axios.post("/properties/lookup/", {
        address: address, city: city, state: state
      });
      // console.log(resp?.data);
      lookup = resp?.data?.lookup_property_data;
      if (lookup) {
        setPropertyInfo({ ...propertyInfo, lookup_property_data: lookup });
      }
      return lookup;
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      return null;
    }

  };


  const handleNext = (payload) => {

      if (payload) {
        saveData(payload);
        return;
      }
  };


  const saveData = async (remodel) => {
    // console.log(propertyInfo);
    setLoading(true);
    let propertyId = propertyInfo?.id;
    let payload = {
      inserted_property_data: { general: propertyInfo.general },
      remodeling_or_updates: remodel,
      address: propertyInfo.general.address,
      city: propertyInfo.general.city,
      state: propertyInfo.general.state
    };
    // payload = {...payload, address: propertyInfo.general.address, city: propertyInfo.general.city, state: propertyInfo.general.state};
    try {
      let response;
      if (!propertyId) {
        // console.log('/properties/property_info')
        response = await axios.post("/properties/property_info", payload);
      } else {
        response = await axios.put(`/properties/${propertyId}/`, payload);
      }
      setLoading(false);
      // dispatch(setPropertyInfo({ ...propertyInfo, id: response.data.id }));
      setPropertyInfo({ ...propertyInfo, id: response.data.id });
      handleNext();
      alert({ message: "Property data saved successfully", severity: "success" });
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      setLoading(false);
    }
  };
  // console.log(initialValues  ,'initi')
  return (
    <>
      <Helmet title={id ? "Edit Property" : "Add Property"}/>
      <Box>

        {
          loading ?
            <Loader/>
            :
            (
              <>
                <Box mt={10}>
                  {loading ?
                    <Loader/>
                    :
                    (<>
                        <PropertyGeneralInfo initialValues={initialValues}/>
                    </>



/*                             <RemodelingUpdates
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                              saveData={saveData}
                            />;*/
                    )

                  }

                </Box>
              </>
            )
        }
      </Box>
    </>
  );

};

export default PropertyInfoPage;
