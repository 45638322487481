import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ImageContainer from "./imageUpload/ImageContainer";
import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Buttons from "../Buttons";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../utils/axios";
import useSnackbar from "../../../hooks/useSnackbar";
import Loader from "../../../components/Loader";
import { PropertyContext } from "../../../contexts/PropertyContext";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const MainImageContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  margin: "auto",
  height: 300,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    width: "100%"
  },
  [theme.breakpoints.up("md")]: {
    width: "40%"
  }
}));

const PropertyImageSelection = ({ handleNext, handlePrevious }) => {

  // const { images, id } = useSelector(state => state.property);
  const { propertyInfo, setPropertyInfo } = useContext(PropertyContext);
  const [files, setFiles] = useState([]);
  const [mainImage, setMainImage] = useState({});
  const [galleryImages, setGalleryImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { alert } = useSnackbar();
  const navigate = useNavigate();
  const addFile = (index, file, isMain) => {
    let newItems = files.slice();
    let foundIndex = newItems.findIndex(e => e.index === index);
    let item = { file, isMain: isMain, index };
    if (foundIndex > -1) {
      newItems[foundIndex] = item;
    } else {
      newItems.push(item);
    }
    setFiles(newItems);
  };

  const orginizeImages = () => {
    let gallery = propertyInfo?.images ? propertyInfo?.images?.filter(e => !e.is_main).slice() : [];

    if (gallery.length < 5) {
      const length = 4 - gallery.length;
      for (let i = 0; i < length; i++) {
        gallery.push({ image: null, is_main: false });
      }
    }
    setGalleryImages(gallery);
    const index = propertyInfo?.images?.findIndex(e => e.is_main);
    if (index > -1) {
      setMainImage(propertyInfo?.images[index]);
    } else {
      setMainImage({ image: null, is_main: true });
    }
  };

  useEffect(() => {
    if (!propertyInfo) {
      return;
    }
    if (!propertyInfo.id) {
      handlePrevious();
      return;
    }

    orginizeImages();

  }, [propertyInfo]);


  const uploadImages = async () => {
    setUploading(true);
    let requests = files.map(e => {
      let formData = new FormData();
      formData.append("image", e.file);
      formData.append("is_main", e.isMain ? e.isMain : false);
      return axios.post(`/properties/${propertyInfo.id}/images/`, formData);
    });

    try {
      await Promise.all(requests);
      setFiles([]);
      alert({ message: "Images uploaded successfully", severity: "success" });
      let resp = await axios.get(`/properties/${propertyInfo.id}/`);
      setPropertyInfo({ ...resp.data });
      setUploading(false);
    } catch (e) {
      alert({ message: "Uploading images failed", severity: "error" });
      setUploading(false);
    }
  };

  const onFinishClicked = () => {
    navigate("/pages/properties");
  };

  return (
    <Card>
      <CardHeader title="Select property images">
      </CardHeader>

      {uploading ?
        <Loader/>
        :
        (
          <>
            <CardContent>
              <MainImageContainer>
                <ImageContainer isMain={true} image={mainImage} index={0} addFile={addFile}/>
              </MainImageContainer>
              <Divider variant="middle">

              </Divider>

              <Grid container spacing={3} mt={5}>
                {
                  galleryImages.map((image, index) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} lg={3}>
                        <Box sx={{ height: "150px", width: "100%" }}>
                          <ImageContainer

                            image={image}
                            isMain={false}
                            index={index + 1}
                            addFile={addFile}
                          />
                        </Box>
                      </Grid>
                    );
                  })
                }


              </Grid>
            </CardContent>

            <Box sx={{
              width: "100%",
              textAlign: "right",
              marginY: 5,
              marginX: -4,
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              gap: 3
            }}>

              <Button
                variant="outlined"
                color="primary"
                onClick={
                  (e) => handlePrevious()}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Back
                </Typography>
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => handleNext()}
              >
                <Typography sx={{ fontWeight: "bold" }} onClick={onFinishClicked}>
                  Finish
                </Typography>
              </Button>

              <Button variant="contained" color="success"
                      onClick={(e) => uploadImages()}
              >
                <CloudUploadIcon sx={{ marginRight: 3 }}/>
                <Typography sx={{ fontWeight: "bold" }}>
                  Upload
                </Typography>
              </Button>
            </Box>
          </>
        )
      }

    </Card>
  );

};

export default PropertyImageSelection;
