import { combineReducers } from "redux";
import { setPropertyInfoReducer } from "./realstatePropertyReducers";
import counterReducer from "../slices/counter";

const allReducers = combineReducers({
  // counter: counterReducer,
  property: setPropertyInfoReducer
});

export default allReducers;
