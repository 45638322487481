import styled from "styled-components/macro";
import { ReactComponent as Logo } from "../vendor/logo.svg";

const BrandMacro = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Brand = () => {
  return <BrandMacro />
};

export default Brand;
