export function tryGetFirstArrayElement(arr) {
  return arr && arr.length > 0 ? arr[0] : "";
}


export function stringValueOrEmpty(value) {
  return value ? value : "";
}

export function stringNoEmpty(value) {
  return value && value.length > 0;
}