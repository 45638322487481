import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Stack,
  FormControl,
  OutlinedInput,
  InputAdornment,
  MenuItem, createTheme,
  IconButton, DialogTitle

} from "@material-ui/core";
import {
  Dialog,
  FormControlLabel, Switch
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { ComparableContext } from "../../../contexts/ComparableContext";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import axios from "../../../utils/axios";
import { stringNoEmpty, stringValueOrEmpty } from "../../../utils/helpers";
import useSnackbar from "../../../hooks/useSnackbar";
import blue from "@material-ui/core/colors/blue";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import useAuth from "../../../hooks/useAuth";
import { ExpandMore as ExpandMoreIcon, PhotoCamera } from "@mui/icons-material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Details from "./ComparableImage";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TypeOf } from "yup";
import DialogContent from "@mui/material/DialogContent";
import UploadImage from "./UploadImage";
import UploadProfileAvatar from "../../../components/profile/UploadProfileAvatar";
import { getDistance } from "geolib";
import UploadProfileLogo from "../../../components/profile/UploadProfileLogo";

const uploadImage = require( "../../../vendor/uploadImage.jpg");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

const animatedComponents = makeAnimated();

const ComparableGeneralInfo = ({ lookupDataFetch,lookupCompDataFetch }) => {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   console.log(window.scrollX , window.scrollY , document.getElementById('parrentDiv').scrollTop , 'window.scroll')
  // }, []);
  const [valueselect, setValueselect] = React.useState('');
  const { comparableInfo, setComparableInfo } = useContext(ComparableContext);
  const [useLookup, setUseLookup] = useState(false);
  const [useComp3Lookup, setComp3UseLookup] = useState(false);
  const [useComp2Lookup, setComp2UseLookup] = useState(false);
  const [useCompLookup, setCompUseLookup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [allRowsCount, setAllRowsCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [zipCodesForRows ,setZipCodesForRows]=useState([])
  const [dateClose,setDateClose]=useState(null)


  const { isInitialized, isAuthenticated } = useAuth();
  const [value, setValue] = React.useState(0);
  const {user} = useAuth();

  const navigate = useNavigate();

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const { alert } = useSnackbar();


  const getInitValueFromComparableInfo = async (general) => {
    return {
        subject: {

          street: general?.sStreet, //general?.address,
          city: general?.sCity, //general?.city,
          state: general?.sState, //general?.city,
          zip: general?.sZip,
          Price: general?.sPrice,
          Source: general?.sSource,
          dateClose : general?.sDateClose,
          Status: general?.sStatus,
          SiteSize: general?.sSiteSize,
          View: general?.sView,
          Floor: general?.sFloor,
          Age: general?.sAge,
          Condition: general?.sCondition,
          bedRooms: general?.sBedRooms,
          FBath: general?.sFBath,
          HBath: general?.sHBath,
          Area: general?.sArea,
          Basement: general?.sBasement,
          Garage: general?.sGarage,
          Deck: general?.sDeck,
          Additional: general?.sAdditional,
        },
        compOne: {
          street: general?.c1Street, //general?.address,
          city: general?.c1City, //general?.city,
          State: general?.c1State, //general?.city,
          zip: general?.c1Zip,
          // proximity:general?.c1Proximity,
          Price: general?.c1Price,
          Source: general?.c1Source,
          DateClose : general?.c1DateClose,
          Status: general?.c1Status,
          SiteSize: general?.c1SiteSize,
          View: general?.c1View,
          Floor: general?.c1Floor,
          Age: general?.c1Age,
          Condition: general?.c1Condition,
          bedRooms: general?.c1BedRooms,
          FBath: general?.c1FBath,
          HBath: general?.c1HBath,
          Area: general?.c1Area,
          Basement: general?.c1Basement,
          Garage: general?.c1Garage,
          Deck: general?.c1Deck,
          Additional: general?.c1Additional,
        },
        compTwo: {
          street: general?.c2Street, //general?.address,
          city: general?.c2City, //general?.city,
          State: general?.c2State, //general?.city,
          zip: general?.c2Zip,
          Price: general?.c2Price,
          Source: general?.c2Source,
          DateClose : general?.c2DateClose,
          Status: general?.c2Status,
          SiteSize: general?.c2SiteSize,
          View: general?.c2View,
          Floor: general?.c2Floor,
          Age: general?.c2Age,
          Condition: general?.c2Condition,
          bedRooms: general?.c2BedRooms,
          FBath: general?.c2FBath,
          HBath: general?.c2HBath,
          Area: general?.c2Area,
          Basement: general?.c2Basement,
          Garage: general?.c2Garage,
          Deck: general?.c2Deck,
          Additional: general?.c2Additional,
        },
        compThree: {
          street: general?.c3Street, //general?.address,
          city: general?.c3City, //general?.city,
          State: general?.c3State, //general?.city,
          zip: general?.c3Zip,
          Price: general?.c3Price,
          Source: general?.c3Source,
          DateClose : general?.c3DateClose,
          Status: general?.c3Status,
          SiteSize: general?.c3SiteSize,
          View: general?.c3View,
          Floor: general?.c3Floor,
          Age: general?.c3Age,
          Condition: general?.c3Condition,
          bedRooms: general?.c2BedRooms,
          FBath: general?.c3FBath,
          HBath: general?.c3HBath,
          Area: general?.c3Area,
          Basement: general?.c3Basement,
          Garage: general?.c3Garage,
          Deck: general?.c3Deck,
          Additional: general?.c3Additional,
        }

    };
  };

  useEffect(() => {
    // if (comparableInfo != null) {
    //   const init = getInitValueFromComparableInfo();
    //   setInitValues(init);
    // }
  }, [comparableInfo]);

  let mySetFieldValue = null;
  let onLookupSwitchChanged = async () => {
    // console.log(address,city,state);
    if (!stringNoEmpty(address) || !stringNoEmpty(city) || !stringNoEmpty(state)) {
      alert({ message: "provide Address, City and State to use lookup data", severity: "error" });
      return;
    }

    setLoading(true);

    let zip;
    if (!useLookup) {
      let lookup = await lookupDataFetch(address, city, state);
      if (lookup?.address_postal1 && lookup?.address_postal1 !== "") {
        zip = lookup?.address_postal1;
      } else if (lookup?.address_postal2 && lookup?.address_postal2 !== "") {
        zip = lookup?.address_postal2;
      } else {
        zip = lookup?.address_postal3;
      }

      let baths = lookup?.building_rooms_bathsfull;
      let bedroom = lookup?.building_rooms_beds;
      setZip(zip);

      mySetFieldValue("sZip", zip);
      mySetFieldValue("building_rooms_bathsfull", baths);
      mySetFieldValue("building_rooms_beds", bedroom);
      mySetFieldValue("sLat", lookup?.location_latitude );
      mySetFieldValue("sLng", lookup?.location_longitude  );


      mySetFieldValue("sSiteSize", lookup?.building_size_bldgsize);
      mySetFieldValue("sAge", lookup?.summary_yearbuilt);
      mySetFieldValue("sBedRooms", lookup?.building_rooms_beds);
      mySetFieldValue("sFBath", baths);
      mySetFieldValue("sHBath", lookup?.building_rooms_bathspartial);
      mySetFieldValue("sArea", lookup?.building_size_grosssize);
      setUseLookup(!useLookup);

    } else {
      mySetFieldValue("sZip", stringValueOrEmpty(comparableInfo?.generalInfo?.zip));
      mySetFieldValue("building_rooms_bathsfull", stringValueOrEmpty(comparableInfo?.general?.building_rooms_bathsfull));
      mySetFieldValue("building_rooms_beds", stringValueOrEmpty(comparableInfo?.general?.building_rooms_beds));
    }
    setLoading(false);
  };

  useEffect(() => {

  }, [useLookup]);

  const [c1Proximity , setC1proximity]=useState(null)
  const [c2Proximity , setC2proximity]=useState(null)
  const [c3Proximity , setC3proximity]=useState(null)

  let onCompLookupSwitchChanged = async () => {
    if (!stringNoEmpty(address) || !stringNoEmpty(city) || !stringNoEmpty(state) || !stringNoEmpty(zip)) {
      alert({ message: "provide Address, City, State and Zip in Subject to use lookup data", severity: "error" });
      return;
    }

    setLoading(true);

    if (!useCompLookup) {
      let compLookup = await lookupCompDataFetch(address, city, state,zip);
      try{
      if (compLookup) {

          let comp = compLookup[1]["COMPARABLE_PROPERTY_ext"];
         // console.log('onCompLookupSwitchChanged' ,comp )

        mySetFieldValue("c1Street", comp["@_StreetAddress"]);
          mySetFieldValue("c1City", comp["@_City"]);
          mySetFieldValue("c1State", comp["@_State"]);
          mySetFieldValue("c1Zip", comp["@_PostalCode"]);

          mySetFieldValue("c1Price", comp["SALES_HISTORY"]["@PropertySalesAmount"]);
          mySetFieldValue("c1SiteSize", comp["SITE"]["@LotSquareFeetCount"]);
          mySetFieldValue("c1Age", comp["STRUCTURE"]["STRUCTURE_ANALYSIS"]["@PropertyStructureBuiltYear"]);
          mySetFieldValue("c1BedRooms", comp["STRUCTURE"]["@TotalBedroomCount"]);
          mySetFieldValue("c1FBath", comp["STRUCTURE"]["@TotalBathroomFullCount_ext"]);
          mySetFieldValue("c1HBath", comp["STRUCTURE"]["@TotalBathroomHalfCount_ext"]);
          mySetFieldValue("c1Area", comp["STRUCTURE"]["@GrossLivingAreaSquareFeetCount"]);
          mySetFieldValue("c1Deck", comp["STRUCTURE"]["AMENITIES"]['AMENITY'][0]['@_Type']);

        // mySetFieldValue("c1Basement", comp["BASEMENT"]["@SquareFeetCount"]);
          // mySetFieldValue("c1Garage", comp["CAR_STORAGE"]["@_ParkingSpacesCount"]);
          setC1proximity(comp['@DistanceFromSubjectPropertyMilesCount'])
          setCompUseLookup(!useCompLookup);
        }
      alert({ message: "Comparable Data Succsesfuly Receive", severity: "success" });
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      setLoading(false);
    }
      }

    setLoading(false);
  };

  useEffect(() => {

  }, [useCompLookup]);

  let onCompTwoLookupSwitchChanged = async () => {
    if (!stringNoEmpty(address) || !stringNoEmpty(city) || !stringNoEmpty(state) || !stringNoEmpty(zip)) {
      alert({ message: "provide Address, City, State and Zip in Subject to use lookup data", severity: "error" });
      return;
    }

    setLoading(true);

    if (!useComp2Lookup) {
      let compLookup = await lookupCompDataFetch(address, city, state,zip);
      try{
      if (compLookup) {
          let comp = compLookup[2]["COMPARABLE_PROPERTY_ext"];
          mySetFieldValue("c2Street", comp["@_StreetAddress"]);
          mySetFieldValue("c2City", comp["@_City"]);
          mySetFieldValue("c2State", comp["@_State"]);
          mySetFieldValue("c2Zip", comp["@_PostalCode"]);

          mySetFieldValue("c2Price", comp["SALES_HISTORY"]["@PropertySalesAmount"]);
          mySetFieldValue("c2SiteSize", comp["SITE"]["@LotSquareFeetCount"]);
          mySetFieldValue("c2Age", comp["STRUCTURE"]["STRUCTURE_ANALYSIS"]["@PropertyStructureBuiltYear"]);
          mySetFieldValue("c2BedRooms", comp["STRUCTURE"]["@TotalBedroomCount"]);
          mySetFieldValue("c2FBath", comp["STRUCTURE"]["@TotalBathroomFullCount_ext"]);
          mySetFieldValue("c2HBath", comp["STRUCTURE"]["@TotalBathroomHalfCount_ext"]);
          mySetFieldValue("c2Area", comp["STRUCTURE"]["@GrossLivingAreaSquareFeetCount"]);
          mySetFieldValue("c2Deck", comp["STRUCTURE"]["AMENITIES"]['AMENITY'][0]['@_Type']);

        // mySetFieldValue("c2Basement", comp["BASEMENT"]["@SquareFeetCount"]);
          // mySetFieldValue("c2Garage", comp["CAR_STORAGE"]["@_ParkingSpacesCount"]);
        setC2proximity(comp['@DistanceFromSubjectPropertyMilesCount'])
          setComp2UseLookup(!useComp2Lookup);

      }
      alert({ message: "Comparable Data Succsesfuly Receive", severity: "success" });
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      setLoading(false);
    }
    }
    setLoading(false);
  };

  useEffect(() => {

  }, [useComp2Lookup]);

let onCompThreeLookupSwitchChanged = async () => {
  if (!stringNoEmpty(address) || !stringNoEmpty(city) || !stringNoEmpty(state) || !stringNoEmpty(zip)) {
    alert({ message: "provide Address, City, State and Zip in Subject to use lookup data", severity: "error" });
    return;
  }

  setLoading(true);

  if (!useComp3Lookup) {
    let compLookup = await lookupCompDataFetch(address, city, state,zip);
    try{
    if (compLookup) {
      let comp = compLookup[3]["COMPARABLE_PROPERTY_ext"];
      mySetFieldValue("c3Street", comp["@_StreetAddress"]);
      mySetFieldValue("c3City", comp["@_City"]);
      mySetFieldValue("c3State", comp["@_State"]);
      mySetFieldValue("c3Zip", comp["@_PostalCode"]);

      mySetFieldValue("c3Price", comp["SALES_HISTORY"]["@PropertySalesAmount"]);
      mySetFieldValue("c3SiteSize", comp["SITE"]["@LotSquareFeetCount"]);
      mySetFieldValue("c3Age", comp["STRUCTURE"]["STRUCTURE_ANALYSIS"]["@PropertyStructureBuiltYear"]);
      mySetFieldValue("c3BedRooms", comp["STRUCTURE"]["@TotalBedroomCount"]);
      mySetFieldValue("c3FBath", comp["STRUCTURE"]["@TotalBathroomFullCount_ext"]);
      mySetFieldValue("c3HBath", comp["STRUCTURE"]["@TotalBathroomHalfCount_ext"]);
      mySetFieldValue("c3Area", comp["STRUCTURE"]["@GrossLivingAreaSquareFeetCount"]);
      mySetFieldValue("c3Deck", comp["STRUCTURE"]["AMENITIES"]['AMENITY'][0]['@_Type']);

      // mySetFieldValue("c3Basement", comp["BASEMENT"]["@SquareFeetCount"]);
      // mySetFieldValue("c3Garage", comp["CAR_STORAGE"]["@_ParkingSpacesCount"]);
      setC3proximity(comp['@DistanceFromSubjectPropertyMilesCount'])
      setComp3UseLookup(!useComp3Lookup);

    }
    alert({ message: "Comparable Data Succsesfuly Receive", severity: "success" });
  } catch (e) {
    alert({ message: e.message, severity: "error" });
    setLoading(false);
  }
  }
  setLoading(false);
};

useEffect(() => {

}, [useComp3Lookup]);


  const validationSchema = Yup.object().shape({

      sStreet: Yup.string().max(400),
      sCity: Yup.string().max(100),
      sState: Yup.string().max(100),
      sZip: Yup.string().max(100),
      sPrice: Yup.string().max(100),
      sDateClose: Yup.string().max(100),
      sSource: Yup.string().max(100),
      sStatus: Yup.string().max(100),
      sSiteSize: Yup.string().max(100),
      sView: Yup.string().max(100),
      sFloor: Yup.string().max(100),
      sAge: Yup.string().max(100),
      sCondition: Yup.string().max(100),
      sBedRooms: Yup.string().max(100),
      sFBath: Yup.string().max(100),
      sHBath: Yup.string().max(100),
      sArea: Yup.string().max(100),
      sBasement: Yup.string().max(100),
      sGarage: Yup.string().max(100),
      sDeck: Yup.string().max(100),
      sAdditional: Yup.string().max(100),

      c1Street: Yup.string()
        .max(400),
      c1City: Yup.string().max(100),
      c1State: Yup.string().max(100),
      c1Zip: Yup.string().max(100),
      c1Price: Yup.string().max(100),
      c1DateClose: Yup.string().max(100),
      c1Source: Yup.string().max(100),
      c1Status: Yup.string().max(100),
      c1SiteSize: Yup.string().max(100),
      c1View: Yup.string().max(100),
      c1Floor: Yup.string().max(100),
      c1Age: Yup.string().max(100),
      c1Condition: Yup.string().max(100),
      c1BedRooms: Yup.string().max(100),
      c1FBath: Yup.string().max(100),
      c1HBath: Yup.string().max(100),
      c1Area: Yup.string().max(100),
      c1Basement: Yup.string().max(100),
      c1Garage: Yup.string().max(100),
      c1Deck: Yup.string().max(100),
      c1Additional: Yup.string().max(100),


      c2Street: Yup.string()
        .max(400),
      c2City: Yup.string().max(100),
      c2State: Yup.string().max(100),
      c2Zip: Yup.string().max(100),
      c2Price: Yup.string().max(100),
    c2DateClose: Yup.string().max(100),
    c2Source: Yup.string().max(100),
      c2Status: Yup.string().max(100),
      c2SiteSize: Yup.string().max(100),
      c2View: Yup.string().max(100),
      c2Floor: Yup.string().max(100),
      c2Age: Yup.string().max(100),
      c2Condition: Yup.string().max(100),
      c2BedRooms: Yup.string().max(100),
      c2FBath: Yup.string().max(100),
      c2HBath: Yup.string().max(100),
      c2Area: Yup.string().max(100),
      c2Basement: Yup.string().max(100),
      c2Garage: Yup.string().max(100),
      c2Deck: Yup.string().max(100),
      c2Additional: Yup.string().max(100),




      c3Street: Yup.string()
        .max(400),
      c3City: Yup.string().max(100),
      c3State: Yup.string().max(100),
      c3Zip: Yup.string().max(100),
      c3Price: Yup.string().max(100),
    c3DateClose: Yup.string().max(100),

    c3Source: Yup.string().max(100),
      c3Status: Yup.string().max(100),
      c3SiteSize: Yup.string().max(100),
      c3View: Yup.string().max(100),
      c3Floor: Yup.string().max(100),
      c3Age: Yup.string().max(100),
      c3Condition: Yup.string().max(100),
      c3BedRooms: Yup.string().max(100),
      c3FBath: Yup.string().max(100),
      c3HBath: Yup.string().max(100),
      c3Area: Yup.string().max(100),
      c3Basement: Yup.string().max(100),
      c3Garage: Yup.string().max(100),
      c3Deck: Yup.string().max(100),
      c3Additional: Yup.string().max(100),

  });
  const saveData = async (data) => {
    // alert({ message: "Comparables data saved successfully", severity: "success" });
    // setComparableInfo({...comparableInfo, data:data })



    let compOne= {
        "address": data?.compOne?.street!==undefined ? data?.compOne?.street  :null,
        "city": data?.compOne?.city!==undefined ? data?.compOne?.city  :null,
        "state": data?.compOne?.State!==undefined ? data?.compOne?.State  :null,
        "zipcode":data?.compOne?.zip!==undefined ? data?.compOne?.zip  :null,
        "bedrooms":data?.compOne?.bedRooms ===''?null : data?.compOne?.bedRooms!==undefined ? data?.compOne?.bedRooms  :null,
        "bathrooms":data?.compOne?.FBath ===''?null : data?.compOne?.FBath!==undefined ? data?.compOne?.FBath  :null,
        "half_baths":data?.compOne?.HBath ===''?null : data?.compOne?.HBath!==undefined ? data?.compOne?.HBath  :null,
        "proximity":c1Proximity!==undefined || c1Proximity !=='' ? c1Proximity :null,
        "sales_price":data?.compOne?.Price!==undefined ? data?.compOne?.Price  :null,
        "date_closed": data?.compOne?.DateClose!==undefined ? data?.compOne?.DateClose  :null,
        "data_source": data?.compOne?.Source!==undefined ? data?.compOne?.Source  :null,
        "status": data?.compOne?.Status!==undefined ? data?.compOne?.Status  :null,
        "site_size": data?.compOne?.SiteSize!==undefined ? data?.compOne?.SiteSize  :null,
        "view": data?.compOne?.View!==undefined ? data?.compOne?.View  :null,
        "floor_height": data?.compOne?.Floor!==undefined ? data?.compOne?.Floor  :null,
        "actual_age": data?.compOne?.Age!==undefined ? data?.compOne?.Age  :null,
        "condition": data?.compOne?.Condition!==undefined ? data?.compOne?.Condition  :null,
        "living_area_under_air":data?.compOne?.Area!==undefined ? data?.compOne?.Area  :null,
        "basement": data?.compOne?.Basement!==undefined ? data?.compOne?.Basement  :null,
        "garage_carport": data?.compOne?.Garage!==undefined ? data?.compOne?.Garage  :null,
        "pool_deck_porch_patio": data?.compOne?.Deck!==undefined ? data?.compOne?.Deck  :null,
        "additional_info": data?.compOne?.Additional!==undefined ? data?.compOne?.Additional  :null,
      }
    let compTwo={
      "address": data?.compTwo?.street!==undefined ? data?.compTwo?.street  :null,
      "city": data?.compTwo?.city!==undefined ? data?.compTwo?.city  :null,
      "state": data?.compTwo?.State!==undefined ? data?.compTwo?.State  :null,
      "zipcode":data?.compTwo?.zip!==undefined ? data?.compTwo?.zip  :null,
      "bedrooms":data?.compTwo?.bedRooms ===''?null : data?.compTwo?.bedRooms!==undefined ? data?.compTwo?.bedRooms  :null,
      "bathrooms":data?.compTwo?.FBath ===''?null : data?.compTwo?.FBath!==undefined ? data?.compTwo?.FBath  :null,
      "half_baths":data?.compTwo?.HBath ===''?null : data?.compTwo?.HBath!==undefined ? data?.compTwo?.HBath  :null,
      "proximity":c2Proximity!==undefined || c2Proximity!=='' ? c2Proximity :null,
      "sales_price":data?.compTwo?.Price!==undefined ? data?.compTwo?.Price  :null,
      "date_closed": data?.compTwo?.DateClose!==undefined ? data?.compTwo?.DateClose  :null,
      "data_source": data?.compTwo?.Source!==undefined ? data?.compTwo?.Source  :null,
      "status": data?.compTwo?.Status!==undefined ? data?.compTwo?.Status  :null,
      "site_size": data?.compTwo?.SiteSize!==undefined ? data?.compTwo?.SiteSize  :null,
      "view": data?.compTwo?.View!==undefined ? data?.compTwo?.View  :null,
      "floor_height": data?.compTwo?.Floor!==undefined ? data?.compTwo?.Floor  :null,
      "actual_age": data?.compTwo?.Age!==undefined ? data?.compTwo?.Age  :null,
      "condition": data?.compTwo?.Condition!==undefined ? data?.compTwo?.Condition  :null,
      "living_area_under_air":data?.compTwo?.Area!==undefined ? data?.compTwo?.Area  :null,
      "basement": data?.compTwo?.Basement!==undefined ? data?.compTwo?.Basement  :null,
      "garage_carport": data?.compTwo?.Garage!==undefined ? data?.compTwo?.Garage  :null,
      "pool_deck_porch_patio": data?.compTwo?.Deck!==undefined ? data?.compTwo?.Deck  :null,
      "additional_info": data?.compTwo?.Additional!==undefined ? data?.compTwo?.Additional  :null,
    }
    let compThree={
      "address": data?.compThree?.street!==undefined ? data?.compThree?.street  :null,
      "city": data?.compThree?.city!==undefined ? data?.compThree?.city  :null,
      "state": data?.compThree?.State!==undefined ? data?.compThree?.State  :null,
      "zipcode":data?.compThree?.zip!==undefined ? data?.compThree?.zip  :null,
      "bedrooms":data?.compThree?.bedRooms ===''?null : data?.compThree?.bedRooms!==undefined ? data?.compThree?.bedRooms  :null,
      "bathrooms":data?.compThree?.FBath ===''?null : data?.compThree?.FBath!==undefined ? data?.compThree?.FBath  :null,
      "half_baths":data?.compThree?.HBath ===''?null : data?.compThree?.HBath!==undefined ? data?.compThree?.HBath  :null,
      "proximity":c3Proximity!==undefined || c3Proximity!=='' ? c3Proximity :null,
      "sales_price":data?.compThree?.Price!==undefined ? data?.compThree?.Price  :null,
      "date_closed": data?.compThree?.DateClose!==undefined ? data?.compThree?.DateClose  :null,
      "data_source": data?.compThree?.Source!==undefined ? data?.compThree?.Source  :null,
      "status": data?.compThree?.Status!==undefined ? data?.compThree?.Status  :null,
      "site_size": data?.compThree?.SiteSize!==undefined ? data?.compThree?.SiteSize  :null,
      "view": data?.compThree?.View!==undefined ? data?.compThree?.View  :null,
      "floor_height": data?.compThree?.Floor!==undefined ? data?.compThree?.Floor  :null,
      "actual_age": data?.compThree?.Age!==undefined ? data?.compThree?.Age  :null,
      "condition": data?.compThree?.Condition!==undefined ? data?.compThree?.Condition  :null,
      "living_area_under_air":data?.compThree?.Area!==undefined ? data?.compThree?.Area  :null,
      "basement": data?.compThree?.Basement!==undefined ? data?.compThree?.Basement  :null,
      "garage_carport": data?.compThree?.Garage!==undefined ? data?.compThree?.Garage  :null,
      "pool_deck_porch_patio": data?.compThree?.Deck!==undefined ? data?.compThree?.Deck  :null,
      "additional_info": data?.compThree?.Additional!==undefined ? data?.compThree?.Additional  :null,
    }


    let payload = {
      "address":  data?.subject?.street!==undefined ? data?.subject?.street  :null,
      "city":  data?.subject?.city!==undefined ? data?.subject?.city  :null,
      "state":  data?.subject?.state!==undefined ? data?.subject?.state  :null,
      "zipcode": data?.subject?.zip!==undefined ? data?.subject?.zip  :null,
      "bedrooms": data?.subject?.bedRooms!==undefined ? data?.subject?.bedRooms  :null,
      "bathrooms": data?.subject?.FBath!==undefined ? data?.subject?.FBath  :null,
      "half_baths":data?.subject?.HBath!==undefined ? data?.subject?.HBath  :null,
      "more_detail": [
        {
          "sales_price": data?.subject?.Price!==undefined ? data?.subject?.Price  :null,
          "date_closed": data?.subject?.DateClose!==undefined ? data?.subject?.DateClose  :null,
          "data_source": data?.subject?.Source!==undefined ? data?.subject?.Source  :null,
          "status": data?.subject?.Status!==undefined ? data?.subject?.Status  :null,
          "site_size": data?.subject?.SiteSize!==undefined ? data?.subject?.SiteSize  :null,
          "view": data?.subject?.View!==undefined ? data?.subject?.View  :null,
          "floor_height": data?.subject?.Floor!==undefined ? data?.subject?.Floor  :null,
          "actual_age": data?.subject?.Age!==undefined ? data?.subject?.Age  :null,
          "condition": data?.subject?.Condition!==undefined ? data?.subject?.Condition  :null,
          "living_area_under_air":data?.subject?.Area!==undefined ? data?.subject?.Area  :null,
          "basement": data?.subject?.Basement!==undefined ? data?.subject?.Basement  :null,
          "garage_carport": data?.subject?.Garage!==undefined ? data?.subject?.Garage  :null,
          "pool_deck_porch_patio": data?.subject?.Deck!==undefined ? data?.subject?.Deck  :null,
          "additional_info": data?.subject?.Additional!==undefined ? data?.subject?.Additional  :null,
        }
      ],
      "comparable_info": [
        compOne,compTwo,compThree
      ]
    }

    setLoading(true);
    // let propertyId = propertyInfo?.id;
    // let payload = {
    //   inserted_property_data: { general: propertyInfo.general },
    //   remodeling_or_updates: remodel,
    //   address: propertyInfo.general.address,
    //   city: propertyInfo.general.city,
    //   state: propertyInfo.general.state
    // };
    // // payload = {...payload, address: propertyInfo.general.address, city: propertyInfo.general.city, state: propertyInfo.general.state};
    try {
      let response;
      if (id===undefined) {
        response = await axios.put(`/properties/prop/${id}/`, payload);
        navigate(id ?`/pages/preview/${id}`  :rowsId===-1?  "/pages/preview/" : `/pages/preview/${rowsId}`)
      } else {

        response = await axios.put(`/properties/prop/${id}/`, payload);
        navigate(id ?`/pages/preview/${id}`  :rowsId===-1?  "/pages/preview/" : `/pages/preview/${rowsId}`)

        setLoading(false);

      }
      setLoading(false);
      // dispatch(setPropertyInfo({ ...propertyInfo, id: response.data.id }));

    } catch (e) {
      alert({ message: e.message, severity: "error" });
      setLoading(false);
    }
  };

  const handleSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {

    const init = await getInitValueFromComparableInfo(values);
    // setInitValues(init);

    setSubmitting(true);
    setStatus(true);
    saveData(init);

  };
  const createData = (id, responseData) => {
    return { key: id , zip :responseData.zipcode , value: responseData?.address + "," + responseData?.city + "," + responseData?.state, label: responseData?.address + "," + responseData?.city + "," + responseData?.state ,
      fBath:responseData?.bathrooms , hBath:responseData?.half_baths, bed:responseData?.bedrooms , all:responseData };
    // id,
    // address: responseData?.address,
    // city: responseData?.city,
    // state: responseData?.state,
    // site_size: responseData?.site_size,
    // view: responseData?.view,
    // actual_age: responseData?.actual_age,
    // building_construction_condition: responseData?.building_construction_condition,
    // building_rooms_beds: responseData?.building_rooms_beds,
    // building_rooms_bathsfull: responseData?.building_rooms_bathsfull,
    // half_bathroom: responseData?.half_bathroom,
    // living_area_under_air: responseData?.living_area_under_air,
    // basement: responseData?.basement,
    // garage_carport: responseData?.garage_carport,
    // lot_pooltype: responseData?.lot_pooltype
    // };
  };


  const {id}=useParams()
  const getProperties = () => {
    setLoading(true);
     // ! property info
    // axios.get("/properties/", { params: { page_size: 25, page: 1 } })
    axios.get("/properties/prop/comp/")
      .then(response => {
        let newRows = response.data?.map(e => createData(e.id, e ));
        setAddress(response.data[0]?.address)
        setCity(response.data[0]?.city)
        setZip(response.data[0]?.zip)

        setZipCodesForRows(response.data)
        setRows(newRows);
        if(id){
          let rowsKey = newRows.find((e)=> e.key === parseInt(id))
          handleChangeMenu(rowsKey)
        }
        setLoading(false);

      }).catch(
      error => {
        alert({ message: error.message, severity: "error" });
        setLoading(false);


      }
    );
  };

  useEffect(() => {
      if (isAuthenticated) {
          getProperties();
      }
  },[isInitialized ]);


  const [rowsId , setRowsId]=useState(-1)

  const handleChangeMenu = (value, event) => {

    let data = value.value.split(',')
    setRowsId(value.key)
    setAddress(data[0]);
    setCity(data[1]);
    setState(data[2]);
    setValueselect(value.value);
    // setComparableInfo({ ...comparableInfo, property_id: parseInt(value.key.toString().split('.')[0]) })
    mySetFieldValue("sStreet", data[0]);
    setAddress( data[0])
    mySetFieldValue("sCity", data[1]);
    setCity(data[1])
    mySetFieldValue("sState", data[2]);
    setState( data[2])
    mySetFieldValue('sZip',value.zip)
    setZip(value.zip)
    mySetFieldValue('sFBath',value.fBath)
    mySetFieldValue('sHBath',value.hBath)
    mySetFieldValue('sBedRooms',value.bed)

    // mySetFieldValue('sDateClose',value.all.more_detail[0].date_closed)
    // mySetFieldValue('sPrice',value.all.more_detail[0].sales_price)
    // mySetFieldValue('sSource',value.all.more_detail[0].data_source)
    // mySetFieldValue('sStatus',value.all.more_detail[0].status)
    // mySetFieldValue('sSiteSize',value.all.more_detail[0].site_size)
    // mySetFieldValue('sView',value.all.more_detail[0].view)
    // mySetFieldValue('sFloor',value.all.more_detail[0].floor_height)
    // mySetFieldValue('sAge',value.all.more_detail[0].actual_age)
    // mySetFieldValue('sArea',value.all.more_detail[0].living_area_under_air)
    // mySetFieldValue('sBasement',value.all.more_detail[0].basement)
    // mySetFieldValue('sGarage',value.all.more_detail[0].garage_carport)
    // mySetFieldValue('sDeck',value.all.more_detail[0].pool_deck_porch_patio)
    // mySetFieldValue('sAdditional',value.all.more_detail[0].additional_info)

    // if(true){

    //   let value1 = value.all.comparable_info[0]
    //   mySetFieldValue("c1Street",'hoston');
    //   mySetFieldValue("c1City", value1.city);
    //   mySetFieldValue("c1State", value1.state);
    //   mySetFieldValue('c1Zip',value1.zip)
    //   mySetFieldValue('c1FBath',value1.bathrooms)
    //   mySetFieldValue('c1HBath',value1.half_baths)
    //   mySetFieldValue('c1BedRooms',value1.bedrooms)
    //   mySetFieldValue('c1DateClose',value1.date_closed)
    //   mySetFieldValue('c1Price',value1.sales_price)
    //   mySetFieldValue('c1Source',value1.data_source)
    //   mySetFieldValue('c1Status',value1.status)
    //   mySetFieldValue('c1SiteSize',value1.site_size)
    //   mySetFieldValue('c1View',value1.view)
    //   mySetFieldValue('c1Floor',value1.floor_height)
    //   mySetFieldValue('c1Age',value1.actual_age)
    //   mySetFieldValue('c1Condition',value1.condition)
    //
    //   mySetFieldValue('c1Area',value1.living_area_under_air)
    //   mySetFieldValue('c1Basement',value1.basement)
    //   mySetFieldValue('c1Garage',value1.garage_carport)
    //   mySetFieldValue('c1Deck',value1.pool_deck_porch_patio)
    //   mySetFieldValue('c1Additional',value1.additional_info)
    //
    //
    //
    //
    //
    // // }
    //
    //   let value2 = value.all.comparable_info[1]
    //   mySetFieldValue("c2Street", value2.address);
    //   mySetFieldValue("c2City", value2.city);
    //   mySetFieldValue("c2State", value2.state);
    //   mySetFieldValue('c2Zip',value2.zip)
    //   mySetFieldValue('c2FBath',value2.bathrooms)
    //   mySetFieldValue('c2HBath',value2.half_baths)
    //   mySetFieldValue('c2BedRooms',value2.bedrooms)
    //   mySetFieldValue('c2DateClose',value2.date_closed)
    //   mySetFieldValue('c2Price',value2.sales_price)
    //   mySetFieldValue('c2Source',value2.data_source)
    //   mySetFieldValue('c2Status',value2.status)
    //   mySetFieldValue('c2SiteSize',value2.site_size)
    //   mySetFieldValue('c2View',value2.view)
    //   mySetFieldValue('c2Floor',value2.floor_height)
    //   mySetFieldValue('c2Age',value2.actual_age)
    //   mySetFieldValue('c2Condition',value2.condition)
    //   mySetFieldValue('c2Area',value2.living_area_under_air)
    //   mySetFieldValue('c2Basement',value2.basement)
    //   mySetFieldValue('c2Garage',value2.garage_carport)
    //   mySetFieldValue('c2Deck',value2.pool_deck_porch_patio)
    //   mySetFieldValue('c2Additional',value2.additional_info)
    //
    //   let value3 = value.all.comparable_info[2]
    //   mySetFieldValue("c3Street", value3.address);
    //   mySetFieldValue("c3City", value3.city);
    //   mySetFieldValue("c3State", value3.state);
    //   mySetFieldValue('c3Zip',value3.zip)
    //   mySetFieldValue('c3FBath',value3.bathrooms)
    //   mySetFieldValue('c3HBath',value3.half_baths)
    //   mySetFieldValue('c3BedRooms',value3.bedrooms)
    //   mySetFieldValue('c3DateClose',value3.date_closed)
    //   mySetFieldValue('c3Price',value3.sales_price)
    //   mySetFieldValue('c3Source',value3.data_source)
    //   mySetFieldValue('c3Status',value3.status)
    //   mySetFieldValue('c3SiteSize',value3.site_size)
    //   mySetFieldValue('c3View',value3.view)
    //   mySetFieldValue('c3Floor',value3.floor_height)
    //   mySetFieldValue('c3Age',value3.actual_age)
    //   mySetFieldValue('c3Area',value3.living_area_under_air)
    //   mySetFieldValue('c3Basement',value3.basement)
    //   mySetFieldValue('c3Garage',value3.garage_carport)
    //   mySetFieldValue('c3Deck',value3.pool_deck_porch_patio)
    //   mySetFieldValue('c3Additional',value3.additional_info)
    //  mySetFieldValue('c3Condition',value3.condition)
    //


    navigate(`/pages/comparables/${value.key}`);

  };


  // ! useefect for new id data

  // useEffect(()=>{
  //   if(id){
  //       axios.get(`/properties/prop/${id}/`).then((res)=>{
  //         console.log(res , "res new")
  //       })
  //   }
  // },[id])
  const [sStatus, setsStatus] = React.useState();
  const [c1Status, setc1Status] = React.useState();
  const [c2Status, setc2Status] = React.useState();
  const [c3Status, setc3Status] = React.useState();
  const handleStatusSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sStatus' ? mySetFieldValue('sStatus',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1Status' ? mySetFieldValue('c1Status',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2Status' ? mySetFieldValue('c2Status',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3Status' ? mySetFieldValue('c3Status',event.target.value): '';
  };

  const [sView, setsView] = React.useState();
  const [c1View, setc1View] = React.useState();
  const [c2View, setc2View] = React.useState();
  const [c3View, setc3View] = React.useState();
  const handleViewSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sView' ? mySetFieldValue('sView' ,event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1View' ? mySetFieldValue('c1View' ,event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2View' ? mySetFieldValue('c2View' ,event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3View' ? mySetFieldValue('c3View' ,event.target.value): '';
  };

  const [sCondition, setsCondition] = React.useState();
  const [c1Condition, setc1Condition] = React.useState();
  const [c2Condition, setc2Condition] = React.useState();
  const [c3Condition, setc3Condition] = React.useState();
  const handleConditionSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sCondition' ? mySetFieldValue('sCondition',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1Condition' ? mySetFieldValue('c1Condition',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2Condition' ? mySetFieldValue('c2Condition',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3Condition' ? mySetFieldValue('c3Condition',event.target.value): '';
  };

  const [sBasement, setsBasement] = React.useState();
  const [c1Basement, setc1Basement] = React.useState();
  const [c2Basement, setc2Basement] = React.useState();
  const [c3Basement, setc3Basement] = React.useState();
  const handleBasementSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sBasement' ? mySetFieldValue('sBasement',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1Basement' ? mySetFieldValue('c1Basement',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2Basement' ? mySetFieldValue('c2Basement',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3Basement' ? mySetFieldValue('c3Basement',event.target.value): '';
  };

  const [sDeck, setsDeck] = React.useState();
  const [c1Deck, setc1Deck] = React.useState();
  const [c2Deck, setc2Deck] = React.useState();
  const [c3Deck, setc3Deck] = React.useState();
  const handleDeckSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sDeck' ? mySetFieldValue('sDeck',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1Deck' ? mySetFieldValue('c1Deck',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2Deck' ? mySetFieldValue('c2Deck',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3Deck' ? mySetFieldValue('c3Deck',event.target.value): '';
  };

  const carportGarage = [
    'None',
    '1 Car Garage',
    '2 Car Garage',
    '3 Car Garage',
    '4 Car Garage',
    '5 Car Garage',
    '6 Car Garage',
    '1 Car Carport',
    '2 Car Carport',
    '3 Car Carport',
    '4 Car Carport'
  ];
  const [sGarage, setsGarage] = React.useState();
  const [c1Garage, setc1Garage] = React.useState();
  const [c2Garage, setc2Garage] = React.useState();
  const [c3Garage, setc3Garage] = React.useState();
  const handleGarageSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sGarage' ? mySetFieldValue('sGarage' , event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1Garage' ? mySetFieldValue('c1Garage',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2Garage' ? mySetFieldValue('c2Garage',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3Garage' ? mySetFieldValue('c3Garage',event.target.value): '';
  };

  const [sHBath, setsHBath] = React.useState();
  const [c1HBath, setc1HBath] = React.useState();
  const [c2HBath, setc2HBath] = React.useState();
  const [c3HBath, setc3HBath] = React.useState();
  const handleHBathSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sHBath' ? mySetFieldValue("sHBath",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1HBath' ? mySetFieldValue("c1HBath",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2HBath' ? mySetFieldValue("c2HBath",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3HBath' ? mySetFieldValue("c3HBath",event.target.value): '';
  };


  const [sFBath, setsFBath] = React.useState();
  const [c1FBath, setc1FBath] = React.useState();
  const [c2FBath, setc2FBath] = React.useState();
  const [c3FBath, setc3FBath] = React.useState();
  const handleFBathSelectChange = (event) => {
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sFBath' ? mySetFieldValue("sFBath",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1FBath' ? mySetFieldValue("c1FBath",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2FBath' ? mySetFieldValue("c2FBath",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3FBath' ? mySetFieldValue("c3FBath",event.target.value): '';
  };


  const [sBedRooms, setsBedRooms] = React.useState();
  const [c1BedRooms, setc1BedRooms] = React.useState();
  const [c2BedRooms, setc2BedRooms] = React.useState();
  const [c3BedRooms, setc3BedRooms] = React.useState();
  const handleBedRoomsSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sBedRooms' ? mySetFieldValue("sBedRooms",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1BedRooms' ? mySetFieldValue("c1BedRooms",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2BedRooms' ? mySetFieldValue("c2BedRooms",event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3BedRooms' ? mySetFieldValue("c3BedRooms",event.target.value): '';
  };


  const [sFloor, setsFloor] = React.useState();
  const [c1Floor, setc1Floor] = React.useState();
  const [c2Floor, setc2Floor] = React.useState();
  const [c3Floor, setc3Floor] = React.useState();
  const handleFloorSelectChange = (event) => {

    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'sFloor' ? mySetFieldValue('sFloor',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c1Floor' ? mySetFieldValue('c1Floor',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c2Floor' ? mySetFieldValue('c2Floor',event.target.value): '';
    // eslint-disable-next-line no-unused-expressions
    event.target.name === 'c3Floor' ? mySetFieldValue('c3Floor',event.target.value): '';
  };


  let convertAge = (sAge) => {
    let currentYear = new Date().getFullYear();
    let numLen = sAge?.toString().length
    // console.log(currentYear,numLen);
    if (numLen >= 4 && sAge <= currentYear){
      return currentYear - sAge;

    }
  }
  useEffect(() => {

  }, []);
  const useStyles = makeStyles({
    select: {
      "& ul": {
        backgroundColor: "#F2321f",
      },
      "& li": {
        fontSize: 12,
        color:"#F2321f"
      },
    },
  });
  const classes = useStyles();

  const theme = createTheme({
    typography: {
      TextField: {
        fontSize: '1rem',
        color:"#F2321f"

      },
    },
  });


  // console.log(c1Proximity , c2Proximity , c3Proximity , 'c1proxy')
  const [openDialog ,setOpenDialog]=useState(false)



  // calculate  proximity while user fill inputs
  // fp === for Proximity (all argumans for proximity)

  const [c1StreetFp , setC1StreetFp]=useState('')
  const [c1CityFp , setC1CityFp]=useState('')
  const [c1ZipFp , setC1ZipFp]=useState('')

  const [c2StreetFp , setC2StreetFp]=useState('')
  const [c2CityFp , setC2CityFp]=useState('')
  const [c2ZipFp , setC2ZipFp]=useState('')


  const [c3StreetFp , setC3StreetFp]=useState('')
  const [c3CityFp , setC3CityFp]=useState('')
  const [c3ZipFp , setC3ZipFp]=useState('')
  // const [c1StateFp , setC1StateFp]=useState('')

  const [mainLat1 , setMainLat1]=useState('0')
  const [mainLng1 , setMainLng1]=useState('0')

  const [newLat1 , setNewLat1]=useState('0')
  const [newLng1 , setNewLng1]=useState('0')

  const [newLat2 , setNewLat2]=useState('0')
  const [newLng2 , setNewLng2]=useState('0')

  const [newLat3 , setNewLat3]=useState('0')
  const [newLng3 , setNewLng3]=useState('0')

  useEffect(()=>{

    if(c1StreetFp==='' || c1CityFp==='' || c1ZipFp===''){
    }else {
      if(address===''||city===''|| zip===''){

      }else {
        axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${address}&city=${city}&zipcode=${zip}`)
          .then((res)=>{
            setMainLat1(res.data.lat.toString())
            setMainLng1(res.data.lng.toString())
          })

        axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${c1StreetFp}&city=${c1CityFp}&zipcode=${c1ZipFp}`)
          .then((res)=>{
            setNewLat1(res.data.lat.toString())
            setNewLng1(res.data.lng.toString())
          })



      }

    }
  },[c1StreetFp  , c1CityFp ,c1ZipFp])


  useEffect(()=>{

    if(c2StreetFp==='' || c2CityFp==='' || c2ZipFp===''){
    }else {
      if(address===''||city===''|| zip===''){

      }else {
        axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${address}&city=${city}&zipcode=${zip}`)
          .then((res)=>{
            setMainLat1(res.data.lat.toString())
            setMainLng1(res.data.lng.toString())
          })

        axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${c2StreetFp}&city=${c2CityFp}&zipcode=${c2ZipFp}`)
          .then((res)=>{
            setNewLat2(res.data.lat.toString())
            setNewLng2(res.data.lng.toString())
          })



      }

    }
  },[c2StreetFp  , c2CityFp ,c2ZipFp])

  useEffect(()=>{

    if(c3StreetFp==='' || c3CityFp==='' || c3ZipFp===''){
    }else {
      if(address===''||city===''|| zip===''){

      }else {
        axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${address}&city=${city}&zipcode=${zip}`)
          .then((res)=>{
            setMainLat1(res.data.lat.toString())
            setMainLng1(res.data.lng.toString())
          })

        axios.get(`https://thecompgenie.com/api/comparables/geolocation?street=${c3StreetFp}&city=${c3CityFp}&zipcode=${c3ZipFp}`)
          .then((res)=>{
            setNewLat3(res.data.lat.toString())
            setNewLng3(res.data.lng.toString())
          })



      }

    }
  },[c3StreetFp  , c3CityFp ,c3ZipFp])

  let distanceC1 = ''
  let distanceC2 = ''
  let distanceC3 = ''
  if(mainLng1==='0'|| mainLng1==='0' || newLng1==='0' || newLat1==='0'){

  }else {
    distanceC1=getDistanceFromLatLonInKm(mainLat1,mainLng1 , newLat1 , newLng1)
  }
  useEffect(()=>{
    if(distanceC1 !=''){
      setC1proximity(distanceC1)
    }
  },[getDistanceFromLatLonInKm])

  if(mainLng1==='0'|| mainLng1==='0' || newLng2==='0' || newLat2==='0'){

  }else {
    distanceC2=getDistanceFromLatLonInKm(mainLat1,mainLng1 , newLat2 , newLng2)
  }
  useEffect(()=>{
    if(distanceC2 !=''){
      setC2proximity(distanceC2)
    }
  },[getDistanceFromLatLonInKm])


  if(mainLng1==='0'|| mainLng1==='0' || newLng3==='0' || newLat3==='0'){

  }else {
    distanceC3=getDistanceFromLatLonInKm(mainLat1,mainLng1 , newLat3 , newLng3)
  }
  useEffect(()=>{
    if(distanceC3 !=''){
      setC3proximity(distanceC3)
    }
  },[getDistanceFromLatLonInKm])

  function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    let R = 6371; // Radius of the earth in km
    let dLat = deg2rad(lat2-lat1);  // deg2rad below
    let dLon = deg2rad(lon2-lon1);
    let a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  return (
    <Card id={'parrentDiv'}>


      <CardHeader
        sx={{ backgroundColor: blue[800], color: "white" }}
        title="Comparables"
      >
      </CardHeader>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
          mySetFieldValue = setFieldValue,
            <form onVlidate onSubmit={handleSubmit}>
              <CardContent>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>

                    <div className="background-select">
                      <div className="equal fields">
                        <div className="field selected">
                          <Select
                            onClick={getProperties}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            options={rows !== [] ? rows : setRows(['a'])}
                            value={rows.filter(function(option) {
                              return option.value === valueselect;
                            })}
                            placeholder="Select property:"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={handleChangeMenu}
                            loading={loading}

                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid  item xs={12} md={12} lg={12}>

                      <label htmlFor="contained-button-file" onClick={()=>setOpenDialog(true)}>
                        <Button variant="contained" component="span" endIcon={<PhotoCamera style={{color:"white"}}/>}>
                          UPLOAD LOGO
                        </Button>
                      </label>


                  </Grid>
                  <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
                    <DialogTitle>
                      Change Image
                    </DialogTitle>
                    <DialogContent>
                      {/*<Grid container sx={{ textAlign: "center" }}>*/}
                      {/*    <Card>*/}

                      {/*    </Card>*/}
                      {/*</Grid>*/}
                      {/*<UploadProfileAvatar handleClose={()=>setOpenDialog(false)}/>*/}
                      <UploadProfileLogo handleClose={()=>setOpenDialog(false)}/>
                    </DialogContent>
                      <div style={{width:'30%' , height:'30px'}}>

                      </div>
                  </Dialog>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box
                      sx={{
                        width: "inherit",
                        height: "auto",
                        borderRadius: 2,
                        marginTop: 5,
                        marginBottom: 5,
                        backgroundColor: 'primary.dark',
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={3} md={3} lg={3}>
                          <Typography color={"white"} align={"center"} variant="h5">
                            Subject
                            <FormControlLabel
                              sx={{
                                display: "block"
                              }}
                              control={
                                <Switch
                                  checked={useLookup}
                                  onChange={() => {onLookupSwitchChanged()}}
                                  name="loading"
                                  color="primary"
                                />
                              }
                              label="Use lookup data"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                          <Typography color={"white"} align={"center"} variant="h5">
                            Comp One
                            <FormControlLabel
                              sx={{
                                display: "block"
                              }}
                              control={
                                <Switch
                                  checked={useCompLookup}
                                  onChange={() => {onCompLookupSwitchChanged('1')}}
                                  name="loading"
                                  color="primary"
                                />
                              }
                              label="Use lookup data"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                          <Typography color={"white"} align={"center"} variant="h5">
                            Comp Two
                            <FormControlLabel
                              sx={{
                                display: "block"
                              }}
                              control={
                                <Switch
                                  checked={useComp2Lookup}
                                  onChange={() => {onCompTwoLookupSwitchChanged()}}
                                  name="loading"
                                  color="primary"
                                />
                              }
                              label="Use lookup data"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                          <Typography color={"white"} align={"center"} variant="h5">
                            Comp Three
                            <FormControlLabel
                              sx={{
                                display: "block"
                              }}
                              control={
                                <Switch
                                  checked={useComp3Lookup}
                                  onChange={() => {onCompThreeLookupSwitchChanged()}}
                                  name="loading"
                                  color="primary"
                                />
                              }
                              label="Use lookup data"
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>


                  <Grid item xs={3} md={3} lg={3}>
                    <Grid container direction={"column"} spacing={5} >
                      {/*<Grid item xs={12} md={6} lg={3}>*/}
                      {/*  <Details imageSrc={uploadImage.default}/>*/}
                      {/*</Grid>*/}
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{boxShadow:1
                          }}
                          label="Street"
                          fullwidth
                          value={values.sStreet}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            handleChange(e);
                          }}

                          name="sStreet"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="City"
                          fullwidth
                          value={values.sCity}
                          onChange={(e) => {
                            setCity(e.target.value);
                            handleChange(e);
                          }}
                          name="sCity"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="State"
                          fullwidth
                          value={values.sState}
                          onChange={(e) => {
                            setState(e.target.value);
                            handleChange(e);
                          }}
                          name="sState"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />

                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Zip"
                          fullwidth
                          value={values.sZip}
                          onChange={(e) => {
                            setZip(e.target.value);
                            handleChange(e);
                          }}
                          name="sZip"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <div style={{width:"100%" , height:"70px" , paddingLeft:"20px", paddingTop:"20px" , display:"flex", flexDirection:"row", alignItems:"center" , justifyContent:"space-between"}}>
                      </div>


                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          label="Sales Price"
                          fullwidth
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="sPrice"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth
                        >
                          <DatePicker
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            fullwidth
                            label="Date Close"
                            value={values.sDateClose === undefined || values.sDateClose === null ? null : typeof values.sDateClose ==="string" ? JSON.parse(values.sDateClose) : null}
                            name='sDateClose'
                            onBlur={handleBlur}
                            onChange={(newValue) => {
                              mySetFieldValue("sDateClose",JSON.stringify(newValue))
                            }}
                            toolbarPlaceholder={'mm-dd-yyyy'}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Data Source (MLS#)"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="sSource"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>

                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Status"
                          fullwidth
                          select
                          value={sStatus}
                          onChange={handleStatusSelectChange}
                          name="sStatus"
                          fullWidth
                          MenuProps={{ classes: { paper: classes.select } }}
                          theme={theme}

                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                          {/* <MenuItem value="unknown">None </MenuItem>*/}
                          {/* <MenuItem value="Closed">Closed </MenuItem>*/}
                          {/* <MenuItem value="Active">Active </MenuItem>*/}
                          {/* <MenuItem value="Pending">Pending </MenuItem>*/}


                          <MenuItem  value="unknown">None</MenuItem>
                          <MenuItem value="Closed">Closed</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Pending">Pending</MenuItem>
                        </TextField>

                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Site Size"
                          fullwidth
                          value={values.sSiteSize}

                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="sSiteSize"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="View"
                          fullwidth
                          select
                          value={sView}
                          onChange={handleViewSelectChange}
                          name="sView"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Water">Water </MenuItem>
                           <MenuItem value="Woods">Woods </MenuItem>
                           <MenuItem value="Park">Park </MenuItem>
                           <MenuItem value="Golf Course">Golf Course </MenuItem>
                           <MenuItem value="City/Skyline">City/Skyline </MenuItem>
                           <MenuItem value="Mountain">Mountain </MenuItem>
                           <MenuItem value="Residential">Residential </MenuItem>
                           <MenuItem value="City Street">City Street </MenuItem>
                           <MenuItem value="Industrial">Industrial </MenuItem>
                           <MenuItem value="Power Lines">Power Lines </MenuItem>
                           <MenuItem value="Limited Sight">Limited Sight </MenuItem>
                           <MenuItem value="Other">Other </MenuItem>

                        </TextField>

                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Floor Height"
                          fullwidth
                          value={parseInt(values.sFloor)}
                          select
                          onChange={handleFloorSelectChange}
                          name="sFloor"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                          label="Actual Age"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          value={convertAge(values.sAge)}

                          name="sAge"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Condition"
                          fullwidth
                          select
                          value={sCondition}
                          onChange={handleConditionSelectChange}
                          name="sCondition"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                          <MenuItem value="unknown">None</MenuItem>

                          <MenuItem value="Complete disaster">Complete disaster</MenuItem>
                          <MenuItem value="Original but needs work">Original but needs work</MenuItem>
                          <MenuItem value="Original but well kept">Original but well kept</MenuItem>
                          <MenuItem value="Some updates/renovation">Some updates/renovation</MenuItem>
                          <MenuItem value="Substantial remodel">Substantial remodel</MenuItem>
                          <MenuItem value="Rebuilt as new or new construction">
                            Rebuilt as new or new construction
                          </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="BedRooms"
                          fullwidth
                          value={parseInt(values.sBedRooms)}
                          select
                          onChange={handleBedRoomsSelectChange}
                          name="sBedRooms"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Full Bath(s)"
                          fullwidth
                          value={parseInt(values.sFBath)}
                          select
                          onChange={handleFBathSelectChange}
                          name="sFBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Half Bath(s)"
                          fullwidth
                          value={parseInt(values.sHBath)}
                          select
                          onChange={handleHBathSelectChange}
                          name="sHBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Living Area Under Air"
                          fullwidth
                          value={values.sArea}

                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="sArea"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Basement"
                          fullwidth
                          select
                          value={sBasement}
                          onChange={handleBasementSelectChange}
                          name="sBasement"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>
                           <MenuItem value="Yes">Yes </MenuItem>
                           <MenuItem value="No">No </MenuItem>
                           <MenuItem value="Partial">Partial </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Garage/Carport"
                          fullwidth
                          select
                          value={sGarage}
                          onChange={handleGarageSelectChange}
                          name="sGarage"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                          {carportGarage.map((item) => (
                             <MenuItem value={item} >
                              {item}
                             </MenuItem>
                          ))}

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Pool, Patio, Porch, Deck"
                          fullwidth
                          select
                          value={sDeck}
                          onChange={handleDeckSelectChange}
                          name="sDeck"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Pool">Pool </MenuItem>
                           <MenuItem value="Patio">Patio </MenuItem>
                           <MenuItem value="Porch">Porch </MenuItem>
                           <MenuItem value="Deck">Deck </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Additional Info"
                          fullwidth
                          name="sAdditional"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          onChange={handleChange}
                        />
                      </Grid>

                    </Grid>

                  </Grid>


                  <Grid item xs={3} md={3} lg={3}>
                    <Grid container direction={"column"} spacing={5}>
                      {/*<Grid item xs={12} md={6} lg={3}>*/}
                      {/*  <Details imageSrc={uploadImage.default}/>*/}
                      {/*</Grid>*/}
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Street"
                          fullwidth
                          value={values.c1Street}

                          onChange={(e) => {
                            handleChange(e);
                            setC1StreetFp(e.target.value)

                          }}
                          name="c1Street"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="City"
                          fullwidth
                          value={values.c1City}
                          onChange={(e) => {
                            handleChange(e);
                            setC1CityFp(e.target.value)
                          }}
                          name="c1City"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="State"
                          fullwidth
                          value={values.c1State}

                          onChange={(e) => {
                            handleChange(e);
                            // setC1StateFp(e.target.value)

                          }}
                          name="c1State"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Zip"
                          fullwidth
                          value={values.c1Zip}

                          onChange={(e) => {
                            handleChange(e);
                            setC1ZipFp(e.target.value)
                          }}
                          name="c1Zip"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <div style={{width:"100%" , height:"70px" , paddingLeft:"20px", paddingTop:"20px" , display:"flex", flexDirection:"row", alignItems:"center" , justifyContent:"space-between"}}>
                        <div style={{marginLeft:"20px"}}>
                          Proximity
                        </div>
                        <div style={{marginRight:"20px"}}>
                          {c1Proximity===undefined || c1Proximity===''||  c1Proximity===null?'': parseFloat(c1Proximity).toFixed(3)+' mi'}
                        </div>
                      </div>

                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          label="Sales Price"
                          value={values.c1Price}
                          fullwidth
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="c1Price"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth
                        >
                          <DatePicker
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            fullwidth
                            label="Date Close"
                            value={values.c1DateClose === undefined || values.c1DateClose === null ? null : typeof values.c1DateClose ==="string" ? JSON.parse(values.c1DateClose) : null}
                            name='c1DateClose'
                            onBlur={handleBlur}
                            onChange={(newValue) => {
                              mySetFieldValue("c1DateClose",JSON.stringify(newValue))
                            }}
                            toolbarPlaceholder={'mm-dd-yyyy'}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Data Source (MLS#)"
                          fullwidth
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="c1Source"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Status"
                          fullwidth
                          select
                          value={c1Status}
                          onChange={handleStatusSelectChange}
                          name="c1Status"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Closed">Closed </MenuItem>
                           <MenuItem value="Active">Active </MenuItem>
                           <MenuItem value="Pending">Pending </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Site Size"
                          fullwidth
                          value={values.c1SiteSize}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="c1SiteSize"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="View"
                          fullwidth
                          select
                          value={c1View}
                          onChange={handleViewSelectChange}
                          name="c1View"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Water">Water </MenuItem>
                           <MenuItem value="Woods">Woods </MenuItem>
                           <MenuItem value="Park">Park </MenuItem>
                           <MenuItem value="Golf Course">Golf Course </MenuItem>
                           <MenuItem value="City/Skyline">City/Skyline </MenuItem>
                           <MenuItem value="Mountain">Mountain </MenuItem>
                           <MenuItem value="Residential">Residential </MenuItem>
                           <MenuItem value="City Street">City Street </MenuItem>
                           <MenuItem value="Industrial">Industrial </MenuItem>
                           <MenuItem value="Power Lines">Power Lines </MenuItem>
                           <MenuItem value="Limited Sight">Limited Sight </MenuItem>
                           <MenuItem value="Other">Other </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Floor Height"
                          fullwidth
                          value={parseInt(values.c1Floor)}
                          select
                          onChange={handleFloorSelectChange}
                          name="c1Floor"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                          label="Actual Age"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          value={convertAge(values.c1Age)}
                          name="c1Age"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Condition"
                          fullwidth
                          select
                          value={c1Condition}
                          onChange={handleConditionSelectChange}
                          name="c1Condition"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Complete disaster">Complete disaster </MenuItem>
                           <MenuItem value="Original but needs work">Original but needs work </MenuItem>
                           <MenuItem value="Original but well kept">Original but well kept </MenuItem>
                           <MenuItem value="Some updates/renovation">Some updates/renovation </MenuItem>
                           <MenuItem value="Substantial remodel">Substantial remodel </MenuItem>
                           <MenuItem value="Rebuilt as new or new construction">
                            Rebuilt as new or new construction
                           </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="BedRooms"
                          fullwidth
                          value={parseInt(values.c1BedRooms)}
                          select
                          onChange={handleBedRoomsSelectChange}
                          name="c1BedRooms"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Full Bath(s)"
                          fullwidth
                          value={parseInt(values.c1FBath)}
                          select
                          onChange={handleFBathSelectChange}
                          name="c1FBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Half Bath(s)"
                          fullwidth
                          value={parseInt(values.c1HBath)}
                          select
                          onChange={handleHBathSelectChange}
                          name="c1HBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Living Area Under Air"
                          fullwidth
                          value={values.c1Area}
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c1Area"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Basement"
                          fullwidth
                          select
                          value={c1Basement}
                          onChange={handleBasementSelectChange}
                          name="c1Basement"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Yes">Yes </MenuItem>
                           <MenuItem value="No">No </MenuItem>
                           <MenuItem value="Partial">Partial </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Garage/Carport"
                          fullwidth
                          select
                          value={c1Garage}
                          onChange={handleGarageSelectChange}
                          name="c1Garage"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                          {carportGarage.map((item) => (
                             <MenuItem value={item} >
                              {item}
                             </MenuItem>
                          ))}

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Pool, Patio, Porch, Deck"
                          fullwidth
                          select
                          value={c1Deck}
                          onChange={handleDeckSelectChange}
                          name="c1Deck"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Pool">Pool </MenuItem>
                           <MenuItem value="Patio">Patio </MenuItem>
                           <MenuItem value="Porch">Porch </MenuItem>
                           <MenuItem value="Deck">Deck </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Additional Info"
                          fullwidth
                          name="c1Additional"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          onChange={handleChange}
                        />
                      </Grid>

                    </Grid>

                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Grid container direction={"column"} spacing={5}>
                      {/*<Grid item xs={12} md={6} lg={3}>*/}
                      {/*  <Details imageSrc={uploadImage.default}/>*/}
                      {/*</Grid>*/}
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Street"
                          fullwidth
                          value={values.c2Street}

                          onChange={(e) => {
                            setC2StreetFp(e.target.value)
                            handleChange(e);
                          }}
                          name="c2Street"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="City"
                          fullwidth
                          value={values.c2City}
                          onChange={(e) => {
                            setC2CityFp(e.target.value)
                            handleChange(e);
                          }}
                          name="c2City"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="State"
                          fullwidth
                          value={values.c2State}

                          onChange={(e) => {
                            // setState(e.target.value);
                            handleChange(e);
                          }}
                          name="c2State"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Zip"
                          fullwidth
                          value={values.c2Zip}

                          onChange={(e) => {
                            setC2ZipFp(e.target.value)
                            handleChange(e);
                          }}
                          name="c2Zip"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>

                      <div style={{width:"100%" , height:"70px" , paddingLeft:"20px", paddingTop:"20px" , display:"flex", flexDirection:"row", alignItems:"center" , justifyContent:"space-between"}}>
                        <div style={{marginLeft:"20px"}}>
                          Proximity
                        </div>
                        <div style={{marginRight:"20px"}}>
                          {c2Proximity===undefined || c2Proximity===''  || c2Proximity===null?'': parseFloat(c2Proximity).toFixed(3)+' mi'}

                        </div>
                      </div>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          label="Sales Price"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c2Price"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth
                        >
                          <DatePicker
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            fullwidth
                            label="Date Close"
                            value={values.c2DateClose === undefined || values.c2DateClose === null ? null : typeof values.c2DateClose ==="string" ? JSON.parse(values.c2DateClose) : null}
                            name='c2DateClose'
                            onBlur={handleBlur}
                            onChange={(newValue) => {
                              mySetFieldValue("c2DateClose",JSON.stringify(newValue))
                            }}
                            toolbarPlaceholder={'mm-dd-yyyy'}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Data Source (MLS#)"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c2Source"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Status"
                          fullwidth
                          select
                          value={c2Status}
                          onChange={handleStatusSelectChange}
                          name="c2Status"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Closed">Closed </MenuItem>
                           <MenuItem value="Active">Active </MenuItem>
                           <MenuItem value="Pending">Pending </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Site Size"
                          fullwidth
                          value={values.c2SiteSize}
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c2SiteSize"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="View"
                          fullwidth
                          select
                          value={c2View}
                          onChange={handleViewSelectChange}
                          name="c2View"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Water">Water </MenuItem>
                           <MenuItem value="Woods">Woods </MenuItem>
                           <MenuItem value="Park">Park </MenuItem>
                           <MenuItem value="Golf Course">Golf Course </MenuItem>
                           <MenuItem value="City/Skyline">City/Skyline </MenuItem>
                           <MenuItem value="Mountain">Mountain </MenuItem>
                           <MenuItem value="Residential">Residential </MenuItem>
                           <MenuItem value="City Street">City Street </MenuItem>
                           <MenuItem value="Industrial">Industrial </MenuItem>
                           <MenuItem value="Power Lines">Power Lines </MenuItem>
                           <MenuItem value="Limited Sight">Limited Sight </MenuItem>
                           <MenuItem value="Other">Other </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Floor Height"
                          fullwidth
                          value={parseInt(values.c2Floor)}
                          select
                          onChange={handleFloorSelectChange}
                          name="c2Floor"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                          label="Actual Age"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          value={convertAge(values.c2Age)}
                          name="c2Age"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Condition"
                          fullwidth
                          select
                          value={c2Condition}
                          onChange={handleConditionSelectChange}
                          name="c2Condition"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Complete disaster">Complete disaster </MenuItem>
                           <MenuItem value="Original but needs work">Original but needs work </MenuItem>
                           <MenuItem value="Original but well kept">Original but well kept </MenuItem>
                           <MenuItem value="Some updates/renovation">Some updates/renovation </MenuItem>
                           <MenuItem value="Substantial remodel">Substantial remodel </MenuItem>
                           <MenuItem value="Rebuilt as new or new construction">
                            Rebuilt as new or new construction
                           </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="BedRooms"
                          fullwidth
                          value={parseInt(values.c2BedRooms)}
                          select
                          onChange={handleBedRoomsSelectChange}
                          name="c2BedRooms"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Full Bath(s)"
                          fullwidth
                          value={parseInt(values.c2FBath)}
                          select
                          onChange={handleFBathSelectChange}
                          name="c2FBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Half Bath(s)"
                          fullwidth
                          value={parseInt(values.c2HBath)}
                          select
                          onChange={handleHBathSelectChange}
                          name="c2HBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Living Area Under Air"
                          fullwidth
                          value={values.c2Area}
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c2Area"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Basement"
                          fullwidth
                          select
                          value={c2Basement}
                          onChange={handleBasementSelectChange}
                          name="c2Basement"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Yes">Yes </MenuItem>
                           <MenuItem value="No">No </MenuItem>
                           <MenuItem value="Partial">Partial </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Garage/Carport"
                          fullwidth
                          select
                          value={c2Garage}
                          onChange={handleGarageSelectChange}
                          name="c2Garage"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                          {carportGarage.map((item) => (
                             <MenuItem value={item} >
                              {item}
                             </MenuItem>
                          ))}

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Pool, Patio, Porch, Deck"
                          fullwidth
                          select
                          value={c2Deck}
                          onChange={handleDeckSelectChange}
                          name="c2Deck"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Pool">Pool </MenuItem>
                           <MenuItem value="Patio">Patio </MenuItem>
                           <MenuItem value="Porch">Porch </MenuItem>
                           <MenuItem value="Deck">Deck </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Additional Info"
                          fullwidth
                          name="c2Additional"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          onChange={handleChange}
                        />
                      </Grid>

                    </Grid>

                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Grid container direction={"column"} spacing={5}>
                      {/*<Grid item xs={12} md={6} lg={3}>*/}
                      {/*  <Details imageSrc={uploadImage.default}/>*/}
                      {/*</Grid>*/}
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Street"
                          fullwidth
                          value={values.c3Street}

                          onChange={(e) => {

                            handleChange(e);
                            setC3StreetFp(e.target.value)
                          }}
                          name="c3Street"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="City"
                          fullwidth
                          value={values.c3City}
                          onChange={(e) => {

                            handleChange(e);
                            setC3CityFp(e.target.value)
                          }}
                          name="c3City"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="State"
                          fullwidth
                          value={values.c3State}

                          onChange={(e) => {
                            // setState(e.target.value);
                            handleChange(e);
                          }}
                          name="c3State"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Zip"
                          fullwidth
                          value={values.c3Zip}

                          onChange={(e) => {

                            handleChange(e);
                            setC3ZipFp(e.target.value)
                          }}
                          name="c3Zip"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <div style={{width:"100%" , height:"70px" , paddingLeft:"20px", paddingTop:"20px" , display:"flex", flexDirection:"row", alignItems:"center" , justifyContent:"space-between"}}>
                        <div style={{marginLeft:"20px"}}>
                          Proximity
                        </div>
                        <div style={{marginRight:"20px"}}>
                          {c3Proximity===undefined || c3Proximity===''  || c3Proximity===null?'': parseFloat(c3Proximity).toFixed(3)+' mi'}
                        </div>
                      </div>

                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          label="Sales Price"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c3Price"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth
                        >
                          <DatePicker
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            fullwidth
                            label="Date Close"
                            value={values.c3DateClose === undefined || values.c3DateClose === null ? null : typeof values.c3DateClose ==="string" ? JSON.parse(values.c3DateClose) : null}
                            name='c3DateClose'
                            onBlur={handleBlur}
                            onChange={(newValue) => {
                              mySetFieldValue("c3DateClose",JSON.stringify(newValue))
                            }}
                            toolbarPlaceholder={'mm-dd-yyyy'}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Data Source (MLS#)"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c3Source"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Status"
                          fullwidth
                          select
                          value={c3Status}
                          onChange={handleStatusSelectChange}
                          name="c3Status"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Closed">Closed </MenuItem>
                           <MenuItem value="Active">Active </MenuItem>
                           <MenuItem value="Pending">Pending </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Site Size"
                          fullwidth
                          value={values.c3SiteSize}
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c3SiteSize"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="View"
                          fullwidth
                          select
                          value={c3View}
                          onChange={handleViewSelectChange}
                          name="c3View"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Water">Water </MenuItem>
                           <MenuItem value="Woods">Woods </MenuItem>
                           <MenuItem value="Park">Park </MenuItem>
                           <MenuItem value="Golf Course">Golf Course </MenuItem>
                           <MenuItem value="City/Skyline">City/Skyline </MenuItem>
                           <MenuItem value="Mountain">Mountain </MenuItem>
                           <MenuItem value="Residential">Residential </MenuItem>
                           <MenuItem value="City Street">City Street </MenuItem>
                           <MenuItem value="Industrial">Industrial </MenuItem>
                           <MenuItem value="Power Lines">Power Lines </MenuItem>
                           <MenuItem value="Limited Sight">Limited Sight </MenuItem>
                           <MenuItem value="Other">Other </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Floor Height"
                          fullwidth
                          value={parseInt(values.c3Floor)}
                          select
                          onChange={handleFloorSelectChange}
                          name="c3Floor"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                          label="Actual Age"
                          fullwidth
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          value={convertAge(values.c3Age)}
                          name="c3Age"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Condition"
                          fullwidth
                          select
                          value={c3Condition}
                          onChange={handleConditionSelectChange}
                          name="c3Condition"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Complete disaster">Complete disaster </MenuItem>
                           <MenuItem value="Original but needs work">Original but needs work </MenuItem>
                           <MenuItem value="Original but well kept">Original but well kept </MenuItem>
                           <MenuItem value="Some updates/renovation">Some updates/renovation </MenuItem>
                           <MenuItem value="Substantial remodel">Substantial remodel </MenuItem>
                           <MenuItem value="Rebuilt as new or new construction">
                            Rebuilt as new or new construction
                           </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="BedRooms"
                          fullwidth
                          value={parseInt(values.c3BedRooms)}
                          select
                          onChange={handleBedRoomsSelectChange}
                          name="c3BedRooms"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Full Bath(s)"
                          fullwidth
                          value={parseInt(values.c3FBath)}
                          select
                          onChange={handleFBathSelectChange}
                          name="c3FBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // SelectProps={{
                          //   native: true,
                          // }}
                          label="Half Bath(s)"
                          fullwidth
                          value={parseInt(values.c3HBath)}
                          select
                          onChange={handleHBathSelectChange}
                          name="c3HBath"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        >
                          {Array.from({ length: 100 }, (_, i) => i).map((item) => (
                             <MenuItem key={item} value={item}>
                              {item}
                             </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">sq. ft.</InputAdornment>,
                          }}
                          label="Living Area Under Air"
                          fullwidth
                          value={values.c3Area}
                          onChange={(e) => {

                            handleChange(e);
                          }}
                          name="c3Area"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Basement"
                          fullwidth
                          select
                          value={c3Basement}
                          onChange={handleBasementSelectChange}
                          name="c3Basement"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Yes">Yes </MenuItem>
                           <MenuItem value="No">No </MenuItem>
                           <MenuItem value="Partial">Partial </MenuItem>

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>

                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Garage/Carport"
                          fullwidth
                          select
                          value={c3Garage}
                          onChange={handleGarageSelectChange}
                          name="c3Garage"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                          {carportGarage.map((item) => (
                             <MenuItem value={item} >
                              {item}
                             </MenuItem>
                          ))}

                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Pool, Patio, Porch, Deck"
                          fullwidth
                          select
                          value={c3Deck}
                          onChange={handleDeckSelectChange}
                          name="c3Deck"
                          fullWidth
                          // SelectProps={{
                          //   native: true,
                          // }}
                        >
                           <MenuItem value="unknown">None </MenuItem>

                           <MenuItem value="Pool">Pool </MenuItem>
                           <MenuItem value="Patio">Patio </MenuItem>
                           <MenuItem value="Porch">Porch </MenuItem>
                           <MenuItem value="Deck">Deck </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Additional Info"
                          fullwidth
                          name="c3Additional"
                          fullWidth
                          onBlur={handleBlur}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          onChange={handleChange}
                        />
                      </Grid>

                    </Grid>

                  </Grid>




                </Grid>
              </CardContent>

              <CardActions
                  sx={{ direction: "rtl" }}
              >
                <Grid container>
                  <Grid item xs={3} md={3} lg={2} sx={{mr:2}} >
                    <Button fullWidth type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                  <Grid item xs={3} md={3} lg={2} sx={{mr:2}} >
                    <Button fullWidth  variant="contained" color="primary"  component={RouterLink} to={id ?`/pages/property/${id}`  :rowsId===-1?  "/pages/property" : `/pages/property/${rowsId}` }>
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs={3} md={3} lg={2} sx={{mr:2}} >
                    <Button fullWidth  variant="contained" color="primary"  component={RouterLink} to="/">
                      Cancel
                    </Button>
                  </Grid>
                </Grid>


              </CardActions>
            </form>
        )}
      </Formik>
    </Card>
  );


};


export default ComparableGeneralInfo;
