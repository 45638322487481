import React, { useState } from "react";
const PropertyContext = React.createContext({
  propertyInfo: null,
  setPropertyInfo: () => {}
});


const PropertyProvider = ({ children }) => {

  const [propertyInfo, setPropertyInfo] = useState(null);




  return (
    <PropertyContext.Provider value={{
      propertyInfo,
      setPropertyInfo,
    }}>
      {children}
    </PropertyContext.Provider>
  );


};


export { PropertyContext, PropertyProvider };



