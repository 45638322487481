import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pricing } from "../../pages/components/react-pricing";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography
} from "@mui/material";
import { StarBorder as StarIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

function PricingPage() {
  return (
    <React.Fragment >
      <Helmet title="Peyments"/>
      <Typography variant="h3" gutterBottom display="inline">
        Peyments
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Peyments</Typography>
      </Breadcrumbs>

      <Divider my={6}/>

      <Header>
        <Typography variant="h3" gutterBottom align="center">
          This is the Payment Page That Will Be Deployed Soon.
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center">
          Whether you're a business or an individual, 14-day trial no credit
          card required.
        </Typography>
      </Header>

      <Grid container my={10} spacing={10} justifyContent="center">
        <Grid item xs={12} md={6} lg={3}>
          <Grid container spacing={6} alignItems="flex-end">
            <Pricing data={[
              { text: "3 new project / month", value: true },
              { text: "Basic interaction", value: true },
              { text: "Assets library", value: false },
            ]}
                     price={7}
                     duration="m"
                     background=""
                     shadow="#96e6a1"
                     currency="$"
                     buttonContent="Get Started"
                     subTitle="For Planned Projects"
                     priceText="Bring your designs to the next level and export them."
                     headerText="Standard"/>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Grid container spacing={6} alignItems="flex-end">
            <Pricing data={[
              { text: "3 new project / month", value: true },
              { text: "Basic interaction", value: true },
              { text: "Assets library", value: false },
            ]}
                     price={7}
                     duration="m"
                     background=""
                     shadow="#96e6a1"
                     currency="$"
                     buttonContent="Get Started"
                     subTitle="For Planned Projects"
                     priceText="Bring your designs to the next level and export them."
                     headerText="Popular"/>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Grid container spacing={6} alignItems="flex-end">
            <Pricing data={[
              { text: "3 new project / month", value: true },
              { text: "Basic interaction", value: true },
              { text: "Assets library", value: false },
            ]}
                     price={7}
                     duration="m"
                     background=""
                     shadow="#96e6a1"
                     currency="$"
                     buttonContent="Get Started"
                     subTitle="For Planned Projects"
                     priceText="Bring your designs to the next level and export them."
                     headerText="Premium"/>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Grid container spacing={6} alignItems="flex-end">
            <Pricing data={[
              { text: "3 new project / month", value: true },
              { text: "Basic interaction", value: true },
              { text: "Assets library", value: false },
            ]}
                     price={7}
                     duration="m"
                     background=""
                     shadow="#96e6a1"
                     currency="$"
                     buttonContent="Get Started"
                     subTitle="For Planned Projects"
                     priceText="Bring your designs to the next level and export them."
                     headerText="Supreme"/>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PricingPage;
