import axios from "axios";

let axiosConfig = {
  baseURL: "http://localhost:8000/api",
};

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   axiosConfig={...axiosConfig}
// } else {
//   axiosConfig={...axiosConfig, baseURL: "https://thecompgenie.com/api"}
//   // production code
// }

axiosConfig={...axiosConfig, baseURL: "https://thecompgenie.com/api"}
// axiosConfig={...axiosConfig, baseURL: "http://94.101.134.204:7000/api"}

// axiosConfig={...axiosConfig, baseURL: "http://localhost:8000/api"}

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // const resp = ;
    // console.log(resp.data);
    if (error.response) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject({ message: "Something went wrong" });
    }
  }
);

export default axiosInstance;
