import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import { PropertyContext } from "../../../../contexts/PropertyContext";
import axios from "../../../../utils/axios";
import useSnackbar from "../../../../hooks/useSnackbar";
import YesNoDialog from "../../../../components/dialogs/YesNoDialog";

const UploadedImagePreview = ({ image, index }) => {

  const [open, setOpen] = useState(false);
  const {alert} = useSnackbar();
  const handleClose = () => setOpen(false);
  const { propertyInfo, setPropertyInfo } = useContext(PropertyContext);
  const handleDelete = async () => {
    await deleteImage(image, index);
    handleClose();
  };

  const deleteImage = async (image, index) => {
    let images = propertyInfo?.images;
    let im = images.find(e => e.id === image.id);
    try {
      await axios.delete(`/properties/${propertyInfo.id}/images/${im.id}/`);
      let index = images.findIndex(e => e.id === image.id);
      images.splice(index, index + 1);
      setPropertyInfo({ ...propertyInfo, images: images });
      alert({ message: "Image deleted successfully", severity: "success" });
      handleClose();
    } catch (e) {
      alert({ message: e.message, severity: "error" });
      handleClose();
    }
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          padding: 0,
          borderRadius: 6,
          width: "100%",
          height: "100%",
          borderColor: "gray",
          position: "relative",
          img : {
            borderRadius: 2,
          }
        }}
      >
        <img alt="image" style={{ width: "100%", height: "100%"}} src={image.image}>
        </img>

        <IconButton
          color="error"
          sx={{ position: "absolute", zIndex: 100, bottom: 5, left: 5, backgroundColor: "white" }}
          aria-label="delete"
          onClick={handleOpen}
        >
          <DeleteIcon/>
        </IconButton>
      </Box>

      <YesNoDialog
        title="Delete image"
        subtitle="Are you sure you want to delete this image?"
        open={open}
        handleYes={() => handleDelete(image, index)}
        handleNo={handleClose}
      />
    </>

  );
};

export default UploadedImagePreview;
