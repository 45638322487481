import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const BoxIcon = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
}));

const SingleImageSelection = ({ isMain, index, addFile }) => {

  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const validateFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.target?.files[0]) {
      return;
    }
    let f = e.target.files[0];
    let err = null;
    let mimeType = f["type"];
    if (mimeType.split("/")[0] !== "image") {
      err = "Please select an image file";
    } else if (f.size === 0 || f.size > 5000000) {
      err = "Maximum allowed file size is 5MB";
    }
    if (!err) {
      // setFile(f);
      // setSelected(true);
    }
    setError(err);

    if (!err) {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (eve) => {
        setImageUrl(eve.target["result"]);
      };
      addFile(index, f, isMain);
    }

  };

  const uploadIcon =
    <BoxIcon>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", gap: 3 }}
           component="label">

        <CloudUploadIcon sx={{ fontSize: "2rem" }} mr={3}/>
        <Typography sx={{ fontWeight: "bold" }}>
          {isMain ? "Main image" : "Gallery image"}
        </Typography>

      </Box>
    </BoxIcon>;

  return (
    <>
      <Button sx={{
        position: "relative",
        overflow: "hidden",
        border: !imageUrl ? "2px dotted" : null,
        padding: !imageUrl && 0,
        borderRadius: "5px",
        width: "100%",
        height: "100%",
        borderColor: "gray"
      }}
              onClick={(e) => e.stopPropagation()}
              component="label"
              onDrop={validateFile}
      >
        <input type="file" style={{ zIndex: 10 }} hidden onChange={e => validateFile(e)} onDrop={validateFile}/>

        {!imageUrl && uploadIcon}

        {imageUrl &&
        (
          <img
            alt="image"
            style={{ width: "100%", height: "100%", zIndex: 50 }}
            src={imageUrl}
          >
          </img>
        )
        }

      </Button>

      {error &&
      <Typography sx={{ textAlign: "center" }} component="span" color="error">
        {error}
      </Typography>
      }
    </>
  );

};

export default SingleImageSelection;
