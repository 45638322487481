import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import axios from "../../utils/axios";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Loader from "../Loader";
import useSnackbar from "../../hooks/useSnackbar";
import useAuth from "../../hooks/useAuth";

const EmailVerificationComponent = () => {

  const { search } = useLocation();
  const { alert } = useSnackbar();
  const navigate = useNavigate();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [verfied, setVerifed] = useState(false);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [secret, setSecret] = useState();
  const { isAuthenticated, isInitialized } = useAuth();

  const verifyEmail = async (s) => {
    try {
      await axios.put("/users/verify/", null, { params: { s } });
      setVerifed(true);
      setLoaded(false);
    } catch (error) {
      const m = error.message || "Something went wrong";
      setError(m);
      alert({ message: m, severity: "error" });
      setVerifed(false);
      setLoaded(false);
    }
  };

  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      navigate("/");
      return;
    }
    const s = query.get("secret");
    if (!s) {
      navigate("/auth/sign-in");
    } else {
      setSecret(query.get("secret"));
      verifyEmail(s);
    }
  }, [isAuthenticated, isInitialized]);

  const verifiedCard = (
    <>

      <Box sx={{ textAlign: "center" }}>
        <Avatar alt="Email verified icon"
                src={verfied ? "/static/img/gifs/emailVerified.gif" : "/static/img/gifs/error-img.gif"}
                sx={{ width: "200px", height: "150px", margin: "auto" }}/>
        {/*<CheckCircleIcon color="success"/>*/}
        <Typography mt={3} mb={2} variant="h3" color="success" style={{ marginBottom: "2.5rem" }}>
          {verfied ? "Email address verified" : error}
        </Typography>

        {
          verfied ?
            (
              <Button color="success" variant="contained" mr={"auto"} href="/">
                Go to dashboard
              </Button>
            )
            :
            (
              <Button color="primary" variant="contained" mr={"auto"} href="/">
                Go to dashboard
              </Button>
            )
        }

      </Box>
    </>
  );

  return (
    <>
      {!loaded && isAuthenticated && isInitialized ? verifiedCard : <Loader/>}
    </>
  );
};

export default EmailVerificationComponent;
