import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import React from "react";
import Button from "@mui/material/Button";

const YesNoDialog = ({ title, subtitle, open, handleClose, handleYes, handleNo, yesBtnColor, noBtnColor }) => {

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h4">
          {title}
        </Typography>

        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" sx={{marginBottom: 6}}>
              {subtitle}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 3 }}>
              <Button
                variant="contained"
                color={yesBtnColor ? yesBtnColor : "success"}
                onClick={handleYes}
              >
                Yes
              </Button>

              <Button
                variant="outlined"
                color={noBtnColor ? noBtnColor : "primary"}
                onClick={handleNo ? handleNo : handleClose}
              >
                No
              </Button>

            </Box>

          </Box>
        </DialogContent>

      </DialogTitle>
    </Dialog>
  );


};

export default YesNoDialog;
