import React, { useState } from "react";
const ComparableContext = React.createContext({
    comparableInfo: null,
  setComparableInfo: () => {}
});


const ComparableProvider = ({ children }) => {

  const [comparableInfo, setComparableInfo] = useState(null);




  return (
    <ComparableContext.Provider value={{
      comparableInfo,
      setComparableInfo,
    }}>
      {children}
    </ComparableContext.Provider>
  );


};


export { ComparableContext, ComparableProvider };



