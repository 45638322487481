import React, { useEffect, useState } from "react";
import './Comp.css'
import { Helmet } from "react-helmet-async";
import Card from "@mui/material/Card";
import blue from "@material-ui/core/colors/blue";
import CardHeader from "@mui/material/CardHeader";
import { Form } from "formik";
import Select from "react-select";
import axios from "../utils/axios";
import makeAnimated from "react-select/animated";
import useAuth from "../hooks/useAuth";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { PhotoCamera } from "@mui/icons-material";
import { CircularProgress, Dialog, Switch } from "@mui/material";
import { DialogTitle } from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import UploadProfileLogo from "../components/profile/UploadProfileLogo";
import SubjectFields from "./SubjectFields";
import CardActions from "@mui/material/CardActions";
import MySwitch from "./MySwitch";

const Comp = ()=>{
  // states :
  const navigate = useNavigate();
  const {id}=useParams()

  const [valueselect, setValueselect] = useState('');
  const [loading, setLoading] = useState(id?true:false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [data ,setData] = useState([])
  const [rows, setRows] = useState([]);
  const [openDialog ,setOpenDialog]=useState(false)
  const [firstTime , setFirstTime]=useState(true)
  const [submit , setSubmit]=useState(0)
  const [beforeSubmit , setBeforeSubmit]=useState(0)
  const [c1Proxi ,setC1Proxi]=useState()
  const [c2Proxi ,setC2Proxi]=useState()
  const [c3Proxi ,setC3Proxi]=useState()

  const [subjectFields , setSubjectFields]=useState({
    street:'',
    city:"",
    state:"",
    zip:"",
    salePrice:"",
    dateClosed:null,
    dataSource:"",
    status:"",
    siteSize:'',
    view:"",
    floorHeight:"",
    actualAge:"",
    condition:"",
    bedRooms:"",
    bathRooms:"",
    halfBath:"",
    livingArea:"",
    basement:"" ,
    garage:"",
    pool:"",
    additional:"",
    lat:null ,
    lng: null
  })
  const [compOneFields , setCompOneFields]=useState({
    street:'',
    city:"",
    state:"",
    zip:"",
    proximity:"",
    salePrice:"",
    dateClosed:null,
    dataSource:"",
    status:"",
    siteSize:'',
    view:"",
    floorHeight:"",
    actualAge:"",
    condition:"",
    bedRooms:"",
    bathRooms:"",
    halfBath:"",
    livingArea:"",
    basement:"" ,
    garage:"",
    pool:"",
    additional:"",
    lat:null ,
    lng: null

  })
  const [compTwoFields , setCompTwoFields]=useState({
    street:'',
    city:"",
    state:"",
    zip:"",
    proximity:"",
    salePrice:"",
    dateClosed:null,
    dataSource:"",
    status:"",
    siteSize:'',
    view:"",
    floorHeight:"",
    actualAge:"",
    condition:"",
    bedRooms:"",
    bathRooms:"",
    halfBath:"",
    livingArea:"",
    basement:"" ,
    garage:"",
    pool:"",
    additional:"",
    lat:null ,
    lng: null
  })
  const [compThreeFields , setCompThreeFields]=useState({
    street:'',
    city:"",
    state:"",
    zip:"",
    proximity:"",
    salePrice:"",
    dateClosed:null,
    dataSource:"",
    status:"",
    siteSize:'',
    view:"",
    floorHeight:"",
    actualAge:"",
    condition:"",
    bedRooms:"",
    bathRooms:"",
    halfBath:"",
    livingArea:"",
    basement:"" ,
    garage:"",
    pool:"",
    additional:"",
    lat:null ,
    lng: null
  })


  // others :
  const { isInitialized, isAuthenticated } = useAuth();

  // functions:

  const handleSubmit = ()=>{
    setSubmit(submit+1)
  }

  const createData = (id, responseData) => {
    return {
      key: id,
      zip: responseData.zipcode,
      value: responseData?.address + "," + responseData?.city + "," + responseData?.state,
      label: responseData?.address + "," + responseData?.city + "," + responseData?.state,
      fBath: responseData?.bathrooms,
      hBath: responseData?.half_baths,
      bed: responseData?.bedrooms,
      all: responseData
    };
  }
  const [rowsId , setRowsId]=useState(-1)
  const handleChangeMenu = (value) => {
    setRowsId(value.key)
    let data = value.value.split(',')
    let moreDetails =value?.all?.more_detail[0]
    let compOne = value?.all?.comparable_info[0]
    let compTwo = value?.all?.comparable_info[1]
    let compThree = value?.all?.comparable_info[2]

    setValueselect(value.value);
    setSubjectFields({
      street:value?.all?.address,
      city:value?.all?.city,
      state:value?.all?.state,
      zip:value?.all?.zipcode,
      salePrice:moreDetails?.sales_price === null || moreDetails?.sales_price === undefined ? "" :moreDetails?.sales_price,
      dateClosed:moreDetails?.date_closed === null || moreDetails?.date_closed === undefined ? null :moreDetails?.date_closed,
      dataSource:moreDetails?.data_source === null || moreDetails?.data_source === undefined ? "" :moreDetails?.data_source,
      status:moreDetails?.status === null || moreDetails?.status === undefined ? null :moreDetails?.status,
      siteSize:moreDetails?.site_size === null || moreDetails?.site_size === undefined ? "" :moreDetails?.site_size,
      view:moreDetails?.view === null || moreDetails?.view === undefined ? null :moreDetails?.view,
      floorHeight:moreDetails?.floor_height === null || moreDetails?.floor_height === undefined ? null :moreDetails?.floor_height,
      actualAge:moreDetails?.actual_age === null || moreDetails?.actual_age === undefined ? "" :moreDetails?.actual_age,
      condition:moreDetails?.condition === null || moreDetails?.condition === undefined ? null :moreDetails?.condition,
      bedRooms:value?.all?.bedrooms,
      bathRooms:value?.all?.bathrooms,
      halfBath:value?.all?.half_baths,
      livingArea:moreDetails?.living_area_under_air === null || moreDetails?.living_area_under_air === undefined ? "" :moreDetails?.living_area_under_air,
      basement:moreDetails?.basement === null || moreDetails?.basement === undefined ? "" :moreDetails?.basement,
      garage:moreDetails?.garage_carport === null || moreDetails?.garage_carport === undefined ? null :moreDetails?.garage_carport,
      pool:moreDetails?.pool_deck_porch_patio === null || moreDetails?.pool_deck_porch_patio === undefined ? null :moreDetails?.pool_deck_porch_patio,
      additional:moreDetails?.additional_info === null || moreDetails?.additional_info === undefined ? "" :moreDetails?.additional_info,
      lat:value?.all?.location_lat,
      lng:value?.all?.location_lng,
    })

    setCompOneFields({
      street: compOne?.address ?  compOne?.address: '',
      city:compOne?.city ? compOne?.city :'',
      state:compOne?.state  ? compOne?.state :'',
      zip:compOne?.zipcode  ? compOne?.zipcode :'',
      proximity: compOne?.proximity  ? compOne?.proximity :null,
      salePrice:compOne?.sales_price  ? compOne?.sales_price :'',
      dateClosed:compOne?.date_closed  ? compOne?.date_closed :null,
      dataSource:compOne?.data_source  ? compOne?.data_source :'',
      status:compOne?.status ? compOne?.status :null,
      siteSize:compOne?.site_size ? compOne?.site_size :'',
      view:compOne?.view ? compOne?.view :null,
      floorHeight:compOne?.floor_height ? compOne?.floor_height :null,
      actualAge:compOne?.actual_age ? compOne?.actual_age :'',
      condition:compOne?.condition ? compOne?.condition :null,
      bedRooms:compOne?.bedrooms ? compOne?.bedrooms :null,
      bathRooms:compOne?.bathrooms ? compOne?.bathrooms :null,
      halfBath:compOne?.half_baths ? compOne?.half_baths :null,
      livingArea:compOne?.living_area_under_air ? compOne?.living_area_under_air :'',
      basement:compOne?.basement ? compOne?.basement :null,
      garage:compOne?.garage_carport ? compOne?.garage_carport :null,
      pool:compOne?.pool_deck_porch_patio ? compOne?.pool_deck_porch_patio :null,
      additional:compOne?.additional_info ? compOne?.additional_info :'',
      lat:compOne?.location_lat ?  compOne?.location_lat :null,
      lng:compOne?.location_lng ?  compOne?.location_lng :null,
    })
    setC1Proxi(compOne?.proximity ? compOne?.proximity :null)
    setC2Proxi(compTwo?.proximity ? compTwo?.proximity :null)
    setC3Proxi(compThree?.proximity ? compThree?.proximity :null)

    setCompTwoFields({
      street: compTwo?.address ? compTwo?.address: '',
      city:compTwo?.city  ? compTwo?.city: '',
      state:compTwo?.state  ? compTwo?.state: '',
      zip:compTwo?.zipcode  ? compTwo?.zipcode: '',
      proximity: compTwo?.proximity  ? compTwo?.proximity: null,

      salePrice:compTwo?.sales_price  ? compTwo?.sales_price: '',
      dateClosed:compTwo?.date_closed ? compTwo?.date_closed: '',
      dataSource:compTwo?.data_source ? compTwo?.data_source: null,
      status:compTwo?.status ? compTwo?.status: null,
      siteSize:compTwo?.site_size ? compTwo?.site_size: '',
      view:compTwo?.view ? compTwo?.view: null,
      floorHeight:compTwo?.floor_height ? compTwo?.floor_height: null,
      actualAge:compTwo?.actual_age ? compTwo?.actual_age: '',
      condition:compTwo?.condition ? compTwo?.condition: null,
      bedRooms:compTwo?.bedrooms ? compTwo?.bedrooms: null,
      bathRooms:compTwo?.bathrooms ? compTwo?.bathrooms: null,
      halfBath:compTwo?.half_baths ? compTwo?.half_baths: null,
      livingArea:compTwo?.living_area_under_air ? compTwo?.living_area_under_air: '',
      basement:compTwo?.basement ? compTwo?.basement: null,
      garage:compTwo?.garage_carport ? compTwo?.garage_carport: null,
      pool:compTwo?.pool_deck_porch_patio ? compTwo?.pool_deck_porch_patio: null,
      additional:compTwo?.additional_info ? compTwo?.additional_info: '',
      lat:compTwo?.location_lat ? compTwo?.location_lat: null,
      lng:compTwo?.location_lng  ? compTwo?.location_lng: null,
    })
    setCompThreeFields({
      street: compThree?.address ?compThree?.address: '',
      city:compThree?.city ?compThree?.city: '',
      state:compThree?.state ?compThree?.state: '',
      zip:compThree?.zipcode ?compThree?.zipcode: '',
      proximity: compThree?.proximity ?compThree?.proximity: null,

      salePrice:compThree?.sales_price ?compThree?.sales_price: '',
      dateClosed:compThree?.date_closed ?compThree?.date_closed: null,
      dataSource:compThree?.data_source ?compThree?.data_source: '',
      status:compThree?.status ?compThree?.status: null,
      siteSize:compThree?.site_size ?compThree?.site_size: '',
      view:compThree?.view ?compThree?.view: null,
      floorHeight:compThree?.floor_height ?compThree?.floor_height: null,
      actualAge:compThree?.actual_age ?compThree?.actual_age: '',
      condition:compThree?.condition ?compThree?.condition: null,
      bedRooms:compThree?.bedrooms ?compThree?.bedrooms: null,
      bathRooms:compThree?.bathrooms ?compThree?.bathrooms: null,
      halfBath:compThree?.half_baths ?compThree?.half_baths: null,
      livingArea:compThree?.living_area_under_air ?compThree?.living_area_under_air: '',
      basement:compThree?.basement ?compThree?.basement: null,
      garage:compThree?.garage_carport ?compThree?.garage_carport: null,
      pool:compThree?.pool_deck_porch_patio ?compThree?.pool_deck_porch_patio: null,
      additional:compThree?.additional_info ?compThree?.additional_info: '',
      lat:compThree?.location_lat ?compThree?.location_lat: null,
      lng:compThree?.location_lng  ?compThree?.location_lng: null,
    })

     navigate(`/pages/comparables/${value.key}`);

  }

  const getProperties = () => {
    setLoading(true);
    axios.get("/properties/prop/comp/")
      .then(response => {
        let newRows = response.data?.map(e => createData(e.id, e ));

        setData(response.data)
        setRows(newRows);

        if(id){
          let rowsKey = newRows.find((e)=> e.key === parseInt(id))
          handleChangeMenu(rowsKey)
        }
        setLoading(false);

      }).catch(
      error => {
        alert({ message: error.message, severity: "error" });
        setLoading(false);


      }
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      getProperties();
    }
  },[isInitialized ]);
  const animatedComponents = makeAnimated();



  useEffect(()=>{
    if(firstTime){
      setFirstTime(false)
    }else {
      // console.log('submit useEffect')
      saveData()
    }
  },[submit])


  const saveData = async (data) => {

    let compOne= {
      "address": compOneFields?.street!==undefined ?compOneFields?.street  :null,
      "city": compOneFields?.city!==undefined ?compOneFields?.city  :null,
      "state":compOneFields?.state!==undefined ?compOneFields?.state  :null,
      "zipcode":compOneFields?.zip!==undefined ?compOneFields?.zip  :null,
      //
      // "bedrooms":compOneFields?.bedRooms ===''?null :compOneFields?.bedRooms!==undefined ?compOneFields?.bedRooms  :null,
      // "bathrooms":compOneFields?.bathRooms ===''?null :compOneFields?.bathRooms!==undefined ?compOneFields?.bathRooms  :null,
      // "half_baths":compOneFields?.halfBath ===''?null :compOneFields?.halfBath!==undefined ?compOneFields?.halfBath  :null,
      "bedrooms": compOneFields?.bedRooms===undefined ||compOneFields?.bedRooms===null ||compOneFields?.bedRooms===''? null  : parseInt(compOneFields?.bedRooms),
      "bathrooms": compOneFields?.bathRooms===undefined ||compOneFields?.bathRooms===null ||compOneFields?.bathRooms===''? null  : parseInt(compOneFields?.bathRooms),
      "half_baths": compOneFields?.halfBath===undefined ||compOneFields?.halfBath===null ||compOneFields?.halfBath===''? null  : parseInt(compOneFields?.halfBath),

      "proximity": c1Proxi==='' || c1Proxi===null || c1Proxi===undefined?  null :c1Proxi,

      // "proximity":compOneFields?.proximity!==undefined || compOneFields?.proximity !=='' ? compOneFields?.proximity :null,
      "sales_price":compOneFields?.salePrice!==undefined ?compOneFields?.salePrice  :null,
      "date_closed":compOneFields?.dateClosed=== ''|| compOneFields?.dateClosed === null || compOneFields?.dateClosed===undefined  ? null :
        JSON.stringify( compOneFields?.dateClosed) !==null ? JSON.stringify( compOneFields?.dateClosed).split('T')[0].split('"')[1]:null,
      "data_source":compOneFields?.dataSource!==undefined ?compOneFields?.dataSource  :null,
      "status":compOneFields?.status!==undefined ?compOneFields?.status  :null,
      "site_size":compOneFields?.siteSize!==undefined ?compOneFields?.siteSize  :null,
      "view":compOneFields?.view!==undefined ?compOneFields?.view  :null,
      "floor_height":compOneFields?.floorHeight!==undefined ?compOneFields?.floorHeight  :null,
      "actual_age":compOneFields?.actualAge!==undefined ?compOneFields?.actualAge  :null,
      "condition":compOneFields?.condition!==undefined ?compOneFields?.condition  :null,
      "living_area_under_air":compOneFields?.livingArea!==undefined ?compOneFields?.livingArea  :null,
      "basement":compOneFields?.basement!==undefined ?compOneFields?.basement  :null,
      "garage_carport":compOneFields?.garage!==undefined ?compOneFields?.garage  :null,
      "pool_deck_porch_patio":compOneFields?.pool!==undefined ?compOneFields?.pool  :null,
      "additional_info":compOneFields?.additional!==undefined ?compOneFields?.additional  :null,
    }
    let compTwo={
      "address": compTwoFields?.street!==undefined ?compTwoFields?.street  :null,
      "city": compTwoFields?.city!==undefined ?compTwoFields?.city  :null,
      "state":compTwoFields?.state!==undefined ?compTwoFields?.state  :null,
      "zipcode":compTwoFields?.zip!==undefined ?compTwoFields?.zip  :null,
      // "bedrooms":compTwoFields?.bedRooms ===''?null :compTwoFields?.bedRooms!==undefined ?compTwoFields?.bedRooms  :null,
      // "bathrooms":compTwoFields?.bathRooms ===''?null :compTwoFields?.bathRooms!==undefined ?compTwoFields?.bathRooms  :null,
      // "half_baths":compTwoFields?.halfBath ===''?null :compTwoFields?.halfBath!==undefined ?compTwoFields?.halfBath  :null,

      "bedrooms": compTwoFields?.bedRooms===undefined ||compTwoFields?.bedRooms===null ||compTwoFields?.bedRooms===''? null  : parseInt(compTwoFields?.bedRooms),
      "bathrooms": compTwoFields?.bathRooms===undefined ||compTwoFields?.bathRooms===null ||compTwoFields?.bathRooms===''? null  : parseInt(compTwoFields?.bathRooms),
      "half_baths": compTwoFields?.halfBath===undefined ||compTwoFields?.halfBath===null ||compTwoFields?.halfBath===''? null  : parseInt(compTwoFields?.halfBath),

      "proximity": c2Proxi==='' || c2Proxi===null || c2Proxi===undefined? null :c2Proxi,
      // "proximity":compTwoFields?.proximity!==undefined || compTwoFields?.proximity !=='' ? compTwoFields?.proximity :null,
      "sales_price":compTwoFields?.salePrice!==undefined ?compTwoFields?.salePrice  :null,
      "date_closed":compTwoFields?.dateClosed=== ''|| compTwoFields?.dateClosed === null || compTwoFields?.dateClosed===undefined  ? null :
        JSON.stringify( compTwoFields?.dateClosed) !==null ? JSON.stringify( compTwoFields?.dateClosed).split('T')[0].split('"')[1]:null,
      "data_source":compTwoFields?.dataSource!==undefined ?compTwoFields?.dataSource  :null,
      "status":compTwoFields?.status!==undefined ?compTwoFields?.status  :null,
      "site_size":compTwoFields?.siteSize!==undefined ?compTwoFields?.siteSize  :null,
      "view":compTwoFields?.view!==undefined ?compTwoFields?.view  :null,
      "floor_height":compTwoFields?.floorHeight!==undefined ?compTwoFields?.floorHeight  :null,
      "actual_age":compTwoFields?.actualAge!==undefined ?compTwoFields?.actualAge  :null,
      "condition":compTwoFields?.condition!==undefined ?compTwoFields?.condition  :null,
      "living_area_under_air":compTwoFields?.livingArea!==undefined ?compTwoFields?.livingArea  :null,
      "basement":compTwoFields?.basement!==undefined ?compTwoFields?.basement  :null,
      "garage_carport":compTwoFields?.garage!==undefined ?compTwoFields?.garage  :null,
      "pool_deck_porch_patio":compTwoFields?.pool!==undefined ?compTwoFields?.pool  :null,
      "additional_info":compTwoFields?.additional!==undefined ?compTwoFields?.additional  :null,
    }
    let compThree={
      "address": compThreeFields?.street!==undefined ?compThreeFields?.street  :null,
      "city": compThreeFields?.city!==undefined ?compThreeFields?.city  :null,
      "state":compThreeFields?.state!==undefined ?compThreeFields?.state  :null,
      "zipcode":compThreeFields?.zip!==undefined ?compThreeFields?.zip  :null,
      "bedrooms": compThreeFields?.bedRooms===undefined ||compThreeFields?.bedRooms===null ||compThreeFields?.bedRooms===''? null  : parseInt(compThreeFields?.bedRooms),
      "bathrooms": compThreeFields?.bathRooms===undefined ||compThreeFields?.bathRooms===null ||compThreeFields?.bathRooms===''? null  : parseInt(compThreeFields?.bathRooms),
      "half_baths": compThreeFields?.halfBath===undefined ||compThreeFields?.halfBath===null ||compThreeFields?.halfBath===''? null  : parseInt(compThreeFields?.halfBath),

      // "bedrooms":compThreeFields?.bedRooms ===''?null :compThreeFields?.bedRooms!==undefined ?compThreeFields?.bedRooms  :null,
      // "bathrooms":compThreeFields?.bathRooms ===''?null :compThreeFields?.bathRooms!==undefined ?compThreeFields?.bathRooms  :null,
      // "half_baths":compThreeFields?.halfBath ===''?null :compThreeFields?.halfBath!==undefined ?compThreeFields?.halfBath  :null,
      //

      "proximity": c3Proxi==='' || c3Proxi===null || c3Proxi===undefined?  null : c3Proxi,

      // "proximity":compThreeFields?.proximity!==undefined || compThreeFields?.proximity !=='' ? compThreeFields?.proximity :null,
      "sales_price":compThreeFields?.salePrice!==undefined ?compThreeFields?.salePrice  :null,
      "date_closed":compThreeFields?.dateClosed=== ''|| compThreeFields?.dateClosed === null || compThreeFields?.dateClosed===undefined  ? null :
        JSON.stringify( compThreeFields?.dateClosed) !==null ? JSON.stringify( compThreeFields?.dateClosed).split('T')[0].split('"')[1]:null,
      "data_source":compThreeFields?.dataSource!==undefined ?compThreeFields?.dataSource  :null,
      "status":compThreeFields?.status!==undefined ?compThreeFields?.status  :null,
      "site_size":compThreeFields?.siteSize!==undefined ?compThreeFields?.siteSize  :null,
      "view":compThreeFields?.view!==undefined ?compThreeFields?.view  :null,
      "floor_height":compThreeFields?.floorHeight!==undefined ?compThreeFields?.floorHeight  :null,
      "actual_age":compThreeFields?.actualAge!==undefined ?compThreeFields?.actualAge  :null,
      "condition":compThreeFields?.condition!==undefined ?compThreeFields?.condition  :null,
      "living_area_under_air":compThreeFields?.livingArea!==undefined ?compThreeFields?.livingArea  :null,
      "basement":compThreeFields?.basement!==undefined ?compThreeFields?.basement  :null,
      "garage_carport":compThreeFields?.garage!==undefined ?compThreeFields?.garage  :null,
      "pool_deck_porch_patio":compThreeFields?.pool!==undefined ?compThreeFields?.pool  :null,
      "additional_info":compThreeFields?.additional!==undefined ?compThreeFields?.additional  :null,
    }


    let payload = {
      "address":  subjectFields?.street!==undefined ? subjectFields?.street  :null,
      "city":  subjectFields?.city!==undefined ? subjectFields?.city  :null,
      "state":  subjectFields?.state!==undefined ? subjectFields?.state  :null,
      "zipcode": subjectFields?.zip!==undefined ? subjectFields?.zip  :null,
      "bedrooms": subjectFields?.bedRooms===undefined ||subjectFields?.bedRooms===null ||subjectFields?.bedRooms===''? null  : parseInt(subjectFields?.bedRooms),
      "bathrooms": subjectFields?.bathRooms===undefined ||subjectFields?.bathRooms===null ||subjectFields?.bathRooms===''? null  : parseInt(subjectFields?.bathRooms),
      "half_baths": subjectFields?.halfBath===undefined ||subjectFields?.halfBath===null ||subjectFields?.halfBath===''? null  : parseInt(subjectFields?.halfBath),
      "more_detail": [
        {
          "sales_price": subjectFields?.salePrice!==undefined ? subjectFields?.salePrice  :null,
          // "date_closed":subjectFields?.dateClosed=== ''
          // || subjectFields?.dateClosed === null || subjectFields?.dateClosed===undefined  ? null : subjectFields?.dateClosed?.split("T")[0],
          "date_closed":subjectFields?.dateClosed=== ''|| subjectFields?.dateClosed === null || subjectFields?.dateClosed===undefined  ? null :
            JSON.stringify( subjectFields?.dateClosed) !==null ? JSON.stringify( subjectFields?.dateClosed).split('T')[0].split('"')[1]:null,
          "data_source": subjectFields?.dataSource!==undefined ? subjectFields?.dataSource  :null,
          "status": subjectFields?.status!==undefined ? subjectFields?.status  :null,
          "site_size": subjectFields?.siteSize!==undefined ? subjectFields?.siteSize  :null,
          "view": subjectFields?.view!==undefined ? subjectFields?.view  :null,
          "floor_height": subjectFields?.floorHeight!==undefined ? subjectFields?.floorHeight  :null,
          "actual_age": subjectFields?.actualAge!==undefined ? subjectFields?.actualAge  :null,
          "condition": subjectFields?.condition!==undefined ? subjectFields?.condition  :null,
          "living_area_under_air":subjectFields?.livingArea!==undefined ? subjectFields?.livingArea  :null,
          "basement": subjectFields?.basement!==undefined ? subjectFields?.basement  :null,
          "garage_carport": subjectFields?.garage!==undefined ? subjectFields?.garage  :null,
          "pool_deck_porch_patio": subjectFields?.pool!==undefined ? subjectFields?.pool  :null,
          "additional_info": subjectFields?.additional!==undefined ? subjectFields?.additional  :null,
        }
      ],
      "comparable_info": [
        compOne,compTwo,compThree
      ]
    }

     try {
      let response;
      if (id===undefined) {
        response = await axios.post(`/properties/prop/`, payload);
        navigate(id ?`/pages/preview/${id}`  :rowsId===-1?  "/pages/preview/" : `/pages/preview/${rowsId}`)
      } else {

        response = await axios.put(`/properties/prop/${id}/`, payload);
        navigate(id ?`/pages/preview/${id}`  :rowsId===-1?  "/pages/preview/" : `/pages/preview/${rowsId}`)

      }
    } catch (e) {
      alert({ message: e.message, severity: "error" });
    }
  };


  return(
    loading?<div style={{width:"100%" , height:"20rem", display:"flex" , flexDirection:"row" , alignItems:"center" , justifyContent:"center"}}>
        <CircularProgress color="primary" size={50} disableShrink />
      </div>   :
    <>
      <Helmet title={"Comparable"}/>

        <Card>
          <CardHeader
            sx={{ backgroundColor: blue[800], color: "white" }}
            title="Comparables"
          >
          </CardHeader>

            <div style={{
              width:"98%",
              margin:"1%",
            }}>
              <Select

                onClick={getProperties}
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={rows !== [] ? rows : setRows(['a'])}
                value={rows.filter(function(option) {
                  return option.value === valueselect;
                })}
                placeholder="Select property:"
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={handleChangeMenu}
                loading={loading}
              />

            </div>
            <div  style={{
              width:"98%",
              margin:"1%",
            }}>
              <Grid  item xs={12} md={12} lg={12}>

                <label htmlFor="contained-button-file" onClick={()=>setOpenDialog(true)}>
                  <Button variant="contained" component="span" endIcon={<PhotoCamera style={{color:"white"}}/>}>
                    UPLOAD LOGO
                  </Button>
                </label>


              </Grid>
              <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
                <DialogTitle>
                  Change Logo
                </DialogTitle>
                <DialogContent>
                  <UploadProfileLogo handleClose={()=>setOpenDialog(false)}/>
                </DialogContent>
                <div style={{width:'30%' , height:'30px'}}>

                </div>
              </Dialog>

            </div>

          <div
            style={{
              width:"100%",
              padding:"1%"
            }}
          >
            <div className={'big-box-container'}>
              <div className={'single-subject-container'}>
                <div>
                    Subject
                </div>
                <MySwitch idTag={'1'} fields={subjectFields} setFields={(e)=>setSubjectFields(e)}/>


              </div>
              <div className={'single-subject-container'}>
                <div>
                  Comp One
                </div>
                <MySwitch idTag={'2'} fields={compOneFields} setFields={(e)=>setCompOneFields(e)}/>

              </div>
              <div className={'single-subject-container'}>
                <div>
                  Comp Two
                </div>
                <MySwitch idTag={'3'} fields={compTwoFields} setFields={(e)=>setCompTwoFields(e)}/>

              </div>
              <div className={'single-subject-container'}>
                <div>
                  Comp Three
                </div>
                <MySwitch idTag={'4'} fields={compThreeFields} setFields={(e)=>setCompThreeFields(e)}/>

              </div>

            </div>

          </div>
          <div style={{
            backgroundColor:"#FFFFFF" ,
            width:"100%" ,
            minHeight:"10rem" ,
            display:"flex",
            flexDirection:"row" ,
            alignItems:"flex-start" ,
            justifyContent:"space-between",
            padding:"1%"
          }}>
            <div style={{width:"24.5%"}}>
              <SubjectFields  rowId={rowsId} name={'s'}
                idTag={'1'} beforeSubmit={beforeSubmit} subjectFields={subjectFields} setSubjectFields={(e)=>setSubjectFields(e)}/>
            </div>
            <div style={{width:"24.5%"}}>
              <SubjectFields rowId={rowsId} name={'c1'} proximity={c1Proxi} setProximity={(e)=>setC1Proxi(e)}
                idTag={'2'} subLatLng={{lat:subjectFields.lat , lng:subjectFields.lng}} beforeSubmit={beforeSubmit} subjectFields={compOneFields} setSubjectFields={(e)=>setCompOneFields(e)}/>
            </div>
            <div style={{width:"24.5%"}}>
              <SubjectFields rowId={rowsId} name={'c2'} proximity={c2Proxi} setProximity={(e)=>setC2Proxi(e)}
                idTag={'3'} subLatLng={{lat:subjectFields.lat , lng:subjectFields.lng}} beforeSubmit={beforeSubmit} subjectFields={compTwoFields} setSubjectFields={(e)=>setCompTwoFields(e)}/>
            </div>
            <div style={{width:"24.5%"}}>
              <SubjectFields rowId={rowsId} name={'c3'} proximity={c3Proxi} setProximity={(e)=>setC3Proxi(e)}
                idTag={'4'} subLatLng={{lat:subjectFields.lat , lng:subjectFields.lng}} beforeSubmit={beforeSubmit} subjectFields={compThreeFields} setSubjectFields={(e)=>setCompThreeFields(e)}/>
            </div>


          </div>

          <CardActions
            sx={{ direction: "rtl" }}
          >
            <Grid container onMouseEnter={()=>setBeforeSubmit(beforeSubmit+1)} onTouchStart={()=>setBeforeSubmit(beforeSubmit+1)}>
              <Grid item xs={3} md={3} lg={2} sx={{mr:2}} >
                <Button fullWidth variant="contained" color="primary" onClick={handleSubmit} >
                  Save
                </Button>
              </Grid>
              <Grid item xs={3} md={3} lg={2} sx={{mr:2}} >
                <Button fullWidth  variant="contained" color="primary"  component={RouterLink} to={id ?`/pages/property/${id}`  :rowsId===-1?  "/pages/property" : `/pages/property/${rowsId}` }>
                  Previous
                </Button>
              </Grid>
              <Grid item xs={3} md={3} lg={2} sx={{mr:2}} >
                <Button fullWidth  variant="contained" color="primary"  component={RouterLink} to="/">
                  Cancel
                </Button>
              </Grid>
            </Grid>


          </CardActions>
        </Card>
    </>
  )
}

export default Comp