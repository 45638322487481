import React, { useEffect, useState } from "react";
import { CircularProgress, Switch } from "@mui/material";
import useSnackbar from "../hooks/useSnackbar";
import axios from "../utils/axios";

function MySwitch(props) {

  const [loading , setLoading]=useState()
  const [value ,setValue]=useState(false)
  const { alert } = useSnackbar();

  const lookup =(address, city, state, zip)=>{

    if(!value){
      setLoading(true)

      if(
        address=== undefined ||address=== null || address==='' ||
        city=== undefined    ||city=== null    || city===''    ||
        state=== undefined   ||state=== null   || state===''   ||
        zip=== undefined     ||zip=== null     || zip===''
      ){
        alert({ message: "provide Address, City , State and zip to use lookup data", severity: "error" });
        setLoading(false)

      }else {

        axios.post("https://thecompgenie.com/api/comparables/lookup/",{
          address: address, city: city, state: state, zip: zip
        },{
          headers:{
            Authorization:"Bearer "+window.localStorage.getItem('accessToken')
          }
          }
        ).then((res)=>{

          if(res.data?.Data===undefined){
            setLoading(false)
            setValue(true)
            props.setFields({ ...props.fields ,
              salePrice:res.data[0]['SALES_HISTORY']['@PropertySalesAmount'],
              dateClosed:res.data[0]['SALES_CONTRACT']['@_Date'],
              // dataSource:"",
              // status:"",
              siteSize: res.data[0]['SITE']['@LotSquareFeetCount'],
              // view:"",
              // floorHeight:"",
              actualAge:res.data[0]['STRUCTURE']['STRUCTURE_ANALYSIS']['@PropertyStructureBuiltYear'],
              // condition:"",
              bedRooms:res.data[0]['STRUCTURE']['@TotalBathroomCount'],
              bathRooms:res.data[0]['STRUCTURE']['@TotalBedroomCount'],
              // halfBath:"",
              livingArea: res.data[0]['STRUCTURE']['@GrossLivingAreaSquareFeetCount'],
              // basement:"" ,
              garage:res.data[0]['STRUCTURE']['CAR_STORAGE']['CAR_STORAGE_LOCATION']['@_ParkingSpacesCount'] + ' Car Garage',
              // pool:"",
              additional:res.data[0]["_LEGAL_DESCRIPTION"]['@_TextDescription'] ,
              lat :res.data[0]["_IDENTIFICATION"]['@LatitudeNumber'],
              lng:res.data[0]["_IDENTIFICATION"]['@LongitudeNumber']
            })
          }else {
            alert({ message: 'Data Not Found', severity: "error" });
            setLoading(false)
            setValue(false)
          }

        }).catch((e)=>{
            alert({ message: e.message, severity: "error" });
            setLoading(false)
            setValue(false)
          })

    }}
  }

  return (
    <div className='use-lookup-container'>
      <span style={{width:"58px" , height:"38px" , display:"flex",flexDirection:"row" , alignItems:"center" , justifyContent:"center"}}  id={'switchParrent'+props.idTag}> </span>
      {/*{*/}
      {/*  loading? */}
      {/*    <span style={{width:"58px" , height:"38px" , display:"flex",flexDirection:"row" , alignItems:"center" , justifyContent:"center"}}>*/}
      {/*             <CircularProgress color="inherit" size={20} disableShrink />*/}
      {/*           </span>*/}
      {/*    : <Switch*/}
      {/*      checked={value}*/}
      {/*      onChange={() => {*/}
      {/*        lookup(props.fields?.street ,props.fields?.city , props.fields?.state,props.fields?.zip )*/}
      {/*      }}*/}

      {/*    />*/}
      {/*}*/}
     {/*<span style={{fontSize:"0.8rem"}}>Use lookup data </span>*/}
    </div>
  );
};

export default MySwitch;