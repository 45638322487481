import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PropertiesTable from "../../components/property/PropertiesTable";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import React from "react";
import green from "@material-ui/core/colors/green";
import { Helmet } from "react-helmet-async";
import { blue } from "@mui/material/colors";

const PropertiesList = () => {
  return (
    <>
      <Helmet title="Properties" />
      <Card>
        <CardHeader sx={{ backgroundColor: blue[800], color: "white" }} title="Properties">


        </CardHeader>
        <CardContent>
          <PropertiesTable/>
        </CardContent>
      </Card>
    </>
  );
};

export default PropertiesList;
