import { Box } from "@mui/material";
import React, { useState } from "react";
import PersonalFieldValue from "./PersonalFieldValue";
import useAuth from "../../../hooks/useAuth";
import Button from "@mui/material/Button";
import { Loader } from "react-feather";
import { Check, CheckCircle, PendingRounded } from "@mui/icons-material";
import axios from "../../../utils/axios";
import useSnackbar from "../../../hooks/useSnackbar";
import PersonalFieldLabel from "./PersonalFieldLabel";
import Chip from "@mui/material/Chip";
import { yellow } from "@mui/material/colors";

const EmailField = () => {

  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const { alert } = useSnackbar();

  const sendLink = async (e) => {
    setLoading(true);
    try {
      await axios.put("/users/verify/", null, { params: { resend: true } });
      setLoading(false);
      setSent(true);
      alert({ message: "Verification email sent successfully", severity: "success" });
    } catch (error) {
      setLoading(false);
      alert({ message: error.message, severity: "error" });
    }
  };

  const unverifiedEmail = (
    <>
      {sent ?
        <Chip label="Pending" color="success" sx={{'border-radius': '15px', 'backgroundColor': yellow[800]}} icon={<PendingRounded  />} />


        :
        <Button variant="contained"
                color="error"
                onClick={sendLink}
                disabled={loading}
        >
          {!loading ? "Verify email" : <Loader/>}
        </Button>
      }
    </>
  );

  const verifiedEmail = (
    <>
      <Chip label="Verified" color="success" icon={<CheckCircle />} sx={{'border-radius': '15px'}}/>
    </>
  );


  return (
    <>
      <PersonalFieldLabel label="Email"/>
      <Box sx={{ display: "flex", alignItems: "center" }} gap={5}>
        <PersonalFieldValue value={user?.email}/>
        {user ? user?.email_verified ? verifiedEmail : unverifiedEmail : ""}
      </Box>
    </>
  );
};

export default EmailField;
