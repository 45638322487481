import React from "react";
import Typography from "@mui/material/Typography";

const PersonalFieldLabel = ({label}) => {
  return (
    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
      {label}
    </Typography>
  )
};

export default PersonalFieldLabel;
