import React from "react";



const SnackbarContext = React.createContext(null);

const SnackbarProvider = ({children}) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("success");
  const alert = (payload) => {
    setMessage(payload.message);
    setSeverity(payload.severity);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{open, setOpen, alert, severity, setSeverity, message, setMessage, handleClose}}>
      {children}
    </SnackbarContext.Provider>

  )
};

export {SnackbarProvider, SnackbarContext};
