import React from "react";
import { Divider, Grid, Stack, Typography } from "@material-ui/core";
import imageLogo from "../../components/preview/styles/img/Comp-Genie-2.png";
import blue from "@material-ui/core/colors/blue";
import TablePrint from "../../components/preview/TablePrint";
import MapComponent from "../../components/preview/Map";
import styled from "styled-components/macro";

const PdfPage = (props) => {
  const Avatar = styled.img`
  display: inline-block;
  border-radius: 5px;
  height: 50px;
  width: 250px;  
  max-height: 100%;
  max-width: 100%;
`;
  return (
    <div className={props.classes.print}>

      <Grid container direction={"row"} sx={{ borderTopRightRadius: 15,borderTopLeftRadius: 15, backgroundColor: "white" }} spacing={1}>
        <Grid item xs={3} md={3} lg={3}>
          <Avatar alt="Profile image" src={imageLogo} />

        </Grid>
        <Divider orientation="vertical" variant={"middle"} sx={{ borderColor: blue[800], borderWidth: 1, height: "auto"}} />

        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="h1" sx={{ fontFamily: "Bookman, URW Bookman L, serif",fontSize: "18px" ,color: "black" }}>
            The comp
          </Typography>
          <Typography variant="h1" sx={{ fontFamily: "Monospace",fontSize: "18px" ,color: "black" }}>
            Genie
          </Typography>
        </Grid>
        <Grid item marginLeft={"120px"} xs={3} md={3} lg={3}>
          <Typography variant="h1" sx={{ textAlign: "center",fontWeight: 560,fontFamily: "Monospace",fontSize: "18px" ,color: "black" }}>
            Comparative Market Analysis
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction={"column"} sx={{ backgroundColor: "white" }} spacing={1}>

        <Divider variant={"middle"} sx={{ marginTop: 2,borderColor: "black", height: "auto"}} />
        <Grid container direction={"row"}  spacing={1}>

          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" sx={{ marginLeft:2, fontFamily: "Times New Roman",fontSize: "16px" ,color: "black" }}>
              Telephone: +1 999 9999 999
            </Typography>

          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" sx={{ marginLeft:2, fontFamily: "Times New Roman",fontSize: "16px" ,color: "black" }}>
              www.thecompgenie.com
            </Typography>

          </Grid>
        </Grid>
        <Divider variant={"middle"} sx={{ borderColor: "black", height: "auto"}} />

      </Grid>


      <Grid container sx={{ padding: 2, backgroundColor: "white" }}  spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%'
              }}
            >
              <Typography variant="h6">Subject Address</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%',
                backgroundColor: (theme) => theme.palette.action.hover,
                height: '2rem'
                // padding: '4px'
              }}
            >
              <Typography>{ props.propertyInfo?.address }</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>
          <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%'
              }}
            >
              <Typography variant="h6">City</Typography>
              <Typography variant="h6">State</Typography>
              <Typography variant="h6">Zip</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%',
                backgroundColor: (theme) => theme.palette.action.hover,
                height: '2rem'
                // padding: '4px'
              }}
            >
              <Typography>{ props.propertyInfo?.city }</Typography>
              <Typography>{ props.propertyInfo?.state }</Typography>
              <Typography>{ props.propertyInfo?.zip }</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>
          <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%'
              }}
            >
              <Typography variant="h6"># Bedroom (s)</Typography>
              <Typography variant="h6"># Bathroom (s)</Typography>
              <Typography variant="h6"># 1/2 Bath (s)</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%',
                backgroundColor: (theme) => theme.palette.action.hover,
                height: '2rem'
                // padding: '4px'
              }}
            >
              <Typography></Typography>
              <Typography></Typography>
              <Typography></Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item sx={{ mt: 1 }} xs={12} md={12} lg={12}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%'
              }}
            >
              <Typography variant="h6">Unique Neighborhood Characteristics</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: '100%',
                backgroundColor: (theme) => theme.palette.action.hover,
                height: '8rem'
                // padding: '4px'
              }}
            >
              <Typography
                display="inline-block"
                sx={{ wordWrap: 'break-word', maxWidth: '90%', height: '100%' }}
              >
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, backgroundColor: 'text.primary', height: 1.5 }} />

      <Grid container sx={{ padding: 2, backgroundColor: "white" }} spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Stack
            sx={{
              height: '2rem'
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography variant="h6">Remodeling/Updates</Typography>
            <Typography variant="h6">Time Frame</Typography>
            <Typography variant="h6">Estimated Cost</Typography>
          </Stack>
        </Grid>

        {props.propertyInfo?.remodeling_or_updates.map((row)=>{
          // setSumCost(sumCost + row.estimated_cost)
          return (

            <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  width: '100%',
                  backgroundColor: (theme) => theme.palette.action.hover,
                  height: '2rem'
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Typography sx={{ minWidth: '182px' }}>{row.unit}</Typography>
                <Typography sx={{ minWidth: '100px' }}>{row.time_frame}</Typography>
                <Typography sx={{ minWidth: '132px' }}>{row.estimated_cost}</Typography>
              </Stack>
            </Grid>
          )

        })}

      </Grid>

      <Divider sx={{ my: 1, backgroundColor: 'text.primary', height: 1.5 }} />

      <Stack sx={{ padding: 2, backgroundColor: "white" }}  direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Typography variant="h6">Total Estimated Cost</Typography>
        <Typography sx={{ minWidth: '132px' }}>
        </Typography>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ my: 1,padding: 2, backgroundColor: "white" }}
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{
            width: '100%'
          }}
        >
          <Typography variant="h6">Important Features</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{
            width: '100%',
            backgroundColor: (theme) => theme.palette.action.hover,
            height: '8rem'
          }}
        >
          <Typography
            display="inline-block"
            sx={{
              wordWrap: 'break-word',
              maxWidth: '90%',
              height: '100%'
            }}
          >
            {props.propertyData?.important_features}
          </Typography>
        </Stack>
      </Stack>

      <div className={props.classes.pagebreak} />

      <Divider sx={{ my: 5, backgroundColor: '#000000', height: 2 }} />
      <TablePrint rows={props.dataRows} />

      <Divider sx={{ my: 2, backgroundColor: '#000000', height: 2 }} />
      <div className={props.classes.pagebreak} />
      <div sx={{ my: 4 }} />
      <MapComponent name={props.name} proximixy={props.dataRows[3]} />
      {/*<GoogleMaps /> */}
      {/*<SimpleMap/>*/}
      {/*<MarkerInfoWindow/>*/}
      {/*<MarkerInfoWindowGmapsObj/>*/}

    </div>
  )
}

export default PdfPage;