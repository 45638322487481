// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const PASSWORD_REGEX_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40}$/;
export const PASSWORD_REGEX_MATCH_ERROR_MESSAGE =
  "Password should contain lower case letter, upper case letter and number and between 8 to 40 characters";


