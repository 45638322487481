import React from "react";
import { Helmet } from "react-helmet-async";
import EmailVerificationComponent from "../../components/auth/EmailVerificaionComponent";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { Paper } from "@mui/material";
import Brand from "../../components/Brand";
import Wrapper from "../../components/Wrapper";
import Card from "@mui/material/Card";



const EmailVerification = () => {
  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Email Verification" />
        <Card sx={{padding: "0.2rem", margin: "auto 0.4rem"}}>
          <Paper>
            <EmailVerificationComponent />
          </Paper>
        </Card>
      </Wrapper>
    </React.Fragment>
  );
};

export default EmailVerification;
