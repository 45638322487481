import AxiosMockAdapter from "axios-mock-adapter";
import axios from "axios";

// import axios from "../utils/axios";

const axiosMockAdapter = new AxiosMockAdapter(axios.create(), {
  delayResponse: 0,
});

export default axiosMockAdapter;
