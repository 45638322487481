import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PersonalFieldLabel from "./PersonalFieldLabel";
import PersonalFieldValue from "./PersonalFieldValue";

const PersonalField = ({ label, value, onFieldEdited }) => {
  const [editMode, setEditMode] = useState(false);

  const fieldLabel = (
   <PersonalFieldLabel label={label}/>
  );

  const readyOnly = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
     <PersonalFieldValue value={value} />
      {/*<IconButton*/}
      {/*  onClick={() => {*/}
      {/*    setEditMode(!editMode);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <EditIcon />*/}
      {/*</IconButton>*/}
    </Box>
  );

  const edit = (
    <Box>
      <TextField></TextField>
    </Box>
  );

  return (
    <Box>
      {fieldLabel}
      {readyOnly}
      {/*{!editMode ? readyOnly : edit}*/}
    </Box>
  );
};

export default PersonalField;
