import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import {
  PASSWORD_REGEX_MATCH_ERROR_MESSAGE,
  PASSWORD_REGEX_PATTERN
} from "../../../constants";
import * as Yup from "yup";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import { AuthContext } from "../../../contexts/JWTContext";
import { tryGetFirstArrayElement } from "../../../utils/helpers";
import axios from "../../../utils/axios";
import Alert from "@mui/material/Alert";
import ChangePasswordComponent from "../../../components/auth/ChangePasswordComponent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

const ChangePassword = ({ open, handleClose}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Change password</DialogTitle>
      <DialogContent>
        <ChangePasswordComponent handleClose={handleClose}/>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
