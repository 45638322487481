import { IconButton, Snackbar } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { Close as CloseIcon } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import { SnackbarContext } from "./SnackbarContext";
import useSnackbar from "../hooks/useSnackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

const MySnackbar = () => {

  const { open, message, setOpen, severity, handleClose } = useSnackbar();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message="Note archived"
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small"/>
          </IconButton>
        </React.Fragment>
      }
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MySnackbar;
